import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FiMapPin, FiPhoneCall } from 'react-icons/fi'
import { AiOutlineMail, AiOutlineTwitter, AiOutlineInstagram } from 'react-icons/ai'
import Logo from '../../assets/images/footer-logo.png'
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa'

const Footer = () => {
    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    const openIncurrentTab = url => {
        window.open(url, '_top', 'noopener,noreferrer');
    };
    return (
        <div className="footer">
            <Container>
                <Row>
                    <Col md="8">
                        <Row>
                            <Col>
                                <h5>About</h5>
                                <p>Paytail is a fintech company enabling instant digital finance and easy EMI's across millions of merchants all over the country. Our vision is to help consumers take their favourite products home with convenience and affordability.</p>
                                <ul className="cLogo">
                                    <li><img src={require('../../assets/images/startupindia-logo.png')} /></li>
                                    <li><img src={require('../../assets/images/msms-logo.png')} /></li>
                                </ul>
                            </Col>
                            <Col>
                                <div className="boxSpace">
                                    <h5>Get Help</h5>
                                    <ul>
                                        <li>
                                            <span><FiMapPin /></span>
                                            <p>5th Floor, Unitech Commercial Tower 2, Greenwoods City, BlockB, Sector 45,Gurgaon, Haryana, 122003</p>
                                        </li>
                                        <li>
                                            <span><AiOutlineMail /></span>
                                            <a href="mailto:support@paytail.com">support@paytail.com</a>
                                        </li>
                                        <li>
                                            <span><FiPhoneCall /></span>
                                            <p>8880-200-500</p>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="siteLink">
                        <div className="footerLink">
                            <h5>Legal</h5>
                            <ul>
                                <li><a onClick={() => openInNewTab('/privacypolicy')}>Privacy Policy</a></li>
                                <li><a onClick={() => openInNewTab('/merchant-terms')}>Merchant Terms</a></li>
                                <li><a onClick={() => openInNewTab('/consumer-term-condition')}>Consumer Terms</a></li>
                                <li><a onClick={() => openInNewTab('/refund-policy')}>Cancellation & <br />Refund Policy</a></li>
                                <li><a onClick={() => openInNewTab('/term-use')}>Terms of use</a></li>
                                <li><a onClick={() => openIncurrentTab('/blog')}>Blogs</a></li>
                            </ul>
                        </div>
                        <div className="footerLink">
                            <h5>Company</h5>
                            <ul>
                                <li><a onClick={() => openIncurrentTab('/about-us')}>About Us</a></li>
                                <li><a href='https://paytail-web.s3.ap-south-1.amazonaws.com/Paytail.apk' target={'_blank'}>Download</a></li>
                                {/* <li><a href='https://play.google.com/store/apps/details?id=com.paytail.consumers' target={'_blank'}>Download</a></li> */}
                                <li><a onClick={() => openIncurrentTab('/financial-partners')}>Financial Partners</a></li>
                            </ul>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="bottomFooter">
                            <img src={Logo} />
                            <ul>
                                <li><a href="https://www.facebook.com/PayTailIndia/" target={'_blank'}><FaFacebookF /></a></li>
                                <li><a href="https://www.linkedin.com/company/paytail/" target={'_blank'}><FaLinkedinIn /></a></li>
                                <li><a href="https://twitter.com/PaytailIndia" target={'_blank'}><AiOutlineTwitter /></a></li>
                                <li><a href="https://www.instagram.com/paytailindia/" target={'_blank'}><AiOutlineInstagram /></a></li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="oliveTag">
                <Container>
                    <Row>
                        <Col>
                            <div className="companyTag">
                                <p>© Copyright 2022. Paytail. All Rights Reserved.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default Footer;
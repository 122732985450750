import React from "react";
import policyStyle from "../Style/policyStyle.module.css";
  
const RefundPolicy = () => {
  return (
    <div className="policy_content refund">
        <h2 className={policyStyle.header}>
            CANCELLATION &amp; REFUND POLICY
        </h2>
        <p>
            This document is published in accordance with the provisions of Rule
            3(1) of the Information Technology (Intermediaries Guidelines) Rules,
            2011 which require the publishing of the rules and regulations governing
            the use and access of www.paytail.com and the Paytail Services (defined
            below), or any other portal as may be launched by Paytail India Private
            Limited ('Paytail website').
        </p>
        <p>
            In addition to acceptance of the Customer Credit &amp; Allied Terms,
            Merchant Terms, Paytail Application Terms of Use &amp; Privacy Policy
            including amendments thereto that may be made from time to time (“
            <b>Policies</b>”), all the events of refund, exchange, cancellation,
            return etc. of the Product or Services accessed by the Paytail User
            through Patail App, shall be governed by the present Cancellation &amp;
            Refund Policy (hereinafter referred to as the “<b>Policy</b>”) and the
            User is advised to read and agree to the terms hereof before purchasing
            the products/ services through Paytail App. All terms and conditions of
            the Customer Credit &amp; Allied Terms, Merchant Terms, Paytail Application
            Terms of Use &amp; Privacy Policy are incorporated by reference in this
            Policy and shall be deemed to be a part of this Policy. The Policy is as
            under;
        </p>
       <br />
        <ol>
            <li>
                <h3>General</h3>
            </li>
            <p>
                </p><ol type="a">
                    <li>
                        Access of Paytail App by the Paytail User shall be considered as
                        deemed acceptance of this Policy by the User. This Policy
                        envisages the general terms of cancellation and refund as per the
                        prevalent practices, however, the manufacturer or merchant or
                        seller’s policies in this regard shall be final and binding on the
                        Paytail User.
                    </li>
                   <br />
                    <li>
                        Products/ Services may be subject to their respective,
                        cancellation, return, refund &amp; exchange policy mentioned at the
                        time of order and be governed by the terms of merchant or
                        manufacturer or seller. You are requested to read the refund,
                        exchange, return &amp; cancellation policy of each product/ service
                        before placing the order on Paytail Application.
                    </li>
                    <li>
                        The product intended to be returned shall be unused and preserved
                        in its original condition along with tags and original packaging.
                    </li>
                   <br />
                </ol>
            <p></p>
            <li>
                <h3>
                    Cancellation &amp; Refund (Debit/ Credit Card/ Netbanking/ Cash on
                    Delivery)
                </h3>
            </li>
            <p>
                Following Policy will be applicable on the Customer transacting
                through Debit/ Credit Card/ Netbanking/ Cash on Delivery (“
                <b>Transaction/ Transaction Price</b>”);
               <br />
               <br />
                </p><ol type="a">
                    <li>
                        You, as a Paytail User, will be entitled to claim a refund of the
                        Transaction Price (as Your sole and exclusive remedy) in case You
                        do not receive the delivery within the time period provided at the
                        time of placing the order. In case you do not raise a refund claim
                        using Paytail App features within the stipulated time than this
                        would make You ineligible for a refund and/or exchange and/or
                        return.
                    </li>
                   <br />
                    <li>
                        You, as a Paytail User, understand that the Payment Facility may
                        not be available in full or in part for certain category of
                        products and/or services and/or Transactions as mentioned in the
                        Policies and hence You may not be entitled to a refund in respect
                        of the Transactions for those products and /or services. Paytail
                        User is advised to read the cancellation and refund policy of
                        their respective Product/ Services before order on Paytail App.
                    </li>
                    <li>
                        Except for Cash On Delivery transaction, refund, if any, shall be
                        made into the bank account of the Paytail user only or through any
                        other method available on the Platform, as chosen by You.
                    </li>
                    <li>
                        For cash on delivery transactions, refunds, if any, shall be made
                        into the bank account of the Paytail user only so provided by
                        him/her at the time of confirming the cancellation &amp; refund
                        request on Paytail App. Paytail strongly advises the Paytail Users
                        to avoid the cash on delivery option in case the Paytail User does
                        not have a bank account in order to avoid any delay or failure in
                        refund of the Transaction Price in the event of cancellation.
                        Paytail categorically disclaims any liability qua the claim of
                        damages, interest etc. from the Paytail User in this regard.
                    </li>
                    <li>
                        Refund shall be made in Indian Rupees only and shall be equivalent
                        to the Transaction Price received in Indian Rupees.
                    </li>
                    <li>
                        For electronics payments, refund shall be made through payment
                        facility using NEFT / RTGS or any other online banking /
                        electronic funds transfer system approved by Reserve Bank India
                        (RBI)
                    </li>
                    <li>
                        Refunds may be supported for select banks. Where a bank is not
                        supported for processing refunds, You will be required to share
                        alternate bank account details with us for processing the refund.
                    </li>
                   <br />
                </ol>
            <p></p>
            <li>
                <h3>
                    Cancellation &amp; Refund (Loan/ Credit Facility)
                </h3>
            </li>
            <p>
                Following Policy will be applicable on the Customer transacting on
                Paytail App using the Loan/ Credit Facility through the Partner
                Merchan Debit/ Credit Card/ Netbanking/ Cash on Delivery (“
                <b>Transaction/ Transaction Price</b>”);
               <br />
               <br />
                </p><ol type="a">
                    <li>
                        You, as a Paytail User, will be entitled to claim a refund of the
                        Transaction Price (as Your sole and exclusive remedy) in case You
                        do not receive the delivery within the time period provided at the
                        time of placing the order. In case you do not raise a refund claim
                        using Paytail App features within the stipulated time than this
                        would make You ineligible for a refund and/or exchange and/or
                        return.
                    </li>
                   <br />
                    <li>
                        You, as a Paytail User, understand that the Payment Facility may
                        not be available in full or in part for certain category of
                        products and/or services and/or Transactions as mentioned in the
                        Policies and hence You may not be entitled to a refund in respect
                        of the Transactions for those products and /or services. Paytail
                        User is advised to read the cancellation and refund policy of
                        their respective Product/ Services before order on Paytail App.
                    </li>
                    <li>
                        In the event of request for Cancellation &amp; Refund w.r.t the
                        Transactions done by availing the Loan/ Credit Facility through
                        Paytail App, the Paytail User shall pay, settle and close the Loan
                        with the Partner Merchant Bank by paying the full amount of the
                        Product/ Services.
                    </li>
                    <li>
                        All/Any return of the Product/s purchased via the Paytail App or
                        by using the Paytail services shall be directly initiated or
                        applied with the Merchant/Manufacturer from who the Product was
                        purchased, Notwithstanding whether the such Merchant is the
                        Manufacturer of the said product or not and such returns shall be
                        processed as per the Terms and Conditions and/or the Return Policy
                        of the such Merchants/Manufacturer. The Consumer/Paytail user
                        shall keep Paytail indemnified from the consequences or effects of
                        such return and shall be obliged to pay the acknowledged EMI until
                        the refund has been deposited with Paytail/ Partner Merchant Bank
                        and NOC obtained with regards to the Loan Closure. The
                        Consumer/Paytail User agrees and accepts that interest shall be
                        charged on the days/months the Loan was availed until the Loan
                        Closure.
                    </li>
                    <li>
                        The Merchant upon request for Return by the Consumer/Paytail User,
                        facilitate the Return of the Product as per the Return Policy of
                        the Merchant/Manufacturer. In the event of acceptance of Return,
                        the Maerchant/Manufacturer shall, at the first instance, inform
                        Paytail and initiate the refund process. The Merchant/manufacturer
                        confirms to keep Paytail indemnified against any/all repercussions
                        arising out of delay in such refund to Paytail.
                    </li>
                   <br />
                </ol>
            <p></p>
            <li>
                <h3>
                    Exchange shall be subject to respective Merchant Policies. Paytail
                    User shall keep the Paytail indemnified in this regard always.
                </h3>
            </li>
            <li>
                <h3>
                    Refund shall be conditional and shall be with recourse available to
                    Paytail in case of any misuse by Paytail User.
                </h3>
            </li>
            <li>
                <h3>
                    Merchant/ Seller/ paytail may also request you for additional
                    documents for verification
                </h3>
            </li>
            <li>
                <h3>
                    Refund shall be subject to Buyer complying with Policies.
                </h3>
            </li>
        </ol>
        <p>
            Paytail User acknowledges that she/he has read this Policy and agrees to
            all its terms and conditions. User shall also independently evaluated
            the desirability of purchasing the Product and is not relying on any
            representation, guarantee or statement(s) other than as set out in this
            Refund Policy or Terms of Use
        </p>
    </div>
  );
};
  
export default RefundPolicy;
import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ShopImg from '../../assets/images/shomImg.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import ShopEmiVideo from '../../assets/videos/paytail.m4v'
import Lottie from 'react-lottie';
import everyMonthVideo from '../../assets/jsonFile/logoVideo'
const NewDeals =()=>{

    const videoMonth = {
        loop: false,
        autoplay: true,
        animationData: everyMonthVideo,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

    useEffect(() => {
        AOS.init();
      }, [])
    return(
        <section className="dealSec sectionPadding fullHeight">
            <Container>
                <Row className="flexCenter">
                    <Col>
                        <h2 className="secTitle">
                            <span data-aos="fade-right" data-aos-duration="300" data-aos-easing="ease-in-sine" data-aos-delay="100">Paytail Spend Power</span> 
                            <span data-aos="fade-right" data-aos-duration="300" data-aos-easing="ease-in-sine" data-aos-delay="120">users get new deals</span> 
                            <span data-aos="fade-right" data-aos-duration="300" data-aos-easing="ease-in-sine" data-aos-delay="130">every month</span>
                        </h2>
                    </Col>
                    <Col className="text-center">
                        <video data-aos="fade-up" data-aos-duration="400" data-aos-easing="ease-in-sine" data-aos-delay="300" src={ShopEmiVideo} loop muted autoPlay className='paytailVide'></video>
                        <div className='lotiMobile' data-aos="fade-up" data-aos-duration="400" data-aos-easing="ease-in-sine" data-aos-delay="200">
                        <Lottie 
                            options={videoMonth}
                            height="100%"
                            width="100%"
                        /> 
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default NewDeals;
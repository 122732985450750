import React, { useEffect, useState } from "react";
import blogStyle from "../Style/blog.module.css";
import Header from '../common/Header';
import Footer from '../common/Footer';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ButtonStyle from '../Style/Button.module.css'
import blog1 from '../../assets/images/blog1.jpg';
import blog2 from '../../assets/images/blog2.jpg';
import blog3 from '../../assets/images/blog3.jpg';
import blog4 from '../../assets/images/blog4.jpg';
import blog5 from '../../assets/images/blog5.jpg';
import blog6 from '../../assets/images/blog6.jpg';
import frwd from '../../assets/images/frwd.svg';
  
const Blog = () => {
    useEffect(() => {
        AOS.init();
      }, [])
    const openIncurrentTab = url => {
        window.open(url, '_top', 'noopener,noreferrer');
    };
    const [showmore, setshowmore]=useState(false); 
    const openblog = () => {
        setshowmore(true);
    };
  return (
        <div className="main">
            <Header />
            <div className={blogStyle.blog_main}>
                <Container>
                    <h2 className="secTitle">
                        Blogs
                    </h2>                   
                    <div className={blogStyle.blog_list}>
                        <Row>
                            <Col md="4" sm="6">
                                <div className={blogStyle.blog_item} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="400">
                                    <div className={blogStyle.blog_img}>
                                        <img src={blog1} alt="Blog" /> 
                                    </div>                               
                                    <div className={blogStyle.blog_detail}>
                                        <p className={blogStyle.date}>08 Nov 2022</p>
                                        <a onClick={() => openIncurrentTab('/blog-detail/How-digital-lending-services-are-the-new-present-and-future')} className={blogStyle.title}>How digital lending services are the new present and future?</a>
                                        <p>“Get a loan within seconds” is an advertisement that banks constantly bombard you with. You switch a channel on your television and there comes a commercial “XYZ bank approves your loan in a minute”.</p>
                                        <div className={blogStyle.action}>
                                            <a onClick={() => openIncurrentTab('/blog-detail/How-digital-lending-services-are-the-new-present-and-future')}>Read more <img src={frwd} alt="Blog" /></a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="4" sm="6">
                                <div className={blogStyle.blog_item} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="500">
                                    <div className={blogStyle.blog_img}>
                                        <img src={blog2} alt="Blog" /> 
                                    </div>                               
                                    <div className={blogStyle.blog_detail}>
                                        <p className={blogStyle.date}>08 Nov 2022</p>
                                        <a onClick={() => openIncurrentTab('/blog-details/How-digitization-of-payments-can-help-the-unorganized-retail-flourish')} className={blogStyle.title}>How digitization of payments can help the unorganized retail flourish</a>
                                        <p>The retail scenario in India is highly dominated by the family-run “nukkad kiranas” that have a monopoly in their areas of operation. Almost 90% of the retail sector in the country is unorganized. They offer brands that are in demand in the neighborhood and notice every customer’s preferences across a wide range of products in groceries, electronics, apparel, etc.</p>
                                        <div className={blogStyle.action}>
                                            <a onClick={() => openIncurrentTab('/blog-detail/How-digitization-of-payments-can-help-the-unorganized-retail-flourish')}>Read more <img src={frwd} alt="Blog" /></a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="4" sm="6">
                                <div className={blogStyle.blog_item} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="600">
                                    <div className={blogStyle.blog_img}>
                                        <img src={blog3} alt="Blog" /> 
                                    </div>                               
                                    <div className={blogStyle.blog_detail}>
                                        <p className={blogStyle.date}>08 Nov 2022</p>
                                        <a onClick={() => openIncurrentTab('/blog-detail/The-growth-of-indian-retail')} className={blogStyle.title}>The growth of indian retail</a>
                                        <p>Indian retail has completely transformed itself and experienced rapid development in the last decade. According to a report by BCG, the sector is crossing the trillion- dollar mark. This growth is driven by certain social, economic, and technological factors that fuel consumers' consumption.</p>
                                        <div className={blogStyle.action}>
                                            <a onClick={() => openIncurrentTab('/blog-detail/The-growth-of-indian-retail')}>Read more <img src={frwd} alt="Blog" /></a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="4" sm="6">
                                <div className={blogStyle.blog_item} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="500">
                                    <div className={blogStyle.blog_img}>
                                        <img src={blog4} alt="Blog" /> 
                                    </div>                               
                                    <div className={blogStyle.blog_detail}>
                                        <p className={blogStyle.date}>08 Nov 2022</p>
                                        <a onClick={() => openIncurrentTab('/blog-detail/Unorganised-Retail:-Tapping-the-Untapped-Giant')} className={blogStyle.title}>Unorganised Retail: Tapping the Untapped Giant</a>
                                        <p>With the advent of technology, the retail sector has witnessed a 360-degree transformation in the past decade. The pandemic has further proven to be a catalyst in the acceptance of digitization of payments in the offline retail sector.</p>
                                        <div className={blogStyle.action}>
                                            <a onClick={() => openIncurrentTab('/blog-detail/Unorganised-Retail:-Tapping-the-Untapped-Giant')}>Read more <img src={frwd} alt="Blog" /></a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="4" sm="6">
                                <div className={blogStyle.blog_item} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="600">
                                    <div className={blogStyle.blog_img}>
                                        <img src={blog6} alt="Blog" /> 
                                    </div>                               
                                    <div className={blogStyle.blog_detail}>
                                        <p className={blogStyle.date}>08 Nov 2022</p>
                                        <a onClick={() => openIncurrentTab('/blog-detail/Why-Indias-digital-payment-ecosystem-is-a-revolution')} className={blogStyle.title}>Why India's digital payment ecosystem is a revolution</a>
                                        <p>For an Indian resident in the year 2022, it has become extremely common to commence the day with a QR code scan and end it on the same. From your groceries to your travel, digital payments have entered your lives in full force. This is when you realise you are a part of the digital revolution, digital India.</p>
                                        <div className={blogStyle.action}>
                                            <a onClick={() => openIncurrentTab('/blog-detail/Why-Indias-digital-payment-ecosystem-is-a-revolution')}>Read more <img src={frwd} alt="Blog" /></a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md="4" sm="6">
                                <div className={blogStyle.blog_item} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="400">
                                    <div className={blogStyle.blog_img}>
                                        <img src={blog5} alt="Blog" /> 
                                    </div>                               
                                    <div className={blogStyle.blog_detail}>
                                        <p className={blogStyle.date}>08 Nov 2022</p>
                                        <a onClick={() => openIncurrentTab('/blog-detail/Your-credit-score-and-what-it-does-for-you')} className={blogStyle.title}>Your credit score and what it does for you?</a>
                                        <p>“Your credit is not approved.” A notification that does not bring a smile on your face and probably no one else would want to experience that too.</p>
                                        <div className={blogStyle.action}>
                                            <a onClick={() => openIncurrentTab('/blog-detail/Your-credit-score-and-what-it-does-for-you')}>Read more <img src={frwd} alt="Blog" /></a>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="text-center mt-4">
                            <button onClick={openblog} className={ButtonStyle.whiteBtn}>Load More</button>
                        </div>
                    </div>
                </Container>
            </div>
            <Footer />
        </div>
    );
};
export default Blog;
import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import HomeRoute from './components/Home/HomeRoute';
import ConsumerTermCondition from './components/Terms/ConsumerTermCondition';
import PrivacyPolicy from './components/Terms/privacy-policy/PrivacyPolicy';
import TermsOfUse from './components/Terms/TermsOfUse';
import MerchantTerms from './components/Terms/MerchantTerms';
import RefundPolicy from './components/Terms/RefundPolicy';
import Blog from './components/blog/blog';
import Blog_Detail from './components/blog/blog-detail';
import Careers from './components/careers/careers';
import Job_desc from './components/careers/job-description';
import Financial_partners from './components/Financial-partners/financial-partners';
import About from './components/about/about';

function App() {
  return (
    <Router>
      <Routes >
        <Route path='/' element={<HomeRoute />} />
        <Route path='/consumer-term-condition' element={<ConsumerTermCondition />} />
        <Route path='/privacypolicy' element={<PrivacyPolicy />} />
        <Route path='/term-use' element={<TermsOfUse />} />
        <Route path='/merchant-terms' element={<MerchantTerms />} />
        <Route path='/refund-policy' element={<RefundPolicy />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/blog-detail/:id' element={<Blog_Detail />} />
        <Route path='/careers' element={<Careers />} />
        <Route path='/job-descriptions/:id' element={<Job_desc />} />
        <Route path='/financial-partners' element={<Financial_partners />} />
        <Route path='/about-us' element={<About />} />

      </Routes >
    </Router>
  );
}

export default App;

import React, { useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AOS from 'aos';
import 'aos/dist/aos.css';
import $ from "jquery";
import V2 from '../../assets/videos/way_video_02.mp4';
import V3 from '../../assets/videos/way_video_03.mp4';
import V4 from '../../assets/videos/way_video_04.mp4';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Lottie from 'react-lottie';
import PowerVideo from '../../assets/jsonFile/Paytail-Spend-Power'
import MerchantVideo from '../../assets/jsonFile/Merchant-&-Products'
import KYCVideo from '../../assets/jsonFile/KYC'
import PaymentVideo from '../../assets/jsonFile/Payment'


const PaytailWay = () => {

    const lotiVideo1 = {
        loop: true,
        autoplay: true,
        animationData: PowerVideo,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const lotiVideo2 = {
        loop: true,
        autoplay: true,
        animationData: MerchantVideo,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const lotiVideo3 = {
        loop: true,
        autoplay: true,
        animationData: KYCVideo,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const lotiVideo4 = {
        loop: true,
        autoplay: true,
        animationData: PaymentVideo,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    useEffect(() => {
        AOS.init();
    }, [])

    $(function () {
        $(window).on('scroll', function () {
            if ($(window).scrollTop() >= $('#show').offset().top + 200) {
                $(".va1").addClass("active")
                $(".videoF1").addClass("active")
                $(".vo1").addClass("active")
            } else {
                $(".va1").removeClass("active")
                $(".videoF1").removeClass("active")

            }

            if ($(window).scrollTop() >= $('#show').offset().top + 600) {
                $(".videoF1").removeClass("active")
                $(".va2").addClass("active")
                $(".videoF2").addClass("active")

                $(".vo1").removeClass("active")
                $(".vo2").addClass("active")
            } else {
                $(".va2").removeClass("active")
                $(".vo2").removeClass("active")
                $(".videoF2").removeClass("active")
            }

            if ($(window).scrollTop() >= $('#show').offset().top + 1000) {
                $(".videoF2").removeClass("active")
                $(".va3").addClass("active")
                $(".videoF3").addClass("active")
                $(".vo2").removeClass("active")
                $(".vo3").addClass("active")
            } else {
                $(".va3").removeClass("active")
                $(".videoF3").removeClass("active")
                $(".vo3").removeClass("active")
            }

            if ($(window).scrollTop() >= $('#show').offset().top + 1400) {
                $(".videoF3").removeClass("active")
                $(".va4").addClass("active")
                $(".videoF4").addClass("active")
                $(".vo3").removeClass("active")
                $(".vo4").addClass("active")
            } else {
                $(".va4").removeClass("active")
                $(".videoF4").removeClass("active")
                $(".vo4").removeClass("active")
            }

        });

        $(window).on('scroll', function () {
            if ($(window).scrollTop() >= $('#show').offset().top) {
                $('.waySec').addClass('secActive');
            }
            else {
                $('.waySec').removeClass('secActive');
                //or use $('.menu').removeClass('addclass');
            }
        });
    })

    return (
        <section className="waySec sectionPadding" id="show">
            <Container>
                <Row>
                    <Col sm={12} className="rowFlex">
                        <div className="leftSpace listSide">
                            <div className='stickyDiv'>
                                <h2 className="secTitle">
                                    <span>We will show you</span>
                                    <span>the way!</span>
                                </h2>
                                <div className="wayProsess">
                                    <ul>
                                        <li className='va1'>
                                            <div className='leftBox'>
                                                <span>1</span>
                                                <p>Check your Paytail Spend Power in 30 seconds </p>
                                            </div>
                                            <div className='rightBox'>
                                            </div>
                                        </li>
                                        <li className='va2'>
                                            <div className='leftBox'>
                                                <span>2</span>
                                                <p>Choose the Merchant & Product</p>
                                            </div>
                                            <div className='rightBox'>
                                            </div>
                                        </li>
                                        <li className='va3'>
                                            <div className='leftBox'>
                                                <span>3</span>
                                                <p>Complete Paperless KYC</p>
                                            </div>
                                            <div className='rightBox'>
                                            </div>
                                        </li>
                                        <li className='va4'>
                                            <div className='leftBox'>
                                                <span>4</span>
                                                <p>Pay</p>
                                            </div>
                                            <div className='rightBox'>
                                            </div>
                                        </li>
                                        <li className="checkBtn listFive ">
                                            <h6><a href='https://paytail-web.s3.ap-south-1.amazonaws.com/Paytail.apk' target={'_blank'}>Check your Paytail Spend Power Now</a></h6>
                                            {/* <h6><a href='https://play.google.com/store/apps/details?id=com.paytail.consumers' target={'_blank'}>Check your Paytail Spend Power Now</a></h6> */}
                                        </li>
                                    </ul>
                                </div>
                                <div className='mobileViewVideo'>
                                    <div className='vo1 vMob'>
                                        <Lottie
                                            options={lotiVideo1}
                                            height="100%"
                                            width="250px"
                                        />
                                    </div>
                                    <div className='vo2 vMob'>
                                        <Lottie
                                            options={lotiVideo2}
                                            height="100%"
                                            width="250px"
                                        />
                                    </div>
                                    <div className='vo3 vMob'>
                                        <Lottie
                                            options={lotiVideo3}
                                            height="100%"
                                            width="250px"
                                        />
                                    </div>
                                    <div className='vo4 vMob'>
                                        <Lottie
                                            options={lotiVideo4}
                                            height="100%"
                                            width="250px"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='rightSpace videoSide desktopView'>
                            <div className='videoDiv'>
                                <div className='videoF1 comanV'>
                                    <Lottie
                                        options={lotiVideo1}
                                        height="100%"
                                        width="400px"
                                    />
                                </div>
                                <div className='videoF2 comanV'>
                                    <Lottie
                                        options={lotiVideo2}
                                        height="100%"
                                        width="400px"
                                    />                                 </div>
                                <div className='videoF3 comanV'>
                                    <Lottie
                                        options={lotiVideo3}
                                        height="100%"
                                        width="400px"
                                    />                                 </div>
                                <div className='videoF4 comanV'>
                                    <Lottie
                                        options={lotiVideo4}
                                        height="100%"
                                        width="400px"
                                    />                                 </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default PaytailWay
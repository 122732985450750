import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AOS from 'aos';
import 'aos/dist/aos.css';

const BigNoFrom =()=>{
    useEffect(() => {
        AOS.init();
      }, [])
    return(
        <div className="bigFrom sectionPadding fullHeight">
            <Container>
                <Row>
                    <Col>
                        <div className="bigUs">
                            <div className="bigUsLeftBox">
                                <div className='bigUsLeftBoxBg' ></div>
                                <h2 className="secTitle" data-aos="zoom-in" data-aos-duration="300" data-aos-easing="ease-in-sine" data-aos-delay="300">Its a big <span>NO</span> from us!</h2>
                            </div>
                            <div className="bigMultiBox">
                                <div className="fromBox" data-aos="fade-up" data-aos-duration="400" data-aos-easing="ease-in-sine" data-aos-delay="300">
                                    <div className="icon">
                                        <img src={require('../../assets/images/Paperwork-Icon.png')} />
                                    </div>
                                    <h6>No <span>Paperwork</span></h6>
                                </div>
                                <div className="fromBox" data-aos="fade-up" data-aos-duration="400" data-aos-easing="ease-in-sine" data-aos-delay="400">
                                    <div className="icon">
                                        <img src={require('../../assets/images/interest-icon.png')} />
                                    </div>
                                    <h6>No interest <span>for first 3 months</span></h6>
                                </div>
                                <div className="fromBox" data-aos="fade-up" data-aos-duration="400" data-aos-easing="ease-in-sine" data-aos-delay="500">
                                    <div className="icon">
                                        <img src={require('../../assets/images/hassle-icon.png')} />
                                    </div>
                                    <h6>No hassle <span>for you</span></h6>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default BigNoFrom;
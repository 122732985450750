import React, { useEffect } from "react";
import financialStyle from "../Style/financial.module.css";
import ButtonStyle from '../Style/Button.module.css'
import Header from '../common/Header';
import Footer from '../common/Footer';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import AOS from 'aos';
import 'aos/dist/aos.css';
import financial_partners_banner from '../../assets/images/financial-partners-banner.jpg';
import financial1 from '../../assets/images/financial1.png';
import financial2 from '../../assets/images/financial2.png';
import financial3 from '../../assets/images/financial3.png';
import financial4 from '../../assets/images/iciciImage.png';
import PiramalLogo from '../../assets/images/piramalLogo.png';


const Financial_partners = () => {
	useEffect(() => {
		AOS.init();
	}, [])
	const openIncurrentTab = url => {
		window.open(url, '_top', 'noopener,noreferrer');
	};
	return (
		<div className="main">
			<Header />
			<div className={financialStyle.financial_main + ' financial_main'}>
				<div className={financialStyle.inner_banner}>
					<img className="w-100" src={financial_partners_banner} alt="Financial Partners" />
					<h2 data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="400">Financial Partners</h2>
				</div>
				<div className={financialStyle.financial_content}>
					<Container>
						<h2 data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="400">We have a vision to transform the lives of </h2>
						<div className={financialStyle.number} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="500">
							<span>125</span>
							<h4>Million</h4>
						</div>
						<h2 data-aos-duration="500" data-aos="fade-up" data-aos-easing="ease-in-sine" data-aos-delay="600">households by providing them quality credit, to help them get what they truly want.</h2>
					</Container>
				</div>
				<div className={financialStyle.financial_partner}>
					<Container>
						<h2 data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="400">We and our financial partners together <br />make this possible for you!</h2>

						<Row className={financialStyle.list + " justify-content-center"}>
							<Col md="4" sm="6" lg="4" xl={2}>
								<div className={financialStyle.item} data-aos="fade-down" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="400">
									<div className={financialStyle.img}>
										<a href="https://www.hdbfs.com/customer-services/customer-support" target="_blank" rel="noreferrer">
											<img className="w-100" src={financial1} alt="Contact HDB finance services" />
										</a>
									</div>
									<a href="https://www.hdbfs.com/customer-services/customer-support" target="_blank" rel="noreferrer">  <button className={ButtonStyle.whiteBtn + ' ' + financialStyle.whiteBtn}>Contact HDB finance services</button></a>
								</div>
							</Col>
							<Col md="4" sm="6" lg="4" xl={2}>
								<div className={financialStyle.item} data-aos="fade-down" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="500">
									<div className={financialStyle.img}>
										<a href="https://www.cholamandalam.com" target="_blank" rel="noreferrer">
											<img className="w-100" src={financial2} alt="Contact Chola" />
										</a>
									</div>
									<a href="https://www.cholamandalam.com" target="_blank" rel="noreferrer">
										<button className={ButtonStyle.whiteBtn + ' ' + financialStyle.whiteBtn}>Contact Chola</button>
									</a>
								</div>
							</Col>
							<Col md="4" sm="6" lg="4" xl={2}>
								<div className={financialStyle.item} data-aos="fade-down" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="600">
									<div className={financialStyle.img}>
										<a href="https://www.arthmate.com/" target="_blank" rel="noreferrer">
											<img className="w-100" src={financial3} alt="Contact  Arthmate" />
										</a>
									</div>
									<a href="https://www.arthmate.com/" target="_blank" rel="noreferrer">
										<button className={ButtonStyle.whiteBtn + ' ' + financialStyle.whiteBtn}>Contact  Arthmate</button>
									</a>
								</div>
							</Col>
							<Col md="4" sm="6" lg="4" xl={2}>
								<div className={financialStyle.item} data-aos="fade-down" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="700">
									<div className={financialStyle.img}>
										<a href="https://www.icicibank.com/" target="_blank" rel="noreferrer">
											<img className="w-100" src={financial4} alt="Contact ICICI Bank" />
										</a>
									</div>
									<a href="https://www.icicibank.com/" target="_blank" rel="noreferrer">
										<button className={ButtonStyle.whiteBtn + ' ' + financialStyle.whiteBtn}>Contact ICICI Bank</button>
									</a>
								</div>
							</Col>
							<Col md="4" sm="6" lg="4" xl={2}>
								<div className={financialStyle.item} data-aos="fade-down" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="700">
									<div className={financialStyle.img}>
										<a href="https://www.piramalfinance.com/" target="_blank" rel="noreferrer">
											<img className="w-100" src={PiramalLogo} alt="Contact Piramal Finance" />
										</a>
									</div>
									<a href="https://www.piramalfinance.com/" target="_blank" rel="noreferrer">
										<button className={ButtonStyle.whiteBtn + ' ' + financialStyle.whiteBtn}>Contact Piramal Finance</button>
									</a>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
			<Footer />
		</div >
	);
};
export default Financial_partners;
import React, { useEffect } from "react";
import careerStyle from "../Style/career.module.css";
import ButtonStyle from '../Style/Button.module.css'
import Header from '../common/Header';
import Footer from '../common/Footer';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import AOS from 'aos';
import 'aos/dist/aos.css';
import employment from '../../assets/images/employment.svg';
import experience from '../../assets/images/experience.svg';
import location from '../../assets/images/location.svg';
import upload_resume from '../../assets/images/upload_resume.svg';
import { useState } from "react";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set } from "firebase/database";
import { getStorage, ref as refs, uploadBytes } from "firebase/storage";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

var firebaseConfig = {
    // Firebase credentials
    apiKey: "AIzaSyDlfqGfxv44849bJMMuYUbsEaKXofZTmG0",
    authDomain: "paytailwebview.firebaseapp.com",
    databaseURL: "https://paytailwebview-default-rtdb.firebaseio.com",
    projectId: "paytailwebview",
    storageBucket: "paytailwebview.appspot.com",
    messagingSenderId: "494975397592",
    appId: "1:494975397592:web:3db4a1431781c269ce31ad",
    measurementId: "G-2HNTPXMJ6K"
  };
   
  
  const candidateDetails = {
      position: "",
      firstName: "",
      lastName: "",
      emailAddress: "",
      expectedCTC: "",
      currentCTC: "",
      telephoneNo: "",
      uploadResume: ""
    }

const Job_desc = () => {
    const [candidateDetail, setCandidateDetail] = useState(candidateDetails);
        const [open, setOpen] = useState(false)
        const [error, setError] = useState(true)
        
        const handleSetDetail = (res) => {
            if(error){
                setError(false)
            }
            let name = res.target.name;
            setCandidateDetail({...candidateDetail, [name]: res.target.value})
        }
    const page = window.location.pathname.split('/')[2]

    useEffect(() => {
        
        AOS.init();
      }, [])
      const [show, setShow] = useState(false);
    
      const applynowClose = () => setShow(false);
      const applynow = (name) => {
        setCandidateDetail({...candidateDetail, position: name})
        setShow(true);
      }

      const handleSubmit = (res) => {
        sub();
     }

      const app = initializeApp(firebaseConfig);
      const db = getDatabase(app);
      const sub = () => {
          if(candidateDetail.firstName == "" || candidateDetail.lastName == "") {
              setError(true)
              return false
          }
          let candidateId = Math.floor(Math.random() * 10000);
          set(ref(db, 'candidate/' + candidateId+"-"+candidateDetail.position), {
              ...candidateDetail,
          }).then(res=> setOpen(true))
          setShow(false)
      }

      const storage = getStorage(app);
    
      // Upload the file and metadata
      const uploadTask = (event) => {
          let candidateId = Math.floor(Math.random() * 10000);
          const storageRef = refs(storage, `documents/${candidateId+"-"+event.target.files[0].name}`);
          uploadBytes(storageRef, event.target.files[0]).then((snapshot) => {
            console.log(snapshot)
              setCandidateDetail({...candidateDetail, uploadResume: snapshot.metadata.name})
          });;
      }

      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };

  return (
        <div className="main">
            <Header />
            <div className={careerStyle.job_desc}>               
                <div className={careerStyle.top}>
                    <h2 className="secTitle">Job Descriptions</h2>
                </div>            
                <div className={careerStyle.detail}>
                    {page === 'Lead-Data-Scientist' && <Container>
                        <h2>Lead Data Scientist</h2>
                        <div className={careerStyle.top_action}>
                            <ul>
                                <li>
                                    <img className="img-fluid" src={employment} alt="Employment" />
                                    <span><strong>Employment Type </strong>: Full time</span>
                                </li>
                                <li>
                                    <img className="img-fluid" src={experience} alt="Experience" />
                                    <span><strong>Experience </strong>: 6+ years</span>
                                </li>
                                <li>
                                    <img className="img-fluid" src={location} alt="Location" />
                                    <span><strong>Location </strong>: Sector 45, Gurugram</span>
                                </li>
                            </ul>
                            <p>We are looking to hire a dynamic and passionate Lead Data Scientist for our Data Science engineering team who is hands-on, energetic, ambitious, go-getter, loves to explore new technologies and can match our fast-paced growth</p>
                        </div>  
                        <div className={careerStyle.key_points}>
                            <h2>As a Lead Data Scientist in our Data Science team, you will:</h2>
                            <ul>
                                <li>Explore new technologies in the space, do the POCs and drive tech innovations at Paytail.</li>
                                <li>Establish best of the standards and practices for you and the team to deliver effective models with clean, secure, scalable and maintainable code.</li>
                                <li>Lead, build and grow the Data Science engineering team and enable the team to build and ship quality models.</li>
                                <li>Collaborate with business, engineering and other teams to resolve ambiguities and drive Paytail’s growth vision.</li>
                            </ul>
                        </div>
                        <div className={careerStyle.key_points + ' ' + careerStyle.requirements}>
                            <h2>Key Requirements: </h2>
                            <ul>
                                <li>Should have top-notch problem-solving skills who can understand complex variables for various credit models.</li>
                                <li>Should be well versed with data modelling and predictive analytics.</li>
                                <li>Should help in setting-up the strategy and standards for Modelling, Machine Learning and Advanced Analytics.</li>
                                <li>Provide technical leadership and mentoring data scientists and analytics professionals.</li>
                                <li>Work experience on credit underwriting, fraud prevention, risk modelling would be a plus.</li>
                                <li>Excellent learning skills and ability to thrive in a dynamic, collaborative and fast-paced environment.</li>
                                <li>Should be a good team player who can work with cross functional teams.</li>
                            </ul>
                        </div>
                        <button onClick={()=>applynow('Lead Data Scientist')} className={ButtonStyle.whiteBtn + ' ' + careerStyle.whiteBtn}>Apply now</button>
                    </Container>}
                    {page === 'Product-Manager' && <Container>
                        <h2>Product Manager</h2>
                        <div className={careerStyle.top_action}>
                            <ul>
                                <li>
                                    <img className="img-fluid" src={employment} alt="Employment" />
                                    <span><strong>Employment Type </strong>: Full time</span>
                                </li>
                                <li>
                                    <img className="img-fluid" src={location} alt="Location" />
                                    <span><strong>Location </strong>: Sector 45, Gurugram</span>
                                </li>
                            </ul>
                            <p>We are looking to hire a dynamic and passionate Product Manager who is energetic, ambitious and can match our fast-paced growth.</p>
                        </div>  
                        <div className={careerStyle.key_points}>
                            <h2>Job Responsibilities:</h2>
                            <ul>
                                <li>Work closely with engineering, design & business teams to define, scope & drive strategic roadmap.</li>
                                <li>Engaging team from strategic planning to tactical creation of user stories, use cases, & acceptance criteria.	</li>
                                <li>Identify key insights and develop business case requirements by analyzing data, market trends, and competitive products.</li>
                                <li>Be the champion for product & customer success KPIs and continually seek paths to drive improvements.</li>
                                <li>Rapidly test and iterate hypotheses to improve metrics.</li>
                                <li>Work with partners to extend value proposition and champion new integrations.</li>
                            </ul>
                        </div>
                        <div className={careerStyle.key_points + ' ' + careerStyle.requirements}>
                            <h2>Competencies: </h2>
                            <ul>
                                <li>Strong communication\collaboration skills and a good team player.</li>
                                <li>Hands on product manager, who can effectively balance business, engineering and technical requirements.</li>
                                <li>An exceptional communicator and comfortable communicating with a broad range of stakeholders and customers.</li>
                                <li>Experience in conceptualizing, defining, and commercializing new product features.</li>
                                <li>Experience in working closely with data to derive actionable insights and learnings.</li>
                                <li>Experience with customer research; enjoy digging deep on use case details and customer needs.</li>
                                <li>Experience as a Product Owner in an agile environment. Experience “owning” and prioritizing the product backlog for a diverse set of stakeholders.</li>
                                <li>Good to have product management experience in any of fraud, risk, payments, ecommerce or lending products.</li>
                            </ul>
                        </div>
                        <button onClick={()=>applynow('Product-Manager')} className={ButtonStyle.whiteBtn + ' ' + careerStyle.whiteBtn}>Apply now</button>
                    </Container>}
                    {page === 'Senior-QA-engineer' && <Container>
                        <h2>SENIOR QA ENGINEER</h2>
                        <div className={careerStyle.top_action}>
                            <ul>
                                <li>
                                    <img className="img-fluid" src={employment} alt="Employment" />
                                    <span><strong>Employment Type </strong>: Full time</span>
                                </li>
                                <li>
                                    <img className="img-fluid" src={experience} alt="Experience" />
                                    <span><strong>Experience </strong>: 4-6 years</span>
                                </li>
                                <li>
                                    <img className="img-fluid" src={location} alt="Location" />
                                    <span><strong>Location </strong>: Sector 45, Gurugram</span>
                                </li>
                            </ul>
                            <p>We are looking to hire a dynamic and passionate Senior QA Engineer for our QA team who is energetic, ambitious, go-getter, loves to explore new technologies and can match our fast-paced growth.</p>
                        </div>
                        <div className={careerStyle.key_points + ' ' + careerStyle.requirements}>
                            <h2>Key Requirements: </h2>
                            <ul>
                                <li>Ability to think, write and test thoroughly to cover even remote\edge testcases.</li>
                                <li>Hands on experience in working with automation frameworks using Java.</li>
                                <li>Hands on experience with TestNG, Junit, Maven, GIT and Jenkins.</li>
                                <li>Hands on experience with testing of REST APIs and SQL\NoSQL DBs.</li>
                                <li>Excellent learning skills and ability to thrive in a dynamic, collaborative and fast-paced environment.</li>
                                <li>Should have excellent analytical skills.</li>
                                <li>Should be a good team player who can work with cross functional teams.</li>
                            </ul>
                        </div>
                        <button onClick={()=>applynow('Senior-QA-engineer')} className={ButtonStyle.whiteBtn + ' ' + careerStyle.whiteBtn}>Apply now</button>
                    </Container>}
                    {page === 'Manager-Data-Science' && <Container>
                        <h2>Manager Data Science</h2>
                        <div className={careerStyle.top_action}>
                            <ul>
                                <li>
                                    <img className="img-fluid" src={employment} alt="Employment" />
                                    <span><strong>Employment Type </strong>: Full time</span>
                                </li>
                                <li>
                                    <img className="img-fluid" src={location} alt="Location" />
                                    <span><strong>Location </strong>: Sector 45, Gurugram</span>
                                </li>
                            </ul>
                            <p>We are looking to hire a dynamic and passionate Manager - Data Science for our Data Science 
                                engineering team who is hands-on, energetic, ambitious, go-getter, loves to explore new 
                                technologies and can match our fast-paced growth. PFB the Job details and if you find it 
                                suitable for your passion and next career move, please revert with your updated resume.</p>
                        </div>  
                        <div className={careerStyle.key_points}>
                            <h2>As a Manager in our Data Science team, you will:</h2>
                            <ul>
                                <li>Explore new technologies in the space, do the POCs and drive tech innovations at Paytail.</li>
                                <li>Establish best of the standards and practices for you and the team to deliver effective models with clean, secure, scalable and maintainable code.</li>
                                <li>Lead, build and grow the Data Science engineering team and enable the team to build and ship quality models.</li>
                                <li>Collaborate with business, engineering and other teams to resolve ambiguities and drive Paytail’s growth vision.</li>
                            </ul>
                        </div>
                        <div className={careerStyle.key_points + ' ' + careerStyle.requirements}>
                            <h2>Key Requirements: </h2>
                            <ul>
                                <li>Should have top-notch problem-solving skills who can understand complex variables for various credit models.</li>
                                <li>Should be well versed with data modelling and predictive analytics.</li>
                                <li>Should help in setting-up the strategy and standards for Modelling, Machine Learning and Advanced Analytics.</li>
                                <li>Provide technical leadership and mentoring data scientists and analytics professionals.</li>
                                <li>Work experience on credit underwriting, fraud prevention, risk modelling would be a plus.</li>
                                <li>Excellent learning skills and ability to thrive in a dynamic, collaborative and fast-paced 	environment.</li>
                                <li>Should be a good team player who can work with cross functional teams.</li>
                            </ul>
                        </div>
                        <button onClick={()=>applynow('Manager-Data-Science')} className={ButtonStyle.whiteBtn + ' ' + careerStyle.whiteBtn}>Apply now</button>
                    </Container>}
                    {page === 'Credit-manager' && <Container>
                        <h2>Credit Manager</h2>
                        <div className={careerStyle.top_action}>
                            <ul>
                                <li>
                                    <img className="img-fluid" src={employment} alt="Employment" />
                                    <span><strong>Employment Type </strong>: Full time</span>
                                </li>
                                <li>
                                    <img className="img-fluid" src={location} alt="Location" />
                                    <span><strong>Location </strong>: Sector 45, Gurugram</span>
                                </li>
                            </ul>
                            <p>As a Credit Manager/ Credit Analyst in Credit & Risk team at Paytail, you will apply your Underwritingand 
                                analytical skills to major company challenges. You’ll team with best in industry. Professionals to develop 
                                and test risk strategies that ultimately impact the bottom line (revenue growth and risk management) and you
                                 will do it all in a collaborative environmentthat values your insight, encourages you to take on new 
                                 responsibilities, promote continuous learning, and reward innovation.  </p>
                            <p>This role will have accountability across maintaining portfolio quality by overseeing new credit line decisions, 
                                account management strategies, and recommendations for cross- sellproducts.</p>
                        </div>  
                        <div className={careerStyle.key_points}>
                            <h2>Roles and Responsibilities include, But are not limited to,</h2>
                            <ul>
                                <li><strong>Strategic</strong>: Develop business strategies that will help manage risk and drive growth for Paytail in the face of shifting consumer and regulatory demands.</li>
                                <li><strong>Credit Risk</strong>: Drive step-change improvements in credit performance by connecting drivers of future consumer credit trends to historical behavior, and testing hypotheses using rigorous monitoring and analysis.</li>
                                <li><strong>Execution</strong>: Manage and sequence delivery of business intent, build business requirements and execute strategy while working closely with the Risk Technologyteam.</li>
                                <li><strong>Partnership</strong>: Work closely with colleagues across Paytail including: AI, Risk Manual Underwriting Team, Product, Merchant, Growth and others to drive improvement in risk.</li>
                                <li><strong>Strategic and Analytics orientation</strong>: Conceptual thinking skills must be complemented bya strong quantitative orientation, given that a large part of the business is based on rigorous analytic & credit risk management.</li>
                                <li><strong>Strong Communication skills</strong>: Impeccable written and oral communication credentials,coupled with strategic influencing skills and the ability to drive agreement through intellect, interpersonal and negotiation skills.</li>
                                <li><strong>Clear results orientation</strong>: Display an intense focus on achieving both short and long term goals. He/she should be able to drive and execute an agenda in an uncertain and fluid environment.</li>
                            </ul>
                        </div>
                        <div className={careerStyle.key_points + ' ' + careerStyle.requirements}>
                            <h2>Preferred Qualifications: </h2>
                            <ul>
                                <li>Bachelor’s degree with 2 years of experience in credit underwriting.</li>
                                <li>1+ years’ experience in credit risk management roles in any bank, NBFC, or E-commerce start-up.</li>
                                <li>Fresher’s with MBA in Finance can also apply (2021 year Passout).</li>
                            </ul>
                        </div>
                        <div className={careerStyle.key_points + ' ' + careerStyle.requirements}>
                            <h2>Required Skills: </h2>
                            <ul>
                                <li>Strong analytical mind-set and numeric abilities.</li>
                                <li>Excellent verbal and written communication skills.</li>
                                <li>Strong knowledge of credit risk analysis/underwriting principles, methods, and practices.</li>
                            </ul>
                        </div>
                        <button onClick={()=>applynow('Credit Manager')} className={ButtonStyle.whiteBtn + ' ' + careerStyle.whiteBtn}>Apply now</button>
                    </Container>}
                    {page === 'Area-Sales-Manager' && <Container>
                        <h2>Area Sales Manager</h2>
                        <div className={careerStyle.top_action}>
                            <ul>
                                <li>
                                    <img className="img-fluid" src={employment} alt="Employment" />
                                    <span><strong>Employment Type </strong>: Full time</span>
                                </li>
                                <li>
                                    <img className="img-fluid" src={experience} alt="Experience" />
                                    <span><strong>Experience </strong>: 4-8 years</span>
                                </li>
                                <li>
                                    <img className="img-fluid" src={location} alt="Location" />
                                    <span><strong>Location </strong>: North: Delhi-NCR (P1), Jaipur (P2), Chandigarh, Lucknow, 
                                    Indore South: Chennai (P4), Coimbatore, Cochin(P5), Hyderabad, Bangalore (P3), Mysore</span>
                                </li>
                            </ul>
                        </div>  
                        <div className={careerStyle.key_points}>
                            <h2>Job Description:</h2>
                            <ul>
                                <li>Recruiting and Retaining high performing FOS team to drive merchant acquisition, engagement and retention goals </li>
                                <li>Setting sales targets for individual reps and your team as a whole. </li>
                                <li>Should be able to device the best methods for communication of plans/targets to the team so that there is minimum expectation vs delivery gap.</li>
                                <li>Monitoring your team's performance and motivating them to reach targets. Put in place processes and metrics for tracking progress to the plan and setup review mechanisms with all stakeholders.</li>
                                <li>Ensuring highest levels of employee relationship, motivation & engagement to drive results & high levels of employee satisfaction and drive employee development.  </li>
                                <li>Identifying the need of employee training and driving the training program Compiling and analysing sales figures </li>
                                <li>Ensuring effective deployment, training and servicing of merchants to drive merchant relationships.</li>
                                <li>Develop a keen and sharp understanding of local merchant/customer's need and Handle their escalations in market & partner with various internal stakeholders to resolve them. </li>
                                <li>Collecting customer feedback and market research.</li>
                                <li>Monitor competitors' activity in the market, share best practices and ensure appropriate response strategies are formulated and implemented.</li>
                                <li>Keeping up to date with products and competitors </li>
                                <li>Possibly dealing with some major customer accounts yourself.</li>
                                <li>Collaborate with other functions (Ops, Marketing, Credit etc.) to ensure sales team gets the necessary support to develop and grow business and achieve targets.</li>
                            </ul>
                        </div>
                        <div className={careerStyle.key_points + ' ' + careerStyle.requirements}>
                            <h2>Preferrable candidate: </h2>
                            <p>Bachelor's Degree or its equivalent in Marketing and Business Administration or any other related field.</p>
                            <ul>
                                <li>4-8 years of experience in business development/channel sales/fintech.</li>
                                <li>Demonstrable track record of business development, increasing sales, drive process and man power optimisation preferably in the fintech/lending sector.</li>
                                <li>Fluent communication and ability to manage situations on the ground.</li>
                                <li>Should have managed larger teams on the ground level.</li>
                                <li>Problem-solving ability.</li>
                                <li>A team leader with an objective approach towards goal setting and monitoring. Strong track record of leading teams to deliver stretch projects with high impact.</li>

                            </ul>
                        </div>
                        <button onClick={()=>applynow('Area Sales Manager')} className={ButtonStyle.whiteBtn + ' ' + careerStyle.whiteBtn}>Apply now</button>
                    </Container>}
                </div>    
            </div>
                
            <Footer />
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="modal-theme" show={show} onHide={applynowClose}>
                <Modal.Header closeButton>
                <Modal.Title className="secTitle">Apply Job</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md="6"  xs="12">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>First Name<sup>*</sup></Form.Label>
                                    <Form.Control name="firstName" type="text" onChange={handleSetDetail}/>
                                </Form.Group>
                            </Col>
                            <Col md="6"  xs="12">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Last Name<sup>*</sup></Form.Label>
                                    <Form.Control name="lastName" type="text" onChange={handleSetDetail} />
                                </Form.Group>
                            </Col>
                            <Col md="6"  xs="12">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control name="emailAddress" type="email" onChange={handleSetDetail}/>
                                </Form.Group>
                            </Col>
                            <Col md="6"  xs="12">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Telephone No</Form.Label>
                                    <Form.Control name= "telephoneNo" type="text" onChange={handleSetDetail}/>
                                </Form.Group>
                            </Col>
                            <Col md="6"  xs="12">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Current CTC</Form.Label>
                                    <Form.Control name="currentCTC" type="text" onChange={handleSetDetail} />
                                </Form.Group>
                            </Col>
                            <Col md="6"  xs="12">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Expected CTC</Form.Label>
                                    <Form.Control name="expectedCTC" type="text" onChange={handleSetDetail}/>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="upload-resume">
                       <img className="img-fluid" src={upload_resume} alt="Upload Resume" /> Upload Resume
                       <Form.Control type="file" accept="application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation" onChange={uploadTask} />
                    </Button>
                    <Button className="btn-theme" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
            <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',}} open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert variant="filled" severity="success" onClose={handleClose}>Successfully Submitted!</Alert>
            </Snackbar>
        </div>
    );
};
export default Job_desc;
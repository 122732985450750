import React from "react";
import policyStyle from "../Style/policyStyle.module.css";
import { Link } from "react-router-dom";
  
const MerchantTerms = () => {
  return (
    <div className="policy_content">
    <h2 className={policyStyle.header}>
      PAYTAIL MERCHANT-TERMS OF USE
    </h2>
    <p>
      <strong>
        This Paytail Merchant- Terms of Use is an electronic record in terms of Information
        Technology Act, 2000 and rules there under as applicable and the
        amended provisions pertaining to electronic records in various
        statutes as amended by the Information Technology Act, 2000. This
        electronic record is generated by a computer system and does not
        require any physical or digital signatures.
      </strong>
    </p>
    <p>
      <strong>
        This Paytail Merchant- Terms of Use is published in accordance with the provisions of Rule 3
        (1) of the Information Technology (Intermediaries guidelines) Rules,
        2011 that require publishing the rules and regulations, <Link to="/" className={policyStyle.links_styles}>www.paytail.com</Link> and Terms of Use for access or usage of domain name [www.paytail.com]
        (“Website”), including the related mobile site and mobile application
        (hereinafter referred to as “Paytail App or Platform”))
      </strong>
    </p>
    <br />
    <p>
      The Paytail App/Platform is owned by Paytail Commerce Private Limited a company
      incorporated under the Companies Act, 2013 with its registered office at
      HEWO-1 C.G.H.S House no-2 605 Plot no-16 Sec 56 GURUGRAM Gurgaon HR
      122001 IN.
    </p>
    <p>
      For the purpose of these Terms of Use, wherever the context so requires "You" or "User"
      or “Merchant” or “Seller” shall mean any natural or legal person who has agreed to list
      their Product/ Service on Paytail App/ Platform by providing registration data while
      registering on the Platform as Registered User using the computer systems.
    </p>
    <p>
      Your use of the Platform and services and tools are governed by the
      following terms and conditions ("Terms of Use") as applicable to the
      Platform including the applicable policies which are incorporated herein
      by way of reference. If You transact on the Platform, You shall be
      subject to the policies that are applicable to the Platform for such
      transaction. By mere use of the Platform, You shall be contracting with
      Paytail Commerce Private Limited and these terms and conditions
      including the policies constitute Your binding obligations, with
      Paytail.
    </p>
    <p>
      <b>
        ACCESSING, BROWSING OR OTHERWISE USING THE SITE INDICATES YOUR
        AGREEMENT TO ALL THE TERMS AND CONDITIONS UNDER THESE TERMS OF USE, SO
        PLEASE READ THE TERMS OF USE CAREFULLY BEFORE PROCEEDING.
      </b>
      By impliedly or expressly accepting these Terms of Use, You also accept
      and agree to be bound by Paytail Policies ((including but not limited to <Link to="/" className={policyStyle.links_styles}>www.paytail.com</Link> available at Privacy) as amended from time to time.
    </p>
    <br />
    <h3>1. Eligibility</h3>
    <p>
      Transaction on the Platform is available only to persons who can enter into a legally
      binding contracts under Indian Contract Act, 1872. Persons who are "incompetent to
      contract" within the meaning of the Indian Contract Act, 1872. As a minor if you wish
      to transact on the Platform, such transaction on the Platform may be made by your legal
      guardian or parents. Paytail reserves the right to terminate your account and / or refuse
      to provide you with access to the Paytail/ Platform if it is brought to Paytail's notice or if it is discovered
      that
      You are under the age of 18 years and transacting on the Platform.
    </p>
    <br />
    <h3>
      2. Your Account and Registration Obligations
    </h3>
    <p>
      If You use the Platform,You shall be responsible for maintaining the
      confidentiality of your Display Name and Password and You shall be
      responsible for all activities that occur under your Display Name and
      Password. You agree that if You provide any information that is untrue,
      inaccurate, not current or incomplete or We have reasonable grounds to
      suspect that such information is untrue, inaccurate, not current or
      incomplete, or not in accordance with the this Terms of Use, We shall
      have the right to indefinitely suspend or terminate or block access of
      your membership on the Platform and refuse to provide You with access to
      the Platform.
    </p>
    <p>
      Your mobile phone number and/or e-mail address is treated as Your
      primary identifier on the Platform. It is your responsibility to ensure
      that Your mobile phone number and your email address is up to date on
      the Platform at all times. You agree to notify Us promptly if your
      mobile phone number or e-mail address changes by updating the same on
      the Platform through a onetime password verification.
    </p>
    <p>
      You agree that Paytail shall not be liable or responsible for the
      activities or consequences of use or misuse of any information that
      occurs under your Account in cases, including, where You have failed to
      update Your revised mobile phone number and/or e-mail address on the
      Website Platform.
    </p>
    <p>
      If You share or allow others to have access to Your account on the
      Platform (“Account”), by creating separate profiles under Your Account,
      or otherwise, they will be able to view and access Your Account
      information. You shall be solely liable and responsible for all the
      activities undertaken under Your Account, and any consequences
      therefrom.
    </p>
    <br />
    <h3>3. Communications</h3>
    <p>
      When You use the Platform or send emails or other data, information or
      communication to us, You agree and understand that You are communicating
      with Us through electronic records and You consent to receive
      communications via electronic records from Us periodically and as and
      when required. We may communicate with you by email or by such other
      mode of communication, electronic or otherwise.
    </p>
    <br />
    <h3>
      4. Platform for Transaction and Communication
    </h3>
    <p>
      The Platform is a platform that Users utilize to meet and interact with
      one another for their transactions. Paytail is not and cannot be a party
      to or control in any manner any transaction between the Platform's
      Users.
    </p>
    <br />
    <h3>
      5. Buyer &amp; Seller Transaction.
    </h3>
    <p>
      </p><ol>
        <li>
          All commercial/contractual terms are offered by and agreed to between Buyers and
          Sellers alone. The commercial/contractual terms include without limitation price,
          shipping costs, payment methods, payment terms, date, period and mode of delivery,
          warranties related to products and services and after sales services related to products
          and services shall be solely provided by the Seller and he/she shall keep Paytail Indemnied
          in this regard always
        </li>
        <br />
        <li>
          At no time shall Paytail hold any right, title or interest over the products
          nor shall Paytail have any obligations or liabilities in respect of such contract
          entered into between Buyers and Sellers. Paytail is not responsible for
          unsatisfactory or delayed performance of services or damages or delays as
          a result of products which are out of stock, unavailable or back ordered.
        </li>
        <br />
        <li>
          The Platform is only a platform that can be utilized by Merchant/ Seller to reach a
          larger base to buy and sell products or services. Paytail is only providing a platform
          for communication and it is agreed that the contract for sale of any of the products
          or services shall be a strictly bipartite contract between the Seller and the Buyer.
        </li>
        <br />
        <li>
          Paytail is not responsible for unsatisfactory or delayed performance of services or
          damages or delays as a result of products which are out of stock, unavailable or back
          ordered. Merchant/ Seller shall keep Paytail indemnified in this regard always.
        </li>
        <br />
        <li>
          You shall independently agree upon the manner and terms and conditions of delivery,
          payment, insurance, exchange, refund, cancellation etc. with the paytail User/Buyer
          that You transact with.
        </li>
        <br />
        <li>
          You release and indemnify Paytail and/or any of its officers and representatives from
          any cost, damage, liability or other consequence of any of the actions of the Paytail
          User/s of the Platform and specifically waive any claims that you may have in this
          behalf under any applicable law. Notwithstanding its reasonable efforts in that
          behalf, Paytail cannot take responsibility or control the information provided by
          other Users which is made available on the Platform.
        </li>
        <br />
      </ol>
    <p></p>
    <h3>6. Use of the Platform</h3>
    <p>
      You agree, undertake and confirm that Your use of Platform shall be
      strictly governed by the following binding principles:
      </p><ol>
        <li>
          You shall not host, display, upload, modify, publish, transmit,
          update or share any information which:
          <ul classname="bullet-points">
            <li>
              belongs to another person and to which You does not have any
              right to;
            </li>
            <li>
              is grossly harmful, harassing, blasphemous, defamatory, obscene,
              pornographic, paedophilic, libellous, invasive of another's
              privacy, hateful, or racially, ethnically objectionable,
              disparaging, relating or encouraging money laundering or
              gambling, or otherwise unlawful in any manner whatever; or
              unlawfully threatening or unlawfully harassing including but not
              limited to "indecent representation of women" within the meaning
              of the Indecent Representation of Women (Prohibition) Act, 1986;
            </li>
            <li>is misleading in any way;</li>
            <li>
              is patently offensive to the online community, such as sexually
              explicit content, or content that promotes obscenity,
              paedophilia, racism, bigotry, hatred or physical harm of any
              kind against any group or individual;
            </li>
            <li>harasses or advocates harassment of another person;</li>
            <li>
              involves the transmission of "junk mail", "chain letters", or
              unsolicited mass mailing or "spamming";
            </li>
            <li>
              promotes illegal activities or conduct that is abusive,
              threatening, obscene, defamatory or libellous;
            </li>
            <li>
              infringes upon or violates any third party's rights [including,
              but not limited to, intellectual property rights, rights of
              privacy (including without limitation unauthorized disclosure of
              a person's name, email address, physical address or phone
              number) or rights of publicity
            </li>
            <li>
              promotes an illegal or unauthorized copy of another person's
              copyrighted work (see "Copyright complaint" below for
              instructions on how to lodge a complaint about uploaded
              copyrighted material), such as providing pirated computer
              programs or links to them, providing information to circumvent
              manufacture-installed copy-protect devices, or providing pirated
              music or links to pirated music files;
            </li>
            <li>
              contains restricted or password-only access pages, or hidden
              pages or images (those not linked to or from another accessible
              page);
            </li>
            <li>
              provides material that exploits people in a sexual, violent or
              otherwise inappropriate manner or solicits personal information
              from anyone;
            </li>
            <li>
              provides instructional information about illegal activities such
              as making or buying illegal weapons, violating someone's
              privacy, or providing or creating computer viruses;
            </li>
            <li>
              contains video, photographs, or images of another person (with a
              minor or an adult).
            </li>
            <li>
              tries to gain unauthorized access or exceeds the scope of
              authorized access to the Platform or to profiles, blogs,
              communities, account information, bulletins, friend request, or
              other areas of the Platform or solicits passwords or personal
              identifying information for commercial or unlawful purposes from
              other users;
            </li>
            <li>
              engages in commercial activities and/or sales without Our prior
              written consent such as contests, sweepstakes, barter,
              advertising and pyramid schemes, or the buying or selling of
              "virtual" products related to the Platform. Throughout this
              Terms of Use, Paytail's prior written consent means a
              communication coming from Paytail's Legal Department,
              specifically in response to Your request, and specifically
              addressing the activity or conduct for which You seek
              authorization;
            </li>
            <li>
              solicits gambling or engages in any gambling activity which We,
              in Our sole discretion, believes is or could be construed as
              being illegal;
            </li>
            <li>
              interferes with another USER's use and enjoyment of the Platform
              or any other individual's User and enjoyment of similar
              services;
            </li>
            <li>
              refers to any Platform or URL that, in Our sole discretion,
              contains material that is inappropriate for the Platform or any
              other Platform, contains content that would be prohibited or
              violates the letter or spirit of these Terms of Use.
            </li>
            <li>harm minors in any way;</li>
            <li>
              infringes any patent, trademark, copyright or other proprietary
              rights or third party's trade secrets or rights of publicity or
              privacy or shall not be fraudulent or involve the sale of
              counterfeit or stolen products;
            </li>
            <li>violates any law for the time being in force;</li>
            <li>
              deceives or misleads the addressee/ users about the origin of
              such messages or communicates any information which is grossly
              offensive or menacing in nature;
            </li>
            <li>impersonate another person;</li>
            <li>
              contains software viruses or any other computer code, files or
              programs designed to interrupt, destroy or limit the
              functionality of any computer resource; or contains any trojan
              horses, worms, time bombs, cancelbots, easter eggs or other
              computer programming routines that may damage, detrimentally
              interfere with, diminish value of, surreptitiously intercept or
              expropriate any system, data or personal information;
            </li>
            <li>
              threatens the unity, integrity, defence, security or sovereignty
              of India, friendly relations with foreign states, or public
              order or causes incitement to the commission of any cognizable
              offence or prevents investigation of any offence or is insulting
              any other nation.
            </li>
            <li>shall not be false, inaccurate or misleading;</li>
            <li>
              shall not, directly or indirectly, offer, attempt to offer,
              trade or attempt to trade in any item, the dealing of which is
              prohibited or restricted in any manner under the provisions of
              any applicable law, rule, regulation or guideline for the time
              being in force
            </li>
            <li>
              shall not create liability for Us or cause Us to lose (in whole
              or in part) the services of Our internet service provider
              ("ISPs") or other suppliers;
            </li>
          </ul>
        </li>
        <br />
        <li>
          You shall not use any "deep-link", "page-scrape", "robot", "spider"
          or other automatic device, program, algorithm or methodology, or any
          similar or equivalent manual process, to access, acquire, copy or
          monitor any portion of the Platform or any Content, or in any way
          reproduce or circumvent the navigational structure or presentation
          of the Platform or any Content, to obtain or attempt to obtain any
          materials, documents or information through any means not purposely
          made available through the Platform. We reserve Our right to bar any
          such activity.
        </li>
        <br />
        <li>
          You shall not attempt to gain unauthorized access to any portion or
          feature of the Platform, or any other systems or networks connected
          to the Platform or to any server, computer, network, or to any of
          the services offered on or through the Platform, by hacking,
          password "mining" or any other illegitimate means.
        </li>
        <br />
        <li>
          You shall not make any negative, denigrating or defamatory
          statement(s) or comment(s) about Us or the brand name or domain name
          used by Us including the terms Paytail, Paytail.com, or otherwise
          engage in any conduct or action that might tarnish the image or
          reputation, of Paytail or sellers on platform or otherwise tarnish
          or dilute any Paytail's trade or service marks, trade name and/or
          goodwill associated with such trade or service marks, trade name as
          may be owned or used by us. You agree that You will not take any
          action that imposes an unreasonable or disproportionately large load
          on the infrastructure of the Platform or Paytail's systems or
          networks, or any systems or networks connected to Paytail.
        </li>
        <br />
        <li>
          You may not forge headers or otherwise manipulate identifiers in
          order to disguise the origin of any message or transmittal You send
          to Us on or through the Platform or any service offered on or
          through the Platform. You may not pretend that You are, or that You
          represent, someone else, or impersonate any other individual or
          entity.
        </li>
        <br />
        <li>
          You may not use the Platform or any content for any purpose that is
          unlawful or prohibited by these Terms of Use, or to solicit the
          performance of any illegal activity or other activity which
          infringes the rights of Paytail and / or others.
        </li>
        <br />
        <li>
          You shall at all times ensure full compliance with the applicable
          provisions of the Information Technology Act, 2000 and rules
          thereunder as applicable and as amended from time to time and also
          all applicable Domestic laws, rules and regulations (including the
          provisions of any applicable Exchange Control Laws or Regulations in
          Force) and International Laws, Foreign Exchange Laws, Statutes,
          Ordinances and Regulations (including, but not limited to Sales
          Tax/VAT, Income Tax, Octroi, Service Tax, Central Excise, Custom
          Duty, Local Levies) regarding Your use of Our service and Your
          listing, purchase, solicitation of offers to purchase, and sale of
          products or services. You shall not engage in any transaction in an
          item or service, which is prohibited by the provisions of any
          applicable law including exchange control laws or regulations for
          the time being in force.
        </li>
        <br />
        <li>
          From time to time, You shall be responsible for providing
          information relating to the products or services proposed to be sold
          by You. In this connection, You undertake that all such information
          shall be accurate in all respects. You shall not exaggerate or over
          emphasize the attributes of such products or services so as to
          mislead other Users in any manner
        </li>
        <br />
        <li>
          You shall not engage in advertising to, or solicitation of, other
          Users of the Platform to buy or sell any products or services,
          including, but not limited to, products or services related to that
          being displayed on the Platform or related to us. You may not
          transmit any chain letters or unsolicited commercial or junk email
          to other Users via the Platform. It shall be a violation of these
          Terms of Use to use any information obtained from the Platform in
          order to harass, abuse, or harm another person, or in order to
          contact, advertise to, solicit, or sell to another person other than
          Us without Our prior explicit consent. In order to protect Our Users
          from such advertising or solicitation, We reserve the right to
          restrict the number of messages or emails which a user may send to
          other Users in any 24-hour period which We deems appropriate in its
          sole discretion. You understand that We have the right at all times
          to disclose any information (including the identity of the persons
          providing information or materials on the Platform) as necessary to
          satisfy any law, regulation or valid governmental request. This may
          include, without limitation, disclosure of the information in
          connection with investigation of alleged illegal activity or
          solicitation of illegal activity or in response to a lawful court
          order or subpoena. In addition, We can (and You hereby expressly
          authorize Us to) disclose any information about You to law
          enforcement or other government officials, as we, in Our sole
          discretion, believe necessary or appropriate in connection with the
          investigation and/or resolution of possible crimes, especially those
          that may involve personal injury.
        </li>
        <br />
        <li>
          Paytail reserve the right, but has no obligation, to monitor the materials
          posted on the Platform. Paytail shall have the right to remove or edit
          any content that in its sole discretion violates, or is alleged to
          violate, any applicable law or either the spirit or letter of these
          Terms of Use. Notwithstanding this right,
          <b>
            YOU REMAIN SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU
            POST ON THE PLATFORM AND IN YOUR PRIVATE MESSAGES
          </b>
        </li>
        <br />
        <li>
          It is possible that other users (including unauthorized users or
          "hackers") may post or transmit offensive or obscene materials on
          the Platform and that You may be involuntarily exposed to such
          offensive and obscene materials. It also is possible for others to
          obtain personal information about You due to your use of the
          Platform, and that the recipient may use such information to harass
          or injure You. We does not approve of such unauthorized uses, but by
          using the Platform You acknowledge and agree that We are not
          responsible for the use of any personal information that You
          publicly disclose or share with others on the Platform. Please
          carefully select the type of information that You publicly disclose
          or share with others on the Platform.
        </li>
        <br />
      </ol>
    <p></p>
    <br />
    <h3>
      7. Contents Posted on Platform
    </h3>
    <p>
      </p><ol>
        <li>
          All text, graphics, user interfaces, visual interfaces, photographs,
          trademarks, logos, sounds, music and artwork (collectively, "Content"),
          is a third party user generated content and Paytail has no control over
          such third party user generated content as Paytail is merely an
          intermediary for the purposes of this Terms of Use.
        </li>
        <br />
        <li>
          Except as expressly provided in these Terms of Use, no part of the
          Platform and no Content may be copied, reproduced, republished,
          uploaded, posted, publicly displayed, encoded, translated, transmitted
          or distributed in any way (including "mirroring") to any other computer,
          server, Platform or other medium for publication or distribution or for
          any commercial enterprise, without Paytail's express prior written
          consent.
        </li>
        <br />
      </ol>
    <p></p>
    <br />
    <h3>
      8. Registration
    </h3>
    <p>
      You will be required to enter a valid phone number while registering on the Platform. By
      registering Your phone number with us, You consent to send, either directly or through any
      other service provider, from time to time various
      information / alerts / SMS / email / notifications / what’s app / other messages and calls
      or commercial communication, and other services on the registered number(s), whether this
      number(s) is registered with National Do not call registry / National customer preference
      register or not. You also confirm that by sending any of such messages / calls, you will
      not hold us OR its third part service provider liable / institute complaint under Telecom
      commercial communications customer preference (TRAI) regulations, 2010 or such other applicable
      regulations including any amendment thereof, as may be applicable from time to time. If you
      want to stop these calls / messages, pelase write to support@paytail.com OR alternatively
      contact support through our mobile application
    </p>
    <br />
    <h3>9. Selling</h3>
    <p>
      </p><ol>
        <li>
          As a registered seller, you are allowed to list item(s) for sale on the
          Platform in accordance with the Policies which are incorporated by way
          of reference in this Terms of Use. You must be legally able to sell the
          item(s) you list for sale on our Platform. You must ensure that the
          listed items do not infringe upon the intellectual property, trade
          secret or other proprietary rights or rights of publicity or privacy
          rights of third parties. Listings may only include text descriptions,
          graphics and pictures that describe your item for sale. All listed items
          must be listed in an appropriate category on the Platform. All listed
          items must be kept in stock for successful fulfilment of sales.
        </li>
        <br />
        <li>
          The listing description of the item must not be misleading and must
          describe actual condition of the product. If the item description does
          not match the actual condition of the item, you agree to refund any
          amounts that you may have received from the Buyer. You agree not to list
          a single product in multiple quantities across various categories on the
          Platform. Paytail reserves the right to delete such multiple listings of
          the same product listed by you in various categories.
        </li>
        <br />
      </ol>
    <p></p>

    <br />
    <h3>10. Delivery</h3>
    <p>
      Seller to deliver the product within the stipulated time posted under product description
      at the time of acceptance of the order from the Paytail User/ buyer on Paytail platform.
      In case of the delay in delivery the order can be cancelled on the request of the buyer
      and Seller shall be liable to refund the money received from the buyer on account of the
      said order in accordance with the Cancellation &amp; Refund Polices posted on the Platform.
    </p>

    <br />
    <h3>11. Payment</h3>
    <p>
      While availing any of the payment method/s available on the Platform, we
      will not be responsible or assume any liability, whatsoever in respect
      of any loss or damage arising directly or indirectly to You due to:
      </p><ol>
        <li>Lack of authorization for any transaction/s, or</li>
        <li>
          Exceeding the preset limit mutually agreed by You and between
          "Bank/s", or
        </li>
        <li>Any payment issues arising out of the transaction, or</li>
        <li>Decline of transaction for any other reason/s</li>
      </ol>
    <p></p>
    <p>
      All payments made against the purchases/services on Platform by you
      shall be compulsorily in Indian Rupees acceptable in the Republic of
      India. Platform will not facilitate transaction with respect to any
      other form of currency with respect to the purchases made on Platform.
    </p>

    <br />
    <h3>12. Transaction</h3>
    <p>
      </p><ol>
        <li>
          Transactions, Transaction Price and all commercial terms such as Delivery,
          Dispatch of products and/or services are as per principal to principal bipartite
          contractual obligations between Paytail User/Buyer and Seller and payment facility
          is merely used by the Buyer and Seller to facilitate the completion of the Transaction.
          Use of the payment facility shall not render Paytail liable or responsible for the
          non-delivery, non-receipt, non-payment, damage, breach of representations and warranties,
          non-provision of after sales or warranty services or fraud as regards the products and /or
          services listed on Paytail's Platform.
        </li>
        <br />
        <li>
          Paytail reserves the right to impose limits on the number of Transactions or
          Transaction Price which Paytail may receive from on an individual Valid Credit/Debit/ Cash Card
          / Valid Bank Account/Loan/ Credit Facility through Paytail Services and such other infrastructure
          or any other financial instrument directly or indirectly through payment aggregator or
          through any such facility authorized by Reserve Bank of India to provide enabling support
          facility for collection and remittance of payment or by an individual Buyer during any time
          period, and reserves the right to refuse to process Transactions exceeding such limit.
        </li>
        <br />
        <li>
          Paytail and its Partner Merchant Bank reserves the right to refuse to process
          Transactions by Paytail Users/ Buyers with a prior history of questionable
          charges including without limitation breach of any agreements by Buyer with
          Paytail or breach/violation of any law or any charges imposed by Issuing Bank
          or breach of any policy.
        </li>

        <br />
        <li>
          Paytail may delay notifying the payment confirmation i.e. informing Seller to Dispatch,
          if Paytail deems suspicious or for Buyers conducting high transaction volumes to
          ensure safety of the Transaction and Transaction Price.
        </li>

        <br />
        <li>
          Seller acknowledge that Paytail will not be liable for any damages, interests or
          claims etc. resulting from not processing a Transaction/Transaction Price or any
          delay in processing a Transaction/Transaction Price which is beyond control of
          Paytail.
        </li>

        <br />
        <li>
          If Paytail has any suspicion or knowledge that any of its buyers and sellers are
          involved in any activity that is intended to provide claims or information that is
          false or not genuine, Paytail may also, while reserving its rights to initiate civil
          and/or criminal proceedings against such member buyers and sellers, at its sole
          discretion, suspend, block, restrict, cancel the Display Name of such buyers and
          sellers and/or disqualify that user and any related users from availing protection
          through this program. Customers who have been blocked for any suspicious or fraudulent
          activity on Paytail will not be allowed to return their products.
        </li>

        <br />
        <li>
          Paytail reserves its right to initiate civil and/or criminal proceedings against a
          user who, files a invalid and/or false claims or provides false, incomplete, or
          misleading information. In addition to the legal proceedings as aforesaid, Paytail
          may at its sole discretion suspend, block, restrict, cancel the Display Name
          [and its related Display Names] of such user and/or disqualify that user and any
          related users from availing protection through this program. Any person who,
          knowingly and with intent to injure, defraud or deceive, files a Fraudulent Complaint
          containing false, incomplete, or misleading information may be guilty of a criminal
          offence and will be prosecuted to the fullest extent of the law.
        </li>
      </ol>
    <p></p>
    <br />
    <h3>13. Invoice &amp; Chargeback</h3>
    <p>
      Whenever a chargeback comes from a payment gateway/bank, the Seller shall share with
      Paytail the original invoice of the order pertaining to the Paytail User/ Buyer Order
      w.r.t the dicputed transaction through a chargeback blaimthe expressly agrees that
      issuing the correct and complete invoice is the sole and primary responsibility of
      the Seller. Furthermore, Seller shall ensure that invoices state "Powered by Paytail"
      and failing to do so Seller will be liable for chargebacks (as applicable). For the
      intent and purposes of this Paytail Merchant Terms of Use and other polices posted on
      the Platform, the Merchant/ Seller shall retain the invoice of all orders/ transactions
      for a minimum period of 18 months from the date of issuance and further undertakes to
      share the same with Paytail as and when demanded for the purpose of verification, claim
      settlement/ chargeback issues etc.
    </p>

    <br />
    <h3>14. Cancellation &amp; Refund</h3>
    <p>
      The Merchant upon request for Return by the Buyer/Paytail User, facilitate the Return
      of the Product as per the Cancellation &amp; Refund Policy of the Merchant/Manufacturer.
      In the event of acceptance of Return, the Maerchant/Manufacturer shall, at the first
      instance, inform Paytail and initiate the refund process.
    </p>
    <p>
      In the case of cancellation &amp; refund of product/ services purchased by availing Credit
      Facility/ Loan by the Paytail User/ Buyer then the Merchant/Seller shall be under
      obligation to reverse the Transaction Price to Paytail within 24 hours of the confirmation
      of the cancellation of order from Merchant/ Seller’s end. The Merchant/manufacturer
      confirms to keep Paytail indemnified against any/all repercussions arising out of delay
      in such refund to Paytail due to which the Paytail user/ Buyer may be required to pay
      additional interest and other charges to the financier Partner Merchant Bank.
    </p>

    <br />
    <h3>15. Trademark, Copyright and Restriction</h3>
    <p>
      </p><ol>
        <li>
          This site is controlled and operated by Paytail and products are sold by respective
          Sellers. All material on this site, including images, illustrations, audio clips,
          and video clips, are protected by copyrights, trademarks, and other intellectual
          property rights. Material on Platform is solely for Your personal, non-commercial
          use. You must not copy, reproduce, republish, upload, post, transmit or distribute
          such material in any way, including by email or other electronic means and whether
          directly or indirectly and You must not assist any other person to do so. Without the
          prior written consent of the owner, modification of the materials, use of the
          materials on any other Platform or networked computer environment or use of the
          materials for any purpose other than personal, non-commercial use is a violation of
          the copyrights, trademarks and other proprietary rights, and is prohibited. Any use
          for which You receive any remuneration, whether in money or otherwise, is a commercial
          use for the purposes of this clause.
        </li>
        <br />
        <li>
          Paytail respects the intellectual property of others. In case You feel that Your
          Trademark has been infringed, You can write to Paytail at &nbsp;
          <a href="mailto: admin@paytail.com" class="links_styles">
            admin@paytail.com
          </a>
        </li>
        <br />
      </ol>
    <p></p>
    <br />
    <h3>16. Commercial Terms</h3>
    <p>
      The seller shall be liable to pay the transaction fee, subvention fee, platform fee or any other fee as may be
      applicable at transaction level as stipulated and communicated at the time of every transaction and also through
      the schedule of charges for transaction fee and platform fee as updated from time to time.

      The transaction settlement shall be net of the charges/fee for which seller shall receive the tax invoice/s as may
      be applicable.
    </p>
    <br />
    <h3>
      17. Spam Policy
    </h3>
    <p classname="text-justify">
      </p><ol classname="text-justify lower-alphabet">
        <li>
          <b>Unsolicited message-</b> Paytail's spam policy applies only to unsolicited
          commercial messages sent by Merchant/Seller. Merchant/Seller is not allowed
          to send spam messages to other paytail Users or Merchant/ Sellers.
        </li>
        <br />
        <li>
          <b>Offers to Buy or Sell Outside of Paytail - </b> - Paytail prohibits email offers
          to buy or sell listed products outside of the Paytail Platform. Offers of
          this nature are a potential fraud risk for both Buyers and Sellers.
        </li>
        <br />
        <li>
          Violations of this policy may result in a range of actions, including
          limits on Merchant/ Seller account privileges, Merchant/ Seller account
          suspension, removal of listing of products &amp; services etc.
        </li>
        <br />
      </ol>
    <p></p>

    <br />
    <h3>18. External Links</h3>
    <p>
      Paytail does not take responsibility or liability for the actions, products, content
      and services on the Platform, which are linked to Affiliates and / or third party
      Platforms using Platform's APIs or otherwise. In addition, the Platform may provide
      links to the third party Platforms of Our affiliated companies and certain other
      businesses for which, Paytail assumes no responsibility for examining or evaluating the
      products and services offered by them. Paytail do not warrant the offerings of, any of
      these businesses or individuals or the content of such third party Platform(s). Paytail
      does not endorse, in any way, any third party Platform(s) or content thereof.
    </p>

    <br />
    <h3>19. Termination</h3>
    <p>
      Paytail may forthwith terminate and/or suspend, in whole or in part, these T&amp;C, the
      Policies, Paytail App and/or the Paytail Services provided hereunder, for any reason
      including for reasons of breach of these T&amp;C and/or any of the Policies on the Paytail
      App. Your sole right to terminate these T&amp;C and the Policies is by deleting your Paytail
      Account. In the event of termination of these T&amp;C by Paytail or the Paytail User, the
      Paytail User shall be liable to pay the amounts due to Paytail or Partner Merchant Bank
      under the terms of this Agreement, as per the Statement raised by Paytail till the
      date of such termination.
    </p>

    <br />
    <h3>20. JURISDICTION</h3>
    <p>
      The courts at Gurugram, Haryana shall have exclusive jurisdiction over any disputes
      relating to the subject matter herein.
    </p>

    <br />
    <h3>21. MISCELLANEOUS TERMS</h3>
    <p>
      </p><ol>
        <li>
          <b>Notices</b>
          <p>
            All notices, requests, demands, waivers, complaints, queries and other
            communications required or permitted to be given hereunder in relation to the
            Paytail Services under these T&amp;C, shall be emailed at admin@Paytail.com, if
            to Paytail and if to Paytail User, it shall be to the address registered with
            Paytail. Any other notices, requests, demands, waivers, complaints, queries and
            communications arising out of any Partner Merchant’s acts or omissions whatsoever,
            including but not limited to deficiency of service, quality, merchantability of
            products or services, shall be resolved between the Paytail User and the Partner
            Merchant only and Paytail shall have no responsibility or liability whatsoever in
            this regard.
          </p>
        </li>
        <br />

        <li>
          <b>Intellectual Property</b>
          <ol>
            <li>
              The Paytail User acknowledges that Paytail is the sole and rightful owner of
              the Intellectual Property and the Paytail User agrees not to register, use or
              file in its own name or in the name of any other person or company any trademarks
              same or similar or resembling in any manner the Intellectual Property and not
              to associate the Intellectual Property with its own business, except for the
              purpose as specified in these T&amp;C. In this regard 'Intellectual Property'
              shall mean any and all property in any name, signature, word, letter, numeral
              or any combination thereof, software, Trademark, brand name, service mark,
              trade name, design, logo, know-how, trade secrets whether registered or not,
              belonging to Paytail.
            </li>
            <li>
              Nothing contained herein shall authorize a Paytail User to use, display or
              exploit the Intellectual Property rights of Paytail without the prior written
              consent of Paytail and all content on the Paytail App and Paytail Services is
              the copyright of Paytail except for third party content and any link to third
              party websites and/or web applications.
            </li>
            <li>
              The information provided on the Paytail App is intended, solely to provide
              general information for the personal use of the reader, who accepts full
              responsibility for its use. Paytail does not represent or endorse the accuracy
              or reliability of any information, or advertisements contained on, distributed
              through, or linked, downloaded or accessed from any of the Paytail Services
              contained on Paytail App, or the quality of any products, information or other
              materials displayed, or obtained by you as a result of an advertisement or any other
              information or offer in or in connection with the Paytail Services provided through
              the online technology platform.
            </li>

          </ol>
        </li>
        <br />
        <li>
          <b>Amendment</b>
          <p>
            These T&amp;C and/or Policies may be varied, amended or modified, in whole or in part,
            by Paytail at any time without notice to the Paytail Users.
          </p>
        </li>
        <br />

        <li>
          <b>Relationship</b>
          <p>
            These T&amp;C constitute a principal-to-principal relationship between Paytail and the Paytail Users and do not
            attempt to create any agency relationship.
            The Paytail Services are being rendered by Paytail as an independent entity and nothing contained herein
            shall be deemed to create any association, partnership, joint venture or relationship of principal and agent
            or master and servant, or employer and employee between Paytail and the Paytail Users hereto or any
            affiliates or subsidiaries thereof or to provide either Paytail or the Paytail User with the right, power or
            authority, whether express or implied to create any such duty or obligation on behalf of the other party,
            i.e. either Paytail or the Paytail User.
          </p>
        </li>
        <br />

        <li>
          <b>Assignment</b>
          <p>
            Paytail User shall not assign this Agreement to a third party under any circumstances. Paytail may assign,
            in whole or in part, the benefits or obligations of these T&amp;C to its associates of affiliates of other group
            company, or any other company, in circumstances including but not limited to pursuant to a restructuring or
            re-organization or demerger of its organization or operations causing a change in management or something of
            the nature. Paytail shall not require approval or consent of the Paytail User, it can at its discretion
            provide an intimation of such assignment to the Paytail Users. However, the Paytail User shall not assign,
            in whole or in part, the benefits or obligations of these T&amp;C,
            without the prior written permission of Paytail. However, Assignment of this Agreement by Paytail shall not
            constitute any waiver of repayment liability of Statement amount in favour of assignee entity or Partner
            Merchant Bank and User shall be bound to discharge his/her obligations in accordance with this Agreement.
          </p>
        </li>
        <br />

        <li>
          <b>Force Majeure</b>
          <p>
            Paytail shall not be liable for its failure to perform under these T&amp;C as a
            result of occurrence of any force majeure events like acts of God, fire, wars, sabotage, civil unrest,
            labour unrest, action of statutory authorities or local or central governments, change in laws, rules and
            regulations, affecting the performance of Paytail, payment gateways or Partner Merchant.
          </p>
        </li>
        <br />

        <li>
          <b>No Waiver</b>
          <p>
            Unless otherwise expressly stated, failure to exercise or delay in exercising a right or remedy, hereunder
            shall not constitute a waiver of the right or remedy or a waiver of any other rights or remedies, and no
            single or partial exercise of any right or remedy, hereunder shall prevent any further exercise of the right
            or remedy or the exercise of any other right or remedy.
          </p>
        </li>
        <br />

        <li>
          <b>Surviving Terms</b>
          <p>
            The terms and provisions of these T&amp;C, by their nature and content are intended to survive the performance
            hereof by any or all parties hereto shall so survive termination.
          </p>
        </li>
        <br />

        <li>
          <b>Enforceability</b>
          <p>
            If any provision, hereunder becomes, in whole or in part, invalid or unenforceable but would be valid or
            enforceable if some part of that provision was deleted, that provision shall apply with such deletions as
            may be necessary to make it valid. If any court/tribunal of competent jurisdiction holds any of the
            provisions, hereunder unlawful or otherwise ineffective, the remainder shall remain in full force and the
            unlawful or otherwise ineffective provision shall be substituted by a new provision reflecting the intent of
            the provision so substituted.
          </p>
        </li>
        <br />

        <li>
          <b>Non-Solicitation</b>
          <p>
            The Paytail User warrants that it shall not directly or indirectly solicit for employment, nor offer
            employment to, nor enter into any contract of services with any person employed by Paytail.
          </p>
        </li>
        <br />

        <li>
          <b>Non-exclusivity</b>
          <p>
            It is agreed and clarified that the arrangement set out by these T&amp;C between Paytail and the Paytail Users
            is on a non-exclusive basis.
          </p>
        </li>
        <br />
      </ol>
    <h3>Grievance Officer</h3>
    <p>
      In accordance with Information Technology Act 2000 and rules made there
      under and the Consumer Protection (E-Commerce) Rules, 2020, the name and
      contact details of the Grievance Officer are provided below:
    </p><br /> 
    <address>
        <em>
		  <b className={policyStyle.subheading}>Contact info below</b><br />
          Nishant Patni<br />
          Paytail Commerce Private Limited<br />
          5th Floor, Unitech Commercial Tower-2,<br />
          Block B, Greenwood City, <br />
          Sector 45, Gurugram - 122001, Haryana<br />
          <b className={policyStyle.subheading}>Contact Us</b>
          <br />
          <b>Email</b>: grievance.officer@Paytail.com
          <br />
          <b>Phone</b>: 8880200500<br />
          <b>Time</b>: Mon - Sat (9:00 - 18:00)
          <br />
        </em>
      </address>
    <p></p>
  </div>
  );
};
  
export default MerchantTerms;
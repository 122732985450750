
import React from "react";
import Banner from './Banner'
import ClientSlider from './ClientSlider';
import PaytailPower from './PaytailPower'
//import SmoothScroll from './SmoothScroll';
import ReactPageScroller from 'react-page-scroller';
import SectionContainer from '../common/SectionContainer'
import Header from '../common/Header';
import PaytailWay from './PaytailWay';
import BigNoFrom from './BigNoFrom';
import NewDeals from './NewDeals';
import WaitList from "./WaitList";
import SpotUs from './SpotUs';
import Testimonial from './Testimonial';
import Footer from '../common/Footer';

export default class HomeRoute extends React.Component {
    constructor(props) {
      super(props);
      this.state = { currentPage: null };
    }
  

    render() {
  
      return (
          <div>
    
            <Banner />
            <PaytailPower />
             <PaytailWay />
            <BigNoFrom />
            <NewDeals />
            <WaitList />
            <SpotUs />
            <Testimonial />
            <Footer />
            </div>
      );
    }
  }

// const HomeRoute = ()=>{
//     return(
//         <div>
//             <Header/>
//             <Banner />
//             <ClientSlider/>
//             <PaytailPower />
//             <PaytailWay />
//             <BigNoFrom />
//             <NewDeals />
//             <SpotUs />
//             <Testimonial />
//             <Footer />
//         </div>
//     )
// }

//export default HomeRoute;
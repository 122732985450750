import React, { useEffect } from "react";
import aboutStyle from "../Style/about.module.css";
import ButtonStyle from '../Style/Button.module.css'
import Header from '../common/Header';
import Footer from '../common/Footer';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import AOS from 'aos';
import 'aos/dist/aos.css';
import about_banner from '../../assets/images/about-banner.jpg';
import point1 from '../../assets/images/point1.png';
import point2 from '../../assets/images/point2.png';
import point3 from '../../assets/images/point3.png';
import untaped from '../../assets/images/untaped.jpg';
import vikas from '../../assets/images/vikas.jpg';
import amit from '../../assets/images/amit.jpg';
import reena from '../../assets/images/reena.jpg';
import rati from '../../assets/images/rati.jpg';
import pranav from '../../assets/images/pranav.jpg';
import rajat from '../../assets/images/rajat.jpg';
import gaurav from '../../assets/images/gaurav.jpg';
import linkedin from '../../assets/images/linkedin.png';
import news1 from '../../assets/images/news1.jpg';
import news2 from '../../assets/images/news2.jpg';
import news3 from '../../assets/images/news3.jpg';
import news4 from '../../assets/images/news4.jpg';
import news5 from '../../assets/images/news5.jpg';
import news6 from '../../assets/images/news6.jpg';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const About = () => {
    useEffect(() => {
        AOS.init();
    }, [])
    const openIncurrentTab = url => {
        window.open(url, '_top', 'noopener,noreferrer');
    };
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 2
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <div className="main">
            <Header />
            <div className={aboutStyle.about_main + ' about_main'}>
                <div className={aboutStyle.inner_banner}>
                    <img className="w-100" src={about_banner} alt="Financial Partners" />
                    <div className={aboutStyle.content} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="400">
                        <h2>Who are we?</h2>
                        <p>We are a fintech startup with a mission to create real value across retail trade</p>
                    </div>
                </div>
                <div className={aboutStyle.solving_points}>
                    <Container>
                        <p data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="400">We do this by solving for these points!</p>
                        <Row className={aboutStyle.list + " justify-content-center"}>
                            <Col md="4" sm="6">
                                <div className={aboutStyle.item} data-aos="fade-down" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="400">
                                    <div className={aboutStyle.img}>
                                        <img className="img-fluid" src={point1} alt="Access to credit" />
                                    </div>
                                    <h4>Access to credit</h4>
                                    <p>Access to consumer credit & convenience in offline stores in underserved and un-penetrated markets</p>
                                </div>
                            </Col>
                            <Col md="4" sm="6">
                                <div className={aboutStyle.item} data-aos="fade-down" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="500">
                                    <div className={aboutStyle.img}>
                                        <img className="img-fluid" src={point2} alt="HELP SMALL SELLERS SELL MORE" />
                                    </div>
                                    <h4>Help small sellers sell more</h4>
                                    <p>Help small sellers to convert potential buyers as well as create opportunities for them to cross sell and upsell</p>
                                </div>
                            </Col>
                            <Col md="4" sm="6">
                                <div className={aboutStyle.item} data-aos="fade-down" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="600">
                                    <div className={aboutStyle.img}>
                                        <img className="img-fluid" src={point3} alt="Enhance user experience" />
                                    </div>
                                    <h4>Enhance user experience</h4>
                                    <p>Bring speed, transparency, and experience in the process to access credit in retail trade</p>
                                </div>
                            </Col>
                        </Row>
                        <h2 data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="400">We believe that India is poised for exponential growth in this decade and retail trade will be the primary driver for it.</h2>
                    </Container>
                </div>
                <div className={aboutStyle.untaped_banner}>
                    <img className="w-100" src={untaped} alt="Paytail" />
                    <h2
                        className="w-100"
                        data-aos="fade-up" data-aos-duration="500"
                        data-aos-easing="ease-in-sine"
                        data-aos-delay="400"
                    >We want to close in on the untapped potential of the Indian Retail market and achieve several concurring steps of growth. Paytail firmly believes in and how it can extend a hand to retail for conjugating the perfect environment for success along with positioning them correspondingly and equally in the economic framework
                    </h2>
                </div>
                <div className={aboutStyle.team}>
                    <Container>
                        <h2 className="secTitle">Meet Our Team</h2>
                        <Row className={aboutStyle.list + " justify-content-center"}>
                            <Col xl="3" lg="4" sm="6">
                                <div className={aboutStyle.item} data-aos="fade-down" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="400">
                                    <div className={aboutStyle.img}>
                                        <img className="w-100" src={vikas} alt="vikas" />
                                    </div>
                                    <div className={aboutStyle.content}>
                                        <a href="https://www.linkedin.com/in/vikasgarg123/" target='_blank' rel="noreferrer" className={aboutStyle.alignInline}>
                                            <h4>Vikas Garg</h4>
                                            <img className={aboutStyle.imgFluid} src={linkedin} alt="linkedin" />
                                        </a>
                                        <p>Co-Founder & CEO</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xl="3" lg="4" sm="6">
                                <div className={aboutStyle.item} data-aos="fade-down" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="500">
                                    <div className={aboutStyle.img}>
                                        <img className="w-100" src={amit} alt="amit" />
                                    </div>
                                    <div className={aboutStyle.content}>
                                        <a href="https://www.linkedin.com/in/amit-chaturvedi-219803173/" target='_blank' rel="noreferrer" className={aboutStyle.alignInline}>
                                            <h4>Amit Chaturvedi</h4>
                                            <img className={aboutStyle.imgFluid} src={linkedin} alt="linkedin" />
                                        </a>
                                        <p>Co-Founder</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xl="3" lg="4" sm="6">
                                <div className={aboutStyle.item} data-aos="fade-down" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="600">
                                    <div className={aboutStyle.img}>
                                        <img className="w-100" src={reena} alt="reena" />
                                    </div>
                                    <div className={aboutStyle.content}>
                                        <a href="https://www.linkedin.com/in/reena-yadav-7523448/" target='_blank' rel="noreferrer" className={aboutStyle.alignInline}>
                                            <h4>Reena Yadav</h4>
                                            <img className={aboutStyle.imgFluid} src={linkedin} alt="linkedin" />
                                        </a>
                                        <p>CHRO</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xl="3" lg="4" sm="6">
                                <div className={aboutStyle.item} data-aos="fade-down" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="700">
                                    <div className={aboutStyle.img}>
                                        <img className="w-100" src={rati} alt="rati" />
                                    </div>
                                    <div className={aboutStyle.content}>
                                        <a href="https://www.linkedin.com/in/ratisaurabh/" target='_blank' rel="noreferrer" className={aboutStyle.alignInline}>
                                            <h4>Rati Saxena</h4>
                                            <img className={aboutStyle.imgFluid} src={linkedin} alt="linkedin" />
                                        </a>
                                        <p>CTO</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xl="3" lg="4" sm="6">
                                <div className={aboutStyle.item} data-aos="fade-down" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="400">
                                    <div className={aboutStyle.img}>
                                        <img className="w-100" src={pranav} alt="pranav" />
                                    </div>
                                    <div className={aboutStyle.content}>
                                        <a href="https://www.linkedin.com/in/pranav-pathak-a0ba98129/" target='_blank' rel="noreferrer" className={aboutStyle.alignInline}>
                                            <h4>Pranav Pathak</h4>
                                            <img className={aboutStyle.imgFluid} src={linkedin} alt="linkedin" />
                                        </a>
                                        <p>VP-Business Development</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xl="3" lg="4" sm="6">
                                <div className={aboutStyle.item} data-aos="fade-down" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="500">
                                    <div className={aboutStyle.img}>
                                        <img className="w-100" src={rajat} alt="rajat" />
                                    </div>
                                    <div className={aboutStyle.content}>
                                        <a href="https://www.linkedin.com/in/rajatkishore/" target='_blank' rel="noreferrer" className={aboutStyle.alignInline}>
                                            <h4>Rajat Kishore</h4>
                                            <img className={aboutStyle.imgFluid} src={linkedin} alt="linkedin" />
                                        </a>
                                        <p>VP-Enterprise Sales</p>
                                    </div>
                                </div>
                            </Col>
                            <Col xl="3" lg="4" sm="6">
                                <div className={aboutStyle.item} data-aos="fade-down" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="600">
                                    <div className={aboutStyle.img}>
                                        <img className="w-100" src={gaurav} alt="gaurav" />
                                    </div>
                                    <div className={aboutStyle.content}>
                                        <a href="https://www.linkedin.com/in/gaurav-shah-0a69317/" target='_blank' rel="noreferrer" className={aboutStyle.alignInline}>
                                            <h4>Gaurav Shah</h4>
                                            <img className={aboutStyle.imgFluid} src={linkedin} alt="linkedin" />
                                        </a>
                                        <p>VP-Strategic Alliances</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className={aboutStyle.news + ' news'}>
                    <Container>
                        <h2 className="secTitle">Paytail In The News</h2>
                        <div className={aboutStyle.news_carousel} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="400">
                            <Carousel
                                additionalTransfrom={0}
                                arrows
                                autoPlay
                                autoPlaySpeed={3000}
                                centerMode={false}
                                className=""
                                containerClass="container-with-dots"
                                customTransition="all 1s linear"
                                dotListClass=""
                                draggable
                                focusOnSelect={false}
                                infinite
                                itemClass=""
                                minimumTouchDrag={80}
                                pauseOnHover
                                renderArrowsWhenDisabled={false}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                responsive={{
                                    desktop: {
                                        breakpoint: {
                                            max: 3000,
                                            min: 1024
                                        },
                                        items: 2,
                                        partialVisibilityGutter: 40
                                    },
                                    mobile: {
                                        breakpoint: {
                                            max: 767,
                                            min: 0
                                        },
                                        items: 1,
                                        partialVisibilityGutter: 30
                                    },
                                    tablet: {
                                        breakpoint: {
                                            max: 1024,
                                            min: 768
                                        },
                                        items: 2,
                                        partialVisibilityGutter: 30
                                    }
                                }}
                                rewind={false}
                                rewindWithAnimation={false}
                                rtl={false}
                                shouldResetAutoplay
                                showDots={false}
                                sliderClass=""
                                swipeable
                                transitionDuration={0}
                            >
                                <div className={aboutStyle.item + ' item'}>
                                    <div className={aboutStyle.img}>
                                        <img className="w-100" src={news1} alt="News" />
                                    </div>
                                    <div className={aboutStyle.content}>
                                        <span>Dec 18, 2022</span>
                                        <a href="https://www.businessworld.in/article/Made-By-Bharat-Made-For-Bharat-Paytail-Is-Revolutionising-Offline-Retail-With-No-Cost-Paperless-EMIs/18-12-2022-458534/" target={'_blank'}><p>Made By Bharat, Made For Bharat, Paytail Is Revolutionising Offline Retail With No-Cost Paperless EMIs</p></a>
                                    </div>
                                </div>
                                <div className={aboutStyle.item + ' item'}>
                                    <div className={aboutStyle.img}>
                                        <img className="w-100" src={news2} alt="News" />
                                    </div>
                                    <div className={aboutStyle.content}>
                                        <span>Aug 16, 2022</span>
                                        <a href="https://trak.in/tags/business/2022/08/16/exclusive-interview-this-fintech-startup-is-empowering-millions-of-indian-merchants-with-instant-digital-finance/" target={'_blank'}><p>[Exclusive Interview] This Fintech Startup Is Empowering Millions Of Indian Merchants With Instant Digital Finance</p></a>
                                    </div>
                                </div>
                                <div className={aboutStyle.item + ' item'}>
                                    <div className={aboutStyle.img}>
                                        <img className="w-100" src={news3} alt="News" />
                                    </div>
                                    <div className={aboutStyle.content}>
                                        <span>Oct 26, 2022</span>
                                        <a href="https://bfsi.economictimes.indiatimes.com/news/fintech/paytail-bets-on-bharat-for-its-pay-later-solutions/95088724?_gl=1*1tysiav*_ga*MUF3bjJnazlyY01LUTlQdnprajFhQVhjUTlwRE5NYm53MFVseUVCc1V1cUdzUkJFa2R2ckRXVlV6UWJwbnBtXw" target={'_blank'}><p>Paytail bets on Bharat for its pay-later solutions</p></a>
                                    </div>
                                </div>
                                <div className={aboutStyle.item + ' item'}>
                                    <div className={aboutStyle.img}>
                                        <img className="w-100" src={news4} alt="News" />
                                    </div>
                                    <div className={aboutStyle.content}>
                                        <span>Aug 31, 2022</span>
                                        <a href="https://epaper.bizzbuzz.news/Home/ShareArticle?OrgId=318bd7dde66&imageview=0" target={'_blank'}><p>Paytail is building a robust, fully automated tech platform to simplify theentire process for the consumer and merchant network</p></a>
                                    </div>
                                </div>
                                <div className={aboutStyle.item + ' item'}>
                                    <div className={aboutStyle.img}>
                                        <img className="w-100" src={news6} alt="News" />
                                    </div>
                                    <div className={aboutStyle.content}>
                                        <span>Sep 25, 2022</span>
                                        <a href="https://timesofindia.indiatimes.com/blogs/voices/offline-retail-evolving-credit-and-the-need-for-change/" target={'_blank'}><p>Offline retail, evolving credit and the need for change</p></a>
                                    </div>
                                </div>
                                <div className={aboutStyle.item + ' item'}>
                                    <div className={aboutStyle.img}>
                                        <img className="w-100" src={news5} alt="News" />
                                    </div>
                                    <div className={aboutStyle.content}>
                                        <span>Sep 25, 2022</span>
                                        <a href="https://www.firstpost.com/opinion/11062351-11062351.html" target={'_blank'}><p>Why India's digital payment ecosystem is a revolution</p></a>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </Container>
                </div>
            </div>
            <Footer />
        </div>
    );
};
export default About;
import React, { useEffect } from "react";
import blogStyle from "../Style/blog.module.css";
import Header from '../common/Header';
import Footer from '../common/Footer';
import Container from 'react-bootstrap/Container';
import blog_detail from '../../assets/images/blog-detail1.jpg';
import blog2 from '../../assets/images/blog2.jpg';
import blog3 from '../../assets/images/blog3.jpg';
import blog4 from '../../assets/images/blog4.jpg';
import blog5 from '../../assets/images/blog5.jpg';
import blog6 from '../../assets/images/blog6.jpg';
import { FaFacebookF, FaTwitter, FaShareAlt, FaLinkedin, FaInstagram } from 'react-icons/fa'
  
const Blog_Detail = () => {
    const page = window.location.pathname.split('/')[2]
  return (
        <div className="main">
            <Header />
            <div className={blogStyle.blog_detail}>
                {page === "How-digital-lending-services-are-the-new-present-and-future" && <Container>
                    <div className={blogStyle.blog_detail_inner}>
                        <h2 className={blogStyle.secTitle + " secTitle"} style={{fontFamily:"Poppins-Regular", fontWeight: "bold"}}>
                            How digital lending services are the new present and future?
                        </h2>   
                        <div className={blogStyle.img + " text-center"}>                      
                            <img className="w-100 img-fluid" src={blog_detail} alt="Blog" /> 
                        </div>
                        <div className={blogStyle.content_inner}>
                            <div className={blogStyle.left}>
                                <div className={blogStyle.date_month}>
                                    <div className={blogStyle.date}>26</div>
                                    <div className={blogStyle.month}>DEC 22</div>
                                </div>
                                <ul className={blogStyle.social}>
                                    <li>
                                        <a href="https://www.facebook.com/PayTailIndia/" target={'_blank'}><FaFacebookF/></a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/PaytailIndia" target={'_blank'}><FaTwitter/></a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/paytail/" target={'_blank'}><FaLinkedin/></a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/paytailindia/" target={'_blank'}><FaInstagram/></a>
                                    </li>
                                    {/* <li>
                                        <a href=""><FaShareAlt/></a>
                                    </li> */}
                                </ul>
                            </div>
                            <div className={blogStyle.content}>
                                <p>“Get a loan within seconds” is an advertisement that banks constantly bombard you with. You switch a channel on your television and there comes a commercial “XYZ bank approves your loan in a minute”.</p>
                                <p>But when it comes to the ground reality, it becomes crucial to understand how these approvals in seconds have led to hours of stress for the consumers. Since the Covid 19 pandemic brought all economic activities to a standstill, which ultimately led to a drop in the approval rates for loans to small and medium enterprises. While Indian retail saw a massive surge in liquor, grocery, home maintenance, healthcare, and recreational sectors. According to a report by TransUnion Cibil, the drop in approval rates is most acute for instant personal loans (-30%) and LAP (-28%). The report also stated that as lenders and banks are expected to tighten their credit policy, due to uncertain cash flow, there would be a drop in the approval rates for all retail products.</p>
                                <p>As the demand for credit exponentially increased to 93% in the year 2020, it has become extremely essential to find a digital and faster alternative to the traditional credit system of banks and financial institutions.</p>
                                <p>Technology has entered each and every space of human life. The innovations in banking technology have increased consumers’ demand for convenience and a greater speed of service.</p>
                                <p>Fintechs are proving to be drivers of change in the lending world. The pandemic has proven to be a catalyst in the demand for digital lending services and various other digital payment services like buying on EMI.</p>
                                
                                <h3>What involves in the digital lending process?</h3>
                                <p>The digital lending process includes the offering of loans that are applied for, approved, and managed through digital channels (web platforms or mobile apps), in which lenders use digitized data to inform credit decisions and build effective customer engagement.</p>
                                
                                <h3>Which is better, traditional lending or digital lending?</h3>
                                <p>Let’s look at some key distinguishing features between traditional lending and digital lending.</p>

                                <h3>Minimal documentation</h3>
                                <p>If you go to a bank to request a loan, you are presented with an endless amount of tedious paperwork. You need to fill in forms, get photocopies done and attest all your documents. With the digital lending process, there is no need for all this. All your details get verified online, as well as your credit score is monitored easily.</p>

                                <h3>Speed of the lending process</h3>
                                <p>In traditional lending, one of the major faults that are highlighted while availing finance for small business owners is the speed of financial institutions, and how their decision-making process needs to pace up to align with their digital counterparts. While the latter takes a fraction of seconds, from registration to approval process, everything is done through easy-to-use applications.</p>

                                <h3>Borrower eligibility</h3>
                                <p>Whenever you wish to borrow money from traditional lenders, your loan approval is determined by a variety of factors like your current income, employment history, housing, assets, and above all credit scores. Depending on an individual’s ability to pay off his loans in time, his credit score gets affected. This becomes a major obstacle for new entrepreneurs and young professionals to get a loan.</p>

                                <p>While digital loan providers also consider your credit score and income, they also consider other crucial factors like education and professional background as well as potential future earnings to easily determine your creditworthiness.</p>

                                <h3>Privacy</h3>
                                <p>Online payments are completely safe and abide by all the legal guidelines in terms of financial security. You can easily apply with a digital loan partner from the safety of your house rather than physically travelling to a bank which makes them super convenient.</p>

                                <h3>Overall costs</h3>
                                <p>Banks charge a variety of hidden costs in the form of processing charges, prepayment penalties and so on. Digital lenders have effectively cut down on these other charges significantly. Most online lending platforms don’t charge you for registration as well as prepayment. This makes digital lending services hassle-free and you are able to pay your loan easily.</p>

                                <h3>Situation of digital lending platforms in india</h3>
                                <p>The digital lending market is skyrocketing in India. Digital lending platforms offer easy navigation to provide their services, by Fintechs that have a lending license. The digitization of processes allows for reduced costs and gives these Fintechs an edge over banks. Many companies have benefitted by leveraging technology to rapidly scale their operations as well as benefit the small and medium enterprises looking to grow.</p>

                                <h3>Paytail- you ideal digital loan partner!</h3>
                                <p>Paytail is a technology startup that is here to create a revolution in digital lending services. Credit has always been a major factor when it comes to the growth of small and medium enterprises. As your digital loan partner, Paytail is on a mission to empower merchants to offer credit and EMIs to consumers all over the country which ultimately boosts the sales of offline merchants. Consumers can instantly register through Paytail’s easy-to-use application and verify their details digitally. With Paytail, shortness of funds is not an issue.</p>

                                <p>With just a few clicks, the consumer is eligible for instant credit, and they can easily buy their favorite products. Using such instant digital finance solutions is making the shopping experience more pleasant than ever before.</p>
                                <p>Paytail’s primary vision is to make shopping affordable and easy for every consumer and transform the entire buying experience through scalable EMI and credit solutions. Digital lending is here to stay and bring a major change in the entire credit landscape by simplifying the loan approval processes as well as employing technology to its fullest potential. It is indeed the present as well as the future.</p>
                            
                            </div>
                        </div>
                    </div>                      
                </Container>}
                {page === "How-digitization-of-payments-can-help-the-unorganized-retail-flourish" && <Container>
                    <div className={blogStyle.blog_detail_inner}>
                        <h2 className={blogStyle.secTitle + " secTitle"} style={{fontFamily:"Poppins-Regular", fontWeight: "bold"}}>
                            How digitization of payments can help the unorganized retail flourish
                        </h2>   
                        <div className={blogStyle.img + " text-center"}>                      
                            <img className="w-100 img-fluid" src={blog2} alt="Blog" /> 
                        </div>
                        <div className={blogStyle.content_inner}>
                            <div className={blogStyle.left}>
                                <div className={blogStyle.date_month}>
                                    <div className={blogStyle.date}>26</div>
                                    <div className={blogStyle.month}>DEC 22</div>
                                </div>
                                <ul className={blogStyle.social}>
                                    <li>
                                        <a href="https://www.facebook.com/PayTailIndia/" target={'_blank'}><FaFacebookF/></a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/PaytailIndia" target={'_blank'}><FaTwitter/></a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/paytail/" target={'_blank'}><FaLinkedin/></a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/paytailindia/" target={'_blank'}><FaInstagram/></a>
                                    </li>
                                    {/* <li>
                                        <a href=""><FaShareAlt/></a>
                                    </li> */}
                                </ul>
                            </div>
                            <div className={blogStyle.content}>
                                <p>The retail scenario in India is highly dominated by the family-run “nukkad kiranas” that have a monopoly in their areas of operation. Almost 90% of the retail sector in the country is unorganized. They offer brands that are in demand in the neighborhood and notice every customer’s preferences across a wide range of products in groceries, electronics, apparel, etc. They have also been offering home delivery services for a long time now. The accountability to the customers is also much higher as everyone personally knows each other, which makes the retailers directly answerable to them. The focus on quality and customer satisfaction is more pronounced in these local mom-and-pop stores. As the sector is nearing the trillion dollar milestone, it is imperative to plug its inherent shortcomings.</p>
                                
                                <h3>Challenging the unorganized retail</h3>
                                <p>As the pandemic ensued a wave of digitization, much shopping shifted to online stores. E-commerce has been growing phenomenally ever since. The shifting consumer behavior has increased the demand for sustainable goods and essentials for a healthier lifestyle. Both the online and physical stores are trying to adapt to this shift, but the former is better placed to adjust to changing demands due to lesser investment involved in their business model. An omnichannel strategy is most feasible for retail because relying entirely on a single channel doesn’t seem right in an age that blatantly seeks balance.</p>
                                
                                <h3>Which is better, traditional lending or digital lending?</h3>
                                <p>Let’s look at some key distinguishing features between traditional lending and digital lending.</p>

                                <h3>Phygital is the way forward</h3>
                                <p>Much of the flutter in India’s unorganized retail industry, which forms around 60% of the market, is due to the lack of technology, making their operations 
                                    inefficient. It is a massive problem for the sector as inefficiencies lead to increased costs and timelines that are totally unacceptable in the current market scenario. The competition from platforms like Amazon and Flipkart can only be met with the smart use of technology. These could be in the form of ease of payment through various wallets and discount offers, time-bound deliveries, and product variety with a detailed description. </p>
                                <p>The in-store customer experience cannot be overlooked because of the digital benefits of variety and payment. There is an enhanced need to bring the low-cost EMI options to the small retailers to pass on the benefits to their customers. The way to a cashless economy is through capturing the markets where cash is most deeply ingrained. The Indian FMCG sector can benefit significantly from this digital shift as it also increases the retailers’ creditworthiness, which can help them secure loans for expansion and modernization. 
                                </p>

                                <h3>Role of fintech in Indian retail</h3>
                                <p>As the economy started to recover from the pandemic’s peak, many start-ups came to the rescue of local retail shops. They realized the need for technological implementation and upgrades in the previously underserved and unorganized retail sector. Operators such as Paytm were the first spark in India’s fintech boom. The fire has now spread throughout the country with the help of the latest technology, which is also cost-effective. As per the RBI data, the non-cash volume of transactions skyrocketed to a record 98.5 %in 2020-21. The primary reason for this boost is the convenience of digital payments</p>
                                <p>UPI payments are contributing significantly to the disruption in the retail segment. Despite being a pretty recent phenomenon, the adoption of UPI payments in the sector has accounted for 10% of the payments. The scaling scope is still enormous, as 60% of the transactions are of a value lower than Rs.200. The government is also leveraging the latest use of digital wallets such as e-Rupi for offline payments to make the ecosystem inclusive.</p>
                                <p>Contactless payments are the go-to option for people with smartphones, the consumer base of which is increasing rapidly. Even the feature phones are equipped with payment options with UPI Lite, while the smartphones and internet services are unabashedly getting cheaper by the day.</p>

                                <h3>Summing up</h3>
                                <p>India’s retail market is the fourth-largest in the world, and the fact that it is decentralized translates to a lot of potential for the adoption of technology in the sector. The benefits of a local retail outlet need to be reaped by modernizing their operations to make their business model profitable. Hyperlocal deliveries are only possible by the small grocery stores that make up 50% of the retail sector in India. From receiving the order to the final delivery, the growth story of Indian retail is being written by the fintech solutions custom-made for the kiranas.</p>

                            </div>
                        </div>
                    </div>                      
                </Container>}
                {page === "The-growth-of-indian-retail" && <Container>
                    <div className={blogStyle.blog_detail_inner}>
                        <h2 className={blogStyle.secTitle + " secTitle"} style={{fontFamily:"Poppins-Regular", fontWeight: "bold"}}>
                          The growth of indian retail
                        </h2>   
                        <div className={blogStyle.img + " text-center"}>                      
                            <img className="w-100 img-fluid" src={blog3} alt="Blog" /> 
                        </div>
                        <div className={blogStyle.content_inner}>
                            <div className={blogStyle.left}>
                                <div className={blogStyle.date_month}>
                                    <div className={blogStyle.date}>26</div>
                                    <div className={blogStyle.month}>DEC 22</div>
                                </div>
                                <ul className={blogStyle.social}>
                                    <li>
                                        <a href="https://www.facebook.com/PayTailIndia/" target={'_blank'}><FaFacebookF/></a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/PaytailIndia" target={'_blank'}><FaTwitter/></a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/paytail/" target={'_blank'}><FaLinkedin/></a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/paytailindia/" target={'_blank'}><FaInstagram/></a>
                                    </li>
                                    {/* <li>
                                        <a href=""><FaShareAlt/></a>
                                    </li> */}
                                </ul>
                            </div>
                            <div className={blogStyle.content}>
                                <p>"If you search for incredible growth stories, the story of India’s retail comes at the top.”</p>
                                <p>Indian retail has completely transformed itself and experienced rapid development in the last decade. According to a report by BCG, the sector is crossing the trillion- dollar mark. This growth is driven by certain social, economic, and technological factors that fuel consumers' consumption.</p>
                                <p>A good {'>85%'} of the Indian retail market happens at the small offline mom and pop stores where their products range from clothes, groceries to electronics, FMCG, Pharma, and almost everything. However, in a market where there is a rapid technology adoption from tech savvy consumers looking for speed and convenience, the Ecomm space is up to play an increasingly important role. The good old small Mom & Pop stores, which owns the neighbourhoods are facing the heat from the rapid shift in consumer choices. Large players like Amazon and Flipkart have changed the shape of this space by introducing discount tools that did not exist before. The affordable EMI options, coupon codes and finance options through your credit score have accelerated your purchasing power as a consumer. The consumer is naturally inclined to these options due to the convenience they offer.</p>
                                
                                <h3>OFFLINE RETAIL MARKET IN INDIA</h3>
                                <p>It is observed that the Indian offline retail market slipped down by 5% in the previous fiscal year and reached around $800 billion worth. While e-commerce in India saw a growth of 25% and is anticipated to increase at a similar pace for the next 5 years. This shows how offline retail was left behind in the race of retail industry in India and needed a good strategy that could be well used far in the future as well.</p>
                                <p>For smaller stores, the access to technology as well as its implementation cost is some of the obstacles that come in the way of growth in the times of instant digital finance. But there are clear key advantages in offline stores which maintains their relevance like familiarity, proximity and the in-store experience they offer. It will not be an exaggeration to state that in approximately every single lane in India, one can find at least one such store. For these stores to continue to maintain a dominant position, it has become extremely essential to have access to the same latest technology at speed and affordability using easy EMI solutions. With the use of such technology, they could definitely stay in competition with their online counterparts and would not feel the burnout affecting them due to the technological barrier.</p>
                                
                                
                                <h3>FINTECH COMPANIES AND THEIR RELEVANCE IN INDIA</h3>
                                <p>The entry of fintech and payment companies offering varied digital lending services in the space has put the focus back on the potential of Mom & Pop stores. The pandemic brought light to many inspiring stories about companies inclining to new growth opportunities through digital channels. Amongst them, the strongest narrative to come out is the way Mom and Pop stores adopted digital – from 
                                    receiving orders to ordering fulfillment on digital applications. A lot of fintech companies are trying to disrupt the space and be partners of these merchants in building out the entire India growth story.</p>
                                <p>One such company that has etched its name in the Mom and Pop market is PAYTAIL</p>
                                <p>Paytail is a startup and one of the top fintech companies in India that have effectively tapped into the previously untapped potential of offline retailers by amplifying their selling capabilities. It has been a widely known fact that the availability of affordability tools like EMIs, helps consumers to accelerate their buying decision. The merchants get directly benefitted in form of increased sales and profits. Paytail transforms the entire selling experience of the merchant and buying experience of the consumers by giving them Digital, Paperless EMI App-based solutions.. The merchants get empowered to offer easy EMI options to all walk-in customers with a simple 30-second registration process and can sell products with ticket sizes as low as Rs. 3000 and up to Rs. 2 lacs with instant ease.</p>
                                
                                <h3>FINTECH AND OFFLINE STORES</h3>
                                <p>Considering how the pandemic forced retail companies to adopt digital forms of payments, it may have been an astonishing change for many small retailers. But it is observed that with the growth of fintech services in the Indian retail industry supporting businesses in accepting online payments has laid a smooth path altogether. The future of the bond between fintech and offline stores is a hopeful one with an aim to create a system of effortless transactions and high satisfaction on both ends.The vision of the fintech company Paytail, is to empower millions of these small offline retailers across the country and create a level playing field with larger companies. There is a strong belief that these merchants are going to lead India to the success story of what we as a nation wish to achieve</p>
                            </div>
                        </div>
                    </div>                      
                </Container>}
                {page === "Unorganised-Retail:-Tapping-the-Untapped-Giant" && <Container>
                    <div className={blogStyle.blog_detail_inner}>
                        <h2 className={blogStyle.secTitle + " secTitle"} style={{fontFamily:"Poppins-Regular", fontWeight: "bold"}}>
                            Unorganised Retail: Tapping the Untapped Giant
                        </h2>   
                        <div className={blogStyle.img + " text-center"}>                      
                            <img className="w-75 img-fluid" src={blog4} alt="Blog" /> 
                        </div>
                        <div className={blogStyle.content_inner}>
                            <div className={blogStyle.left}>
                                <div className={blogStyle.date_month}>
                                    <div className={blogStyle.date}>26</div>
                                    <div className={blogStyle.month}>DEC 22</div>
                                </div>
                                <ul className={blogStyle.social}>
                                    <li>
                                        <a href="https://www.facebook.com/PayTailIndia/" target={'_blank'}><FaFacebookF/></a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/PaytailIndia" target={'_blank'}><FaTwitter/></a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/paytail/" target={'_blank'}><FaLinkedin/></a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/paytailindia/" target={'_blank'}><FaInstagram/></a>
                                    </li>
                                    {/* <li>
                                        <a href=""><FaShareAlt/></a>
                                    </li> */}
                                </ul>
                            </div>
                            <div className={blogStyle.content}>
                                <p>With the advent of technology, the retail sector has witnessed a 360-degree transformation in the past decade. The pandemic has further proven to be a catalyst in the acceptance of digitization of payments in the offline retail sector.</p>
                                <p>The Indian unorganized retail sector accounts to about 90% of the total market and consist of conventional family-run neighbourhood shops, and mom and pop retailers.</p>
                                <p>With a market opportunity as big as 1.7 trillion dollars till 2026, (ref: article by Investindia.gov.in), the entire entrepreneur and investor ecosystem is venturing exponentially to further achieve hypergrowth in this segment. There are also some clear key advantages in offline stores which maintain their relevance like familiarity, proximity and the in- store experience they offer. It will not be an exaggeration to state that approximately every single lane in India, one can find at least one such store.</p>
                                <p>It has become increasingly essential to leverage tech solutions that can rapidly mobilise millions of unorganized retail stores to unlock incremental value in offline retail.</p>
                                <p>We have officially entered the world of e-commerce, where every retailer needs to put customer convenience first, and second. With the plethora of options available to the “digital” consumer, it has become a necessity for the physical offline stores to adapt to this change. A balanced approach is the need of the hour, where a combination of execution and technology can potentially double up the growth rate.</p>
                                <p>The Indian credit market has also registered an upward trend in credit performance, with a 47% increase in lending from private institutions, and a year-on-year improvement in balance-level delinquencies suggesting that consumers are able to service their borrowing commitments. Credit penetration has experienced a growth from 18% in March 2020 to 21% in March 2022 as a result of sustained growth in consumer demand and easy access to product oriented and consumer durable loans, fueling financial inclusion across metros, urban, semi-urban and rural areas experiencing increased participation. (ref. TransUnion CIBIL Credit Market Indicator, March 2022)</p>
                                <p>To streamline the same, fintechs have come into action and taken a centre stage. The entire idea is cantered around bringing speed and convenience that stretches a helping hand in not only increasing the sales but also organizing the entire business operations from payments to inventory. The competition from E-comms can only be met with the technology adoption in this layer. These could be in the form of ease of payment through various wallets and discount offers, time-bound deliveries, and product variety with a detailed description.</p>
                                <p>Key winners of this adoption have been payment companies where contactless payments are becoming the go to option for every consumer as well as merchant.. As per the RBI data, the non-cash volume of transactions skyrocketed to a record 98.5% in 2020-21. Given the systemic dependence on this layer of fintechs, it is important that the incumbents discover profitable models to keep scaling with the growing economy and add greater value.</p>
                                <p>To further the growth and convenience flywheel, now it’s time and need for digital affordability platforms to bring the low-cost EMI options at scale for the offline retailers and consumers which can accelerate the next wave of growth.</p>
                                <p>To sum it up, businesses who can build profitable models to organize millions of offline retailers which will further create new markets and revenue streams, are the present and the future of the Indian economy. One of the biggest consumer markets in the world, India, will see a skyrocketing rise when access to technology becomes easier. It is safe to say that the path of growth is just “a few clicks away”!</p>
                            </div>
                        </div>
                    </div>                      
                </Container>}
                {page === "Why-Indias-digital-payment-ecosystem-is-a-revolution" && <Container>
                    <div className={blogStyle.blog_detail_inner}>
                        <h2 className={blogStyle.secTitle + " secTitle"} style={{fontFamily:"Poppins-Regular", fontWeight: "bold"}}>
                           Why India's digital payment ecosystem is a revolution
                        </h2>   
                        <div className={blogStyle.img + " text-center"}>                      
                            <img className="w-100 img-fluid" src={blog6} alt="Blog" /> 
                        </div>
                        <div className={blogStyle.content_inner}>
                            <div className={blogStyle.left}>
                                <div className={blogStyle.date_month}>
                                    <div className={blogStyle.date}>26</div>
                                    <div className={blogStyle.month}>DEC 22</div>
                                </div>
                                <ul className={blogStyle.social}>
                                    <li>
                                        <a href="https://www.facebook.com/PayTailIndia/" target={'_blank'}><FaFacebookF/></a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/PaytailIndia" target={'_blank'}><FaTwitter/></a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/paytail/" target={'_blank'}><FaLinkedin/></a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/paytailindia/" target={'_blank'}><FaInstagram/></a>
                                    </li>
                                    {/* <li>
                                        <a href=""><FaShareAlt/></a>
                                    </li> */}
                                </ul>
                            </div>
                            <div className={blogStyle.content}>
                                <p>For an Indian resident in the year 2022, it has become extremely common to commence the day with a QR code scan and end it on the same. From your groceries to your travel, digital payments have entered your lives in full force. This is when you realise you are a part of the digital revolution, digital India.</p>
                                <p>According to a report by ACI Worldwide, the real-time transactions in India has skyrocketed to a whopping 48 billion, making it the global leader in this ecosystem.</p>
                                <p>But if we get to wish to get to the core of this number, what are the reasons that are accelerating this number?</p>

                                <h3>Internet penetration</h3>
                                <p>The effective combination of easy access to inexpensive internet as well as the penetration of smartphones have played a major role in facilitating this revolution. The digital revolution has supplemented increases in the application of financial services across the world, which gives the consumer to have better control over their finances in terms of borrowing, lending, tracking and saving.</p>
                                
                                <h3>Acceptance of UPI</h3>
                                <p>UPI transactions have almost equalled to the credit card and debit card transactions. Stating some numbers in reference to the CLSA report, UPI transactions at $187 billion are close to surpass $83 billion debit card transactions and 102$ billion credit card transactions. UPI has changed the entire scenario of payment ecosystem, where people can transfer the smallest amounts in fraction of seconds without any hassle.</p>

                                <h3>The wave of fintechs</h3>
                                <p>As digital payments have impacted the payment ecosystem by a storm, the growth of digital lending stays parallel to the same.</p>
                                <p>The norms on interest rates and liquidity regulations stretch a helping hand for fintech in serving its customers with ease.</p>
                                <p>A segregation made by the CLSA report is a testimony to the fact that digital payments are the future of the Indian economy. According to the report, close to 44 per cent of the payments will be accepted through payment gateways and aggregators, a rough 34 per cent through QR codes and the remaining percent through POS machines.</p>
                                <p>All these factors have led India to the number 1 spot in the whole world in terms of digital payments. The subcontinent has registered over 26 billion transactions in 2020-21, 60 % more than the market giant, China.</p>
                                <p>India is evolving at a speed which has never been witnessed before. A variety of factors have supplemented the growth of the digital wave. One of the major factors, is the growth and prominence of fintechs. The shape and form of loan disbursements have changed completely.</p>
                                <p>India's digital payment revolution is an inspiration and case study for the entire world to witness. Customer convenience has taken a centre stage in the entire payment ecosystem and every player is pushing themselves to deliver the same.</p>
                                <p>So, if you search for growth stories of digital payments, the story of India comes at the top.</p>
                                <p>Fintechs are becoming one of the major forces that are paving the way for financial inclusion in India. Strong ties with banks, financial partners and insurance players have transformed the entire process of loan procurement and disbursement.</p>
                                <p>Fintechs credit-based products and other EMI related products are acting as a perfect replacement for all real time transactions in the ecosystem. By skillfully analyzing their customers credit history and transaction data to provide loans on an approach governed by API’s, they offer a lot of speed and convenience</p>


                                <h3>Consumers/Merchants say YES to digitization</h3>
                                <p>The new to credit and subprime population has accepted this arrangement with open arms, as they are constantly looking for credit opportunities in the most hassle-free way.</p>
                                <p>The growth of digital lending is further accelerated by its acceptance by MSME’s, who were not able to secure loans from traditional banking systems. Time saving onboarding journey combined with digital KYCs have given these fintechs an exponential boost.</p>

                                <h3>Role of the government</h3>
                                <p>Whenever the chapter of “digital revolution’ opens in the Indian context, the role of the Government of India also needs to be significantly highlighted.</p>
                                <p>With its Digital India programme, the Government of India has accorded highest priority to the acceptance of digital payment ecosystem, where each and every segment of the country can benefit from the convenience of digital payments.</p>

                                
                            </div>
                        </div>
                    </div>                      
                </Container>}
                {page === "Your-credit-score-and-what-it-does-for-you" && <Container>
                    <div className={blogStyle.blog_detail_inner}>
                        <h2 className={blogStyle.secTitle + " secTitle"} style={{fontFamily:"Poppins-Regular", fontWeight: "bold"}}>
                            YOUR CREDIT SCORE AND WHAT IT DOES FOR YOU?
                        </h2>   
                        <div className={blogStyle.img + " text-center"}>                      
                            <img className="w-75 img-fluid" src={blog5} alt="Blog" /> 
                        </div>
                        <div className={blogStyle.content_inner}>
                            <div className={blogStyle.left}>
                                <div className={blogStyle.date_month}>
                                    <div className={blogStyle.date}>26</div>
                                    <div className={blogStyle.month}>DEC 22</div>
                                </div>
                                <ul className={blogStyle.social}>
                                    <li>
                                        <a href="https://www.facebook.com/PayTailIndia/" target={'_blank'}><FaFacebookF/></a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/PaytailIndia" target={'_blank'}><FaTwitter/></a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/paytail/" target={'_blank'}><FaLinkedin/></a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/paytailindia/" target={'_blank'}><FaInstagram/></a>
                                    </li>
                                    {/* <li>
                                        <a href=""><FaShareAlt/></a>
                                    </li> */}
                                </ul>
                            </div>
                            <div className={blogStyle.content}>
                                <p>“Your credit is not approved.” A notification that does not bring a smile on your face and probably no one else would want to experience that too.</p>
                                <p>But have you ever wondered? What is one of the major obstacles that comes in your way of attaining your desired credit? The simple 2 word phenomenon “CREDIT SCORE”. These simple 2 words have a major impact on your aspirations of buying your favourite product.</p>
                                <p>Starting with the basics, what is credit score? Your credit score is a three-digit number that reflects your creditworthiness and financial behaviour, it is calculated after an analysis of your credit history. To be precise, Credit score can be termed as mathematical expression of your financial behaviour, a metric on the basis of which a digital lending provider offers you credit.</p>
                                <p>The credit score in India ranges from 300 to 900. With a higher credit score you have a better chance of availing instant easy loan and vice versa.</p>
                                <p>Generally, a credit score of more than 700 is considered ideal and ease outs the process of obtaining credit.</p>
                                
                                <h3>Below are some advantages of maintaining a good credit score:</h3>

                                <h3>GET YOUR LOAN APPROVED WITH GOOD CREDIT IN NO TIME</h3>
                                <p>The role of credit scores has become even more important as banks use it to determine if you are a disciplined borrower and equally responsible when it comes to borrowing as well as repaying. Getting loan approved with good credit is always better than an individual getting it for lesser credit score than you.</p>
                                
                                <h3>GET APPROVED FOR HIGHER LIMITS</h3>
                                <p>Your borrowing capacity is based on two factors -your income and your credit score.</p>
                                <p>Banks are willing to let you borrow more money because you have proved them that you pay back what you borrow on time.</p>

                                <h3>GET LOWER INTEREST RATES</h3>
                                <p>High credit scores stretch a helping hand in negotiating for availing loans with low interest rates. With the variety of options available from several banks, you always have the upper hand with a good credit score.</p>
                                <p>It can be understood that with good credit score you are able to contribute significantly to the online as well as offline retail with various convenient EMI options at your discretion.</p>
                                
                                <p><h3>FACT </h3>- A good credit score is something to be proud of. Keep working towards a great credit score because the benefits are immense. But one might wonder about the effective ways and techniques that can bring him to that decent credit score where everything seems beautiful and easy.</p>

                                <h3>Paytail a fintech startup would love to share some amazing tips to maintain a healthy credit score:</h3>
                                <ul>
                                    <li style={{paddingBottom:"8px"}}>1. Pay all your bills or EMIs effectively on time.</li>
                                    <li style={{paddingBottom:"8px"}}>2. Do not default on loans and credit cards.</li>
                                    <li style={{paddingBottom:"8px"}}>3. Always maintain a low credit utilisation ratio.</li>
                                    <li style={{paddingBottom:"8px"}}>4. Don’t shop around for credit unnecessarily, as it impacts your score.</li>
                                    <li style={{paddingBottom:"8px"}}>5. Maintain a healthy relationship with the banks and other financial institutions.</li>
                                    <li style={{paddingBottom:"8px"}}>6. Always keep a check on your credit report as well as credit score.</li>
                                </ul>
                                <br/>
                                <p>So, do you think do you have a decent credit score? Well, Paytail is here to give you its full advantages. An entire paperless process for quick digital loan combined with a super easy application effectively ease outs the path between you and your desired credit. An individual with such an opportunity and technology in hand can easily complete his/her purchasing experience without much hassle involved.</p>
                                <p>Paytail is on a mission to bridge the gap between your aspirations and you by bring you your favourite products in the easiest and the most convenient way possible.</p>
                            </div>
                        </div>
                    </div>                      
                </Container>}

            </div>
            <Footer />
        </div>
    );
};
export default Blog_Detail;
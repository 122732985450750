import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonStyle from '../Style/Button.module.css'
import ShopImg from '../../assets/images/shomImg.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import ShopEmiVideo from '../../assets/videos/shop-emi-video.mp4'
import ClientSlider from './ClientSlider';
import Header from '../common/Header';
import TextTransition, { presets, slow } from "react-text-transition";
import Lottie from 'react-lottie';
import PhoneVideo from '../../assets/jsonFile/Phone-Animation'

const TextSlide = [
    "quickest",
    "simplest",
    "paperless",
];

const Index = () => {
    const [textIndex, setTextIndex] = React.useState(0);


    const bannerVideo = {
        loop: true,
        autoplay: true,
        animationData: PhoneVideo,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    React.useEffect(() => {
        const intervalId = setInterval(() =>
            setTextIndex(textIndex => textIndex + 1),
            3000 // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);

    useEffect(() => {
        AOS.init();
    }, [])

    return (
        <section className='indexBanner secFlex' id="bannerSec">
            <Header />
            <Container>
                <Row>
                    <Col className='flexCenter'>
                        <div className='paytailBannerContent'>
                            <h1 data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-sine" data-aos-delay="200">
                                <span>The <span className='redColor'><TextTransition springConfig={presets.slow}>{TextSlide[textIndex % TextSlide.length]}</TextTransition></span></span>
                                <span>way to shop on</span>
                                <span> No Cost EMIs. </span>
                            </h1>
                            <p data-aos="fade-up" data-aos-easing="linear" data-aos-duration="`1000" data-aos-delay="300">Available across 60,000+ offline stores in India, trusted by 1 million+ Indians  </p>
                            <ul>
                                <li data-aos="fade-right" data-aos-duration="1000" data-aos-easing="ease-in-sine" data-aos-delay="400" ><a href='https://paytail-web.s3.ap-south-1.amazonaws.com/Paytail.apk' target={'_blank'} className={ButtonStyle.whiteBtn}>
                                    Download Now
                                    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.2678 7.16935C14.2138 7.08628 14.1233 7.03458 14.0247 7.02926C13.9534 7.0254 13.8818 7.02119 13.8101 7.01696C13.5614 7.00231 13.3109 6.98756 13.0608 6.98729C9.39983 6.98352 5.73881 6.98137 2.07778 6.98768C1.73483 6.98828 1.38952 6.9849 1.12632 6.87233C0.996586 6.81685 0.888613 6.73542 0.811126 6.61545C0.733422 6.49515 0.683786 6.33208 0.677812 6.10921C0.67121 5.86323 0.71421 5.68305 0.789294 5.5502C0.864031 5.41796 0.97293 5.32839 1.10605 5.26729C1.37656 5.14313 1.74049 5.13867 2.10396 5.13909C5.0716 5.14247 8.03921 5.14205 11.1439 5.1416L11.4437 5.14156C12.6347 5.14139 13.2736 3.71398 12.4522 2.84667C12.4431 2.83707 12.434 2.82748 12.4249 2.8179C12.1789 2.55832 11.9339 2.29985 11.8236 2.02259C11.7673 1.88091 11.7471 1.73628 11.7802 1.58524C11.8133 1.43378 11.9008 1.27111 12.068 1.09557C12.2238 0.932007 12.3682 0.847463 12.5022 0.816C12.6355 0.784683 12.764 0.804618 12.8909 0.860442C13.1492 0.974058 13.391 1.23092 13.6316 1.48666C13.8737 1.74405 14.1154 2.00213 14.3571 2.26027C15.24 3.20317 16.1236 4.14688 17.0339 5.06083C17.3778 5.40614 17.5568 5.72162 17.57 6.03286C17.5832 6.34286 17.4323 6.66342 17.0814 7.02056L17.1193 7.0578L17.0814 7.02056C16.1803 7.93775 15.2961 8.87368 14.4123 9.80917C14.1125 10.1265 13.8128 10.4437 13.5125 10.7602C13.2777 11.0076 13.0362 11.2181 12.789 11.2919C12.6672 11.3282 12.5446 11.3312 12.4196 11.2888C12.294 11.2463 12.1622 11.1565 12.025 11.001C11.7623 10.7031 11.7201 10.4258 11.7956 10.1669C11.8727 9.9027 12.0746 9.65077 12.3161 9.41389C12.7937 8.9455 13.2736 8.47982 13.7536 8.01401C13.9105 7.86179 14.0674 7.70956 14.2242 7.55721C14.3295 7.45491 14.3477 7.29244 14.2678 7.16935Z" stroke="white" stroke-width="0.10625" /></svg>
                                </a></li>
                                {/* <li  data-aos="fade-right" data-aos-duration="1000" data-aos-easing="ease-in-sine" data-aos-delay="400" ><a href='https://play.google.com/store/apps/details?id=com.paytail.consumers' target={'_blank'} className={ButtonStyle.whiteBtn}>
                                    Download Now
                                    <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.2678 7.16935C14.2138 7.08628 14.1233 7.03458 14.0247 7.02926C13.9534 7.0254 13.8818 7.02119 13.8101 7.01696C13.5614 7.00231 13.3109 6.98756 13.0608 6.98729C9.39983 6.98352 5.73881 6.98137 2.07778 6.98768C1.73483 6.98828 1.38952 6.9849 1.12632 6.87233C0.996586 6.81685 0.888613 6.73542 0.811126 6.61545C0.733422 6.49515 0.683786 6.33208 0.677812 6.10921C0.67121 5.86323 0.71421 5.68305 0.789294 5.5502C0.864031 5.41796 0.97293 5.32839 1.10605 5.26729C1.37656 5.14313 1.74049 5.13867 2.10396 5.13909C5.0716 5.14247 8.03921 5.14205 11.1439 5.1416L11.4437 5.14156C12.6347 5.14139 13.2736 3.71398 12.4522 2.84667C12.4431 2.83707 12.434 2.82748 12.4249 2.8179C12.1789 2.55832 11.9339 2.29985 11.8236 2.02259C11.7673 1.88091 11.7471 1.73628 11.7802 1.58524C11.8133 1.43378 11.9008 1.27111 12.068 1.09557C12.2238 0.932007 12.3682 0.847463 12.5022 0.816C12.6355 0.784683 12.764 0.804618 12.8909 0.860442C13.1492 0.974058 13.391 1.23092 13.6316 1.48666C13.8737 1.74405 14.1154 2.00213 14.3571 2.26027C15.24 3.20317 16.1236 4.14688 17.0339 5.06083C17.3778 5.40614 17.5568 5.72162 17.57 6.03286C17.5832 6.34286 17.4323 6.66342 17.0814 7.02056L17.1193 7.0578L17.0814 7.02056C16.1803 7.93775 15.2961 8.87368 14.4123 9.80917C14.1125 10.1265 13.8128 10.4437 13.5125 10.7602C13.2777 11.0076 13.0362 11.2181 12.789 11.2919C12.6672 11.3282 12.5446 11.3312 12.4196 11.2888C12.294 11.2463 12.1622 11.1565 12.025 11.001C11.7623 10.7031 11.7201 10.4258 11.7956 10.1669C11.8727 9.9027 12.0746 9.65077 12.3161 9.41389C12.7937 8.9455 13.2736 8.47982 13.7536 8.01401C13.9105 7.86179 14.0674 7.70956 14.2242 7.55721C14.3295 7.45491 14.3477 7.29244 14.2678 7.16935Z" stroke="white" stroke-width="0.10625"/></svg>
                                </a></li> */}
                                <li data-aos="fade-right" data-aos-duration="1000" data-aos-easing="ease-in-sine" data-aos-delay="500"><a href='https://www.youtube.com/watch?v=2f5NjYs1sgk&feature=youtu.be' target={'_blank'} className={ButtonStyle.whiteBtn}>
                                    Watch Tutorial
                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.0384 22.7782C18.4903 22.7578 22.8661 18.3362 22.8253 12.8894C22.7845 7.47322 18.3271 3.07194 12.9007 3.09234C7.58147 3.11274 3.14959 7.61092 3.16999 12.971C3.18529 18.4331 7.58147 22.7986 13.0384 22.7782ZM12.9466 3.72984C18.0313 3.71454 22.1929 7.84552 22.2235 12.9353C22.249 17.9639 18.1333 22.1408 13.1047 22.1867C8.00987 22.2326 3.81259 18.1169 3.77689 13.0373C3.74119 7.95262 7.88747 3.74514 12.9466 3.72984Z" /><path d="M16.1855 12.4043L11.9355 9.74805V15.0605L16.1855 12.4043Z" fill="white" /></svg>
                                </a></li>
                            </ul>
                        </div>
                    </Col>
                    <Col className='bannerVideo'>
                        {/* <img  src={ShopImg} className='responsiveImg' /> */}
                        <video data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-sine" data-aos-delay="200" src={ShopEmiVideo} loop muted autoPlay className='paytailVide'></video>
                        <div className='lotiMobile' data-aos="fade-up" data-aos-duration="1000" data-aos-easing="ease-in-sine" data-aos-delay="100">
                            <Lottie
                                options={bannerVideo}
                                height="100%"
                                width="100%"
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
            <ClientSlider />
        </section>
    )
}

export default Index;
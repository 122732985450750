import React, { useEffect } from "react";
import careerStyle from "../Style/career.module.css";
import ButtonStyle from '../Style/Button.module.css'
import Header from '../common/Header';
import Footer from '../common/Footer';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import AOS from 'aos';
import 'aos/dist/aos.css';
import careers_banner from '../../assets/images/careers-banner.jpg';
import welcome_career from '../../assets/images/welcome-career.png';
import quality1 from '../../assets/images/quality1.png';
import quality2 from '../../assets/images/quality2.png';
import quality3 from '../../assets/images/quality3.png';
import quality4 from '../../assets/images/quality4.png';
import quality5 from '../../assets/images/quality5.png';
import gallery1 from '../../assets/images/gallery1.png';
import gallery2 from '../../assets/images/gallery2.jpg';
import gallery3 from '../../assets/images/gallery3.jpg';
import gallery4 from '../../assets/images/gallery4.jpg';
import gallery6 from '../../assets/images/gallery6.jpg';
import gallery7 from '../../assets/images/gallery7.png';
import gallery8 from '../../assets/images/gallery8.png';
import gallery9 from '../../assets/images/gallery9.jpg';
import { FaSearch } from 'react-icons/fa';
import { useState } from "react";
import upload_resume from '../../assets/images/upload_resume.svg';
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set } from "firebase/database";
import { getStorage, ref as refs, uploadBytes } from "firebase/storage";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

 
var firebaseConfig = {
    // Firebase credentials
    apiKey: "AIzaSyDlfqGfxv44849bJMMuYUbsEaKXofZTmG0",
    authDomain: "paytailwebview.firebaseapp.com",
    databaseURL: "https://paytailwebview-default-rtdb.firebaseio.com",
    projectId: "paytailwebview",
    storageBucket: "paytailwebview.appspot.com",
    messagingSenderId: "494975397592",
    appId: "1:494975397592:web:3db4a1431781c269ce31ad",
    measurementId: "G-2HNTPXMJ6K"
  };
   
  
  const candidateDetails = {
      position: "",
      firstName: "",
      lastName: "",
      emailAddress: "",
      expectedCTC: "",
      currentCTC: "",
      telephoneNo: "",
      uploadResume: ""
    }
    
    const Careers = () => {
        const [candidateDetail, setCandidateDetail] = useState(candidateDetails);
        const [open, setOpen] = useState(false)
        const [error, setError] = useState(true)
        const [fileName, setFileName] = useState('');
        
        const handleSetDetail = (res) => {
            if(error){
                setError(false)
            }
            let name = res.target.name;
            setCandidateDetail({...candidateDetail, [name]: res.target.value})
        }
        useEffect(() => {
            AOS.init();
        }, [])
        const openIncurrentTab = url => {
            window.open(url, '_top', 'noopener,noreferrer');
        };
        const [show, setShow] = useState(false);
        
        const applynowClose = () => setShow(false);
        const applynow = () => setShow(true);
        
        const handleSubmit = (res) => {
            sub();
        }
        
        const app = initializeApp(firebaseConfig);
        const db = getDatabase(app);
        const sub = () => {
            if(candidateDetail.firstName == "" || candidateDetail.lastName == "") {
                setError(true)
                return false
            }
            let candidateId = Math.floor(Math.random() * 10000);
            set(ref(db, 'candidate/' + candidateId+"-"+candidateDetail.position), {
                ...candidateDetail,
            }).then(res=> setOpen(true))
            setShow(false)
        }

    const storage = getStorage(app);
    
    // Upload the file and metadata
    const uploadTask = (event) => {
        let candidateId = Math.floor(Math.random() * 10000);
        setFileName(event.target.files[0].name)
        const storageRef = refs(storage, `documents/${candidateId+"-"+event.target.files[0].name}`);
        uploadBytes(storageRef, event.target.files[0]).then((snapshot) => {
            setCandidateDetail({...candidateDetail, uploadResume: snapshot.metadata.name})
        });;
    }


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      };

  return (
        <div className="main">
            <Header />
                <div className={careerStyle.career_main + ' career_main'}>
                    <div className={careerStyle.inner_banner}>
                        <img className="w-100" src={careers_banner} alt="Career" /> 
                        <h2>Careers @ Paytail</h2>
                    </div>
                    <div className={careerStyle.welcome} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="400">
                        <Container>
                            <h2 className={careerStyle.secTitle + ' secTitle'}>Welcome to Paytail, where employees come first, and second</h2>
                            <div className={careerStyle.welcome_img + ' text-center'}>
                                <img className="img-fluid" src={welcome_career} alt="Career" /> 
                            </div>
                        </Container>
                    </div>
                    <div className={careerStyle.quality}>
                        <Container>
                            <h2 className={careerStyle.secTitle + ' secTitle'}>Building a high-quality product takes a lot of work, but it also takes the right work- life balance</h2>
                            <p className={careerStyle.desc}>We value the uniqueness in each one of you. Some of the qualities that make you a perfect fit are:</p>
                            
                            <Row className={careerStyle.list + " justify-content-center"} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="400">
                                <Col md="4" sm="6">
                                    <div className={careerStyle.quality_item}>
                                        <div className={careerStyle.img}>
                                            <img className="img-fluid" src={quality1} alt="Team Player" /> 
                                        </div>
                                        <h4>Team Player</h4>
                                        <p>You believe that the power of 2&gt;1</p>
                                    </div>
                                </Col>
                                <Col md="4" sm="6">
                                    <div className={careerStyle.quality_item} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="500">
                                        <div className={careerStyle.img}>
                                            <img className="img-fluid" src={quality2} alt="Curiosity" /> 
                                        </div>
                                        <h4>Curiosity</h4>
                                        <p>You are concerned about the how's and why’s</p>
                                    </div>
                                </Col>
                                <Col md="4" sm="6">
                                    <div className={careerStyle.quality_item} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="600">
                                        <div className={careerStyle.img}>
                                            <img className="img-fluid" src={quality3} alt="Ownership" /> 
                                        </div>
                                        <h4>Ownership</h4>
                                        <p>You take charge of your work and rise with flying colours!</p>
                                    </div>
                                </Col>
                                <Col md="4" sm="6">
                                    <div className={careerStyle.quality_item} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="400">
                                        <div className={careerStyle.img}>
                                            <img className="img-fluid" src={quality4} alt="Solution Oriented" /> 
                                        </div>
                                        <h4>Solution Oriented</h4>
                                        <p>You do not tend to panic; an emergency is an opportunity for you to come out with a solution</p>
                                    </div>
                                </Col>
                                <Col md="4" sm="6">
                                    <div className={careerStyle.quality_item} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="500">
                                        <div className={careerStyle.img}>
                                            <img className="img-fluid" src={quality5} alt="Endurance" /> 
                                        </div>
                                        <h4>Endurance</h4>
                                        <p>You know slowly and steadily; we are going to make it!</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <div className={careerStyle.fortheculture}>
                        <Container>
                            <h2 className={careerStyle.secTitle + ' secTitle'}>We are all <br />#fortheculture</h2>
                            
                            <Row className={careerStyle.gallery + " justify-content-center"} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="400">
                                <Col className={careerStyle.text_end+ " text-end"} md="3" xs="12">
                                    <img className="img-fluid" src={gallery1} alt="gallery" /> 
                                    <img className="img-fluid" src={gallery2} alt="gallery" /> 
                                </Col>
                                <Col className={careerStyle.text_start + " text-start p-0"} md="6"  xs="12" data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="600">                                    
                                    <Row className="justify-content-center align-items-end">
                                        <Col className="text-start" md="7" xs="12">
                                            <img className="img-fluid" src={gallery9} alt="gallery" />
                                        </Col>
                                        <Col className="text-start" md="5" xs="12">
                                            <img className="img-fluid" src={gallery6} alt="gallery" /> 
                                        </Col>
                                    </Row>                                   
                                    <Row className="justify-content-center">
                                        <Col className={careerStyle.text_start + " text-start"} md="5" xs="12">
                                            <img className="img-fluid" src={gallery7} alt="gallery" /> 
                                        </Col>
                                        <Col className={careerStyle.text_start + " text-start"} md="7" xs="12">
                                            <img className="img-fluid" src={gallery8} alt="gallery" />  
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="text-center" md="3" xs="12" data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="500">
                                    <img className="img-fluid" src={gallery3} alt="gallery" /> 
                                    <img className="img-fluid" src={gallery4} alt="gallery" /> 
                                </Col>
                            </Row>
                        </Container>
                    </div>                    
                    <div className={careerStyle.howitworks}>
                        <Container>
                            <h2 className={careerStyle.secTitle + ' secTitle'}>So, why Paytail as your next <br /> LinkedIn job update?</h2>
                            <div className={careerStyle.howitworks_content}>
                                <div className={careerStyle.left_side} data-aos="fade-right" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="400">
                                    <div className={careerStyle.common_box + ' ' + careerStyle.left_active}>
			                            <div className={careerStyle.step_number}>1</div>
                                        <p>We believe in clear vision and set paths, where we communicate clearly regarding what we want to achieve rather than draining ourselves in goal less work.</p>
                                    </div>
                                </div>
                                <div className={careerStyle.right_side} data-aos="fade-left" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="500">
                                    <div className={careerStyle.common_box + ' ' + careerStyle.right_active}>
			                            <div className={careerStyle.step_number}>2</div>
                                        <p>We tend to create a healthy work environment and culture which fosters your growth.</p>
                                    </div>
                                </div>
                                <div className={careerStyle.left_side} data-aos="fade-right" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="600">
                                    <div className={careerStyle.common_box + ' ' + careerStyle.left_active}>
			                            <div className={careerStyle.step_number}>3</div>
                                        <p>We are beyond time. We don’t believe in monitoring your clock ins and outs. It’s the work you put on the table that matters.</p>
                                    </div>
                                </div>
                                <div className={careerStyle.right_side} data-aos="fade-left" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="700">
                                    <div className={careerStyle.common_box + ' ' + careerStyle.right_active}>
			                            <div className={careerStyle.step_number}>4</div>
                                        <p>We know health is wealth, and we value your health the most. At Paytail, we provide you with a full health insurance coverage*</p>
                                    </div>
                                </div>	
                                <div className={careerStyle.left_side} data-aos="fade-right" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="800">
                                    <div className={careerStyle.common_box + ' ' + careerStyle.left_active}>
			                            <div className={careerStyle.step_number}>5</div>
                                        <p>We believe in a culture of rewards, be it a shoutout on Slack or rewarding our people with a non-linear career growth trajectory</p>
                                    </div>
                                </div>
                                <div className={careerStyle.right_side} data-aos="fade-left" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="900">
                                    <div className={careerStyle.common_box + ' ' + careerStyle.right_active}>
			                            <div className={careerStyle.step_number}>6</div>
                                        <p>We believe in creative collaboration in the office environment; however, we are super flexible when it comes to working from your choice of place when you do not feel a 100 %.</p>
                                    </div>
                                </div>	
                            </div>
                        </Container>
                    </div>
                    
                    <div className={careerStyle.vacancies + ' vacancies'}>
                        <Container>
                            <h2 className={careerStyle.secTitle + ' secTitle'}>Be a part of the rocketship!</h2>
                            <form className="search_field">
                                <div className="input-group">
                                    <div className="search">
                                        <input type="text" className="form-control" placeholder="Check out our opportunities" /> 
                                        <a href=""><FaSearch /></a>
                                    </div>
                                    <button type="button" className="btn">Let`s GO</button>
                                </div>
                            </form>
                            <Row className={careerStyle.list + " justify-content-center"}>
                                <Col md="6" sm="6">
                                    <div className={careerStyle.item} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="500">
                                        <a onClick={() => openIncurrentTab('/job-descriptions/Lead-Data-Scientist')} className={careerStyle.title}>Lead Data Scientist</a>
                                        <p>We are looking to hire a dynamic and passionate Lead Data Scientist for our Data Science engineering team who is hands-on, energetic, ambitious, go-getter, loves to explore new technologies and can match our fast-paced growth.</p>
                                        <div className={careerStyle.action}>
                                            <button onClick={() => openIncurrentTab('/job-descriptions/Lead-Data-Scientist')} className={ButtonStyle.whiteBtn + ' ' + careerStyle.grayBtn}>Know More </button>
                                            <button onClick={(res)=>{
                                                setCandidateDetail({...candidateDetail, position: "Lead Data Scientist"})
                                                applynow()
                                            }} className={ButtonStyle.whiteBtn + ' ' + careerStyle.whiteBtn}>Apply now</button>
                                        </div>
                                    </div>
                                </Col>   
                                <Col md="6" sm="6">
                                    <div className={careerStyle.item} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="600">
                                        <a onClick={() => openIncurrentTab('/job-descriptions/Product-Manager')} className={careerStyle.title}>Product Manager</a>
                                        <p>We are looking to hire a dynamic and passionate Product Manager who is energetic, ambitious and can match our fast-paced growth.</p>
                                        <div className={careerStyle.action}>
                                            <button onClick={() => openIncurrentTab('/job-descriptions/Product-Manager')} className={ButtonStyle.whiteBtn + ' ' + careerStyle.grayBtn}>Know More </button>
                                            <button onClick={(res)=>{
                                                setCandidateDetail({...candidateDetail, position: "Product Manager"})
                                                applynow()
                                            }} className={ButtonStyle.whiteBtn + ' ' + careerStyle.whiteBtn}>Apply now</button>
                                        </div>
                                    </div>
                                </Col>  
                                <Col md="6" sm="6">
                                    <div className={careerStyle.item} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="500">
                                        <a  onClick={() => openIncurrentTab('/job-descriptions/Senior-QA-engineer')} className={careerStyle.title}>Senior QA engineer</a>
                                        <p>We are looking to hire a dynamic and passionate Senior QA Engineer for our QA team who is energetic, ambitious, go-getter, loves to explore new technologies and can match our fast-paced growth.</p>
                                        <div className={careerStyle.action}>
                                            <button onClick={() => openIncurrentTab('/job-descriptions/Senior-QA-engineer')} className={ButtonStyle.whiteBtn + ' ' + careerStyle.grayBtn}>Know More </button>
                                            <button onClick={(res)=>{
                                                setCandidateDetail({...candidateDetail, position: "Senior QA engineer"})
                                                applynow()
                                            }} className={ButtonStyle.whiteBtn + ' ' + careerStyle.whiteBtn}>Apply now</button>
                                        </div>
                                    </div>
                                </Col>  
                                <Col md="6" sm="6">
                                    <div className={careerStyle.item} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="600">
                                        <a onClick={() => openIncurrentTab('/job-descriptions/Manager-Data-Science')} className={careerStyle.title}>Manager Data Science</a>
                                        <p>We are looking to hire a dynamic and passionate Manager - Data Science for our Data Science engineering team who is hands-on, energetic, ambitious, go-getter, loves to explore new technologies and can match our fast-paced growth. PFB the Job details and if you find it suitable for your passion and next career move, please revert with your updated resume.</p>
                                        <div className={careerStyle.action}>
                                            <button onClick={() => openIncurrentTab('/job-descriptions/Manager-Data-Science')} className={ButtonStyle.whiteBtn + ' ' + careerStyle.grayBtn}>Know More </button>
                                            <button onClick={(res)=>{
                                                setCandidateDetail({...candidateDetail, position: "Manager- Data Science"})
                                                applynow()
                                            }} className={ButtonStyle.whiteBtn + ' ' + careerStyle.whiteBtn}>Apply now</button>
                                        </div>
                                    </div>
                                </Col>  
                                <Col md="6" sm="6">
                                    <div className={careerStyle.item} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="600">
                                        <a onClick={() => openIncurrentTab('/job-descriptions/Credit-manager')} className={careerStyle.title}>Credit manager</a>
                                        <p>As a Credit Manager/ Credit Analyst in Credit & Risk team at Paytail, you will apply your Underwritingand analytical skills to major company challenges. You’ll team with best in industry. 
 professionals to develop and test risk strategies that ultimately impact the bottom line (revenue growth and risk management) and you will do it all in a collaborative environmentthat values your insight, encourages you to take on new responsibilities, promote continuous learning, and reward innovation.</p>
                                        <div className={careerStyle.action}>
                                            <button onClick={() => openIncurrentTab('/job-descriptions/Credit-manager')} className={ButtonStyle.whiteBtn + ' ' + careerStyle.grayBtn}>Know More </button>
                                            <button onClick={(res)=>{
                                                setCandidateDetail({...candidateDetail, position: "Credit manager"})
                                                applynow()
                                            }} className={ButtonStyle.whiteBtn + ' ' + careerStyle.whiteBtn}>Apply now</button>
                                        </div>
                                    </div>
                                </Col>  
                                <Col md="6" sm="6">
                                    <div className={careerStyle.item} data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="500">
                                        <a  onClick={() => openIncurrentTab('/job-descriptions/Area-Sales-Manager')} className={careerStyle.title}>Area Sales Manager  </a>
                                        <p>Recruiting and Retaining high performing FOS team to drive merchant acquisition, engagement and retention goals. Setting sales targets for individual reps and your team as a whole. Should be able to device the best methods for communication of plans/targets to the team so that there is minimum expectation vs delivery gap.</p>
                                        <div className={careerStyle.action}>
                                            <button onClick={() => openIncurrentTab('/job-descriptions/Area-Sales-Manager')} className={ButtonStyle.whiteBtn + ' ' + careerStyle.grayBtn}>Know More </button>
                                            <button onClick={(res)=>{
                                                setCandidateDetail({...candidateDetail, position: "Area Sales Manager"})
                                                applynow()
                                            }} className={ButtonStyle.whiteBtn + ' ' + careerStyle.whiteBtn}>Apply now</button>
                                        </div>
                                    </div>
                                </Col> 
                            </Row>
                        </Container>
                    </div>
                 </div>
            <Footer />
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered className="modal-theme" show={show} onHide={applynowClose}>
                <Modal.Header closeButton>
                <Modal.Title className="secTitle">Apply Job</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row>
                            <Col md="6"  xs="12">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>First Name<sup>*</sup></Form.Label>
                                    <Form.Control name="firstName" type="text" onChange={handleSetDetail}/>
                                </Form.Group>
                            </Col>
                            <Col md="6"  xs="12">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Last Name<sup>*</sup></Form.Label>
                                    <Form.Control name="lastName" type="text" onChange={handleSetDetail} />
                                </Form.Group>
                            </Col>
                            <Col md="6"  xs="12">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control name="emailAddress" type="email" onChange={handleSetDetail}/>
                                </Form.Group>
                            </Col>
                            <Col md="6"  xs="12">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Telephone No</Form.Label>
                                    <Form.Control name= "telephoneNo" type="text" onChange={handleSetDetail}/>
                                </Form.Group>
                            </Col>
                            <Col md="6"  xs="12">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Current CTC</Form.Label>
                                    <Form.Control name="currentCTC" type="text" onChange={handleSetDetail} />
                                </Form.Group>
                            </Col>
                            <Col md="6"  xs="12">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Expected CTC</Form.Label>
                                    <Form.Control name="expectedCTC" type="text" onChange={handleSetDetail}/>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <Button className="upload-resume">
                        <img className="img-fluid" src={upload_resume} alt="Upload Resume" /> Upload Resume
                            <Form.Control type="file" accept="application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation" onChange={uploadTask} />
                        </Button>
                        <Button className="btn-theme" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',}} open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert variant="filled" severity="success" onClose={handleClose}>Successfully Submitted!</Alert>
            </Snackbar>
        </div>
    );
};
export default Careers;
import React from "react";
import ReactDOM from 'react-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const ClientSlider = () =>{
    

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 5
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 4
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 3
        }
      };


    return(
        <div className="clientSlider"  autoPlay autoPlaySpeed={3000} renderArrowsWhenDisabled={false} infinite>
            <Container>
                <Row>
                    <Col>
                    <Carousel
  additionalTransfrom={0}
  arrows
  autoPlay
  autoPlaySpeed={3000}
  centerMode={false}
  className=""
  containerClass="container-with-dots"
  customTransition="all 1s linear"
  dotListClass=""
  draggable
  focusOnSelect={false}
  infinite
  itemClass=""
  minimumTouchDrag={80}
  pauseOnHover
  renderArrowsWhenDisabled={false}
  renderButtonGroupOutside={false}
  renderDotsOutside={false}
  responsive={{
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024
      },
      items: 5,
      partialVisibilityGutter: 40
    },
    mobile: {
      breakpoint: {
        max: 767,
        min: 0
      },
      items: 3,
      partialVisibilityGutter: 30
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 768
      },
      items: 4,
      partialVisibilityGutter: 30
    }
  }}
  rewind={false}
  rewindWithAnimation={false}
  rtl={false}
  shouldResetAutoplay
  showDots={false}
  sliderClass=""
  swipeable
  transitionDuration={0}
>
                            <div><img src={require("./../../assets/images/clientLogo01.png")} /></div>
                            <div><img src={require("./../../assets/images/clientLogo02.png")} /></div>
                            <div><img src={require("./../../assets/images/clientLogo03.png")} /></div>
                            <div><img src={require("./../../assets/images/clientLogo04.png")} /></div>
                            <div><img src={require("./../../assets/images/clientLogo05.png")} /></div>
                            <div><img src={require("./../../assets/images/clientLogo06.png")} /></div>
                            <div><img src={require("./../../assets/images/clientLogo07.png")} /></div>
                            <div><img src={require("./../../assets/images/clientLogo08.png")} /></div>
                            <div><img src={require("./../../assets/images/clientLogo09.png")} /></div>
                            <div><img src={require("./../../assets/images/clientLogo10.png")} /></div>
                            <div><img src={require("./../../assets/images/clientLogo11.png")} /></div>
                            <div><img src={require("./../../assets/images/clientLogo12.png")} /></div>
                            <div><img src={require("./../../assets/images/clientLogo13.png")} /></div>
                            <div><img src={require("./../../assets/images/clientLogo14.jpg")} /></div>
                            <div><img src={require("./../../assets/images/clientLogo15.png")} /></div>
                            <div><img src={require("./../../assets/images/clientLogo16.png")} /></div>
                            <div><img src={require("./../../assets/images/clientLogo17.png")} /></div>
                            <div><img src={require("./../../assets/images/clientLogo18.png")} /></div>
                            <div><img src={require("./../../assets/images/clientLogo19.png")} /></div>
                            <div><img src={require("./../../assets/images/clientLogo20.png")} /></div>
                            <div><img src={require("./../../assets/images/clientLogo21.png")} /></div>
                            <div><img src={require("./../../assets/images/clientLogo23.png")} /></div>
                            <div><img src={require("./../../assets/images/clientLogo24.png")} /></div>
                            <div><img src={require("./../../assets/images/clientLogo25.png")} /></div>
                        </Carousel>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ClientSlider;
import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ShopImg from '../../assets/images/shomImg.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import ShopEmiVideo from '../../assets/videos/paytail.m4v'

const WaitList =()=>{
    useEffect(() => {
        AOS.init();
      }, [])
    return(
        <section className="dealSec sectionPadding fullHeight">
            <Container>
                <Row>
                    <Col>
                        <div className="listContainer">
                            <h2 className="secTitle">
                                <span data-aos="fade-up" data-aos-duration="300" data-aos-easing="ease-in-sine" data-aos-delay="100">Are you on</span>
                                <span data-aos="fade-up" data-aos-duration="300" data-aos-easing="ease-in-sine" data-aos-delay="120">the wait list?</span> 
                            </h2>
                            <div className="listBox topBox">
                                <div className="listImg" data-aos="fade-right" data-aos-duration="400" data-aos-easing="ease-in-sine" data-aos-delay="200">
                                    <img src={require('../../assets/images/EMI-img.png')} />
                                </div>
                                <div className="listDetail" data-aos="fade-left" data-aos-duration="400" data-aos-easing="ease-in-sine" data-aos-delay="250">
                                    <p>Pay with your credit/debit card and convert your purchase into EMI’s </p>
                                </div>
                            </div>
                            <div className="listBox bottomBox">
                                <div className="listDetail cardBox" data-aos="fade-right" data-aos-duration="400" data-aos-easing="ease-in-sine" data-aos-delay="200">
                                    <p>Paytail works across major debit/credit cards and pre-approved offers*.  </p>
                                </div>
                                <div className="listImg"  data-aos="fade-left" data-aos-duration="400" data-aos-easing="ease-in-sine" data-aos-delay="250">
                                    <img src={require('../../assets/images/card-img.png')} />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default WaitList;
import React from "react";
import policyStyle from "../Style/policyStyle.module.css";
  
const TermsOfUse = () => {
  return (
<div className="policy_content">
        <h2 className={policyStyle.header}>
            TERMS OF USE- PAYTAIL APPLICATION
        </h2>
        <p>
            <span className={policyStyle.subheading}>Introduction</span> <br />
            This document is an electronic record in terms of Information Technology Act, 2000 and rules there under as
            applicable. This electronic record is generated by a computer system and does not require any physical or
            digital signatures.  <br />
            This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology
            (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy
            and terms of use for access or usage of this mobile application ("App").  <br />
            The Terms and Conditions (T&amp;C) contained herein along with the Privacy Policy, Customer Credit Terms of Use
            &amp; Cancellation &amp; Refund Policies constitutes a legally binding Agreement between the User &amp; Paytail India
            Private Limited's. “PAYTAIL” web application is owned and controlled by Paytail India Private Limited. User
            is hereinafter referred to as “Paytail User”/ “You”/ “Your”. <br />
            These terms of use ("Terms"), including the various policies incorporated by reference in these Terms,
            govern your access to and use of this App, its website and the underlying services provided by us through
            the App, including our SMS, APIs, email notifications, applications, buttons, widgets, ads, commerce
            services, and our other covered services that may link to these Terms (collectively, the "Services"), and
            any information, text, graphics, photos or other materials uploaded, downloaded or appearing on or as a part
            of the Services (collectively referred to as "Content"). Your access to and use of the Services, however
            accessed, constitutes your agreement to be bound by these Terms, which establishes a contractual
            relationship between You and PAYTAIL. When You use any of the Services provided by Us through the
            App/website, you will be subject to the rules, guidelines, policies, terms, and conditions applicable to
            such Service, and they shall be deemed to be incorporated into and shall apply in addition to these Terms.
            If you transact on the App or our website, you shall be subject to the policies that are applicable to the
            App/Website for such transactions.  <br />
            By downloading or using the app, these terms will automatically apply to you - you should make sure
            therefore that you read them carefully before using the app. If you do not agree to these App Terms, you
            must immediately uninstall the App and discontinue its use. You shall be a person of legal age and not
            barred to execute an Agreement as per the laws of India at the time of sign-up. If you have any questions
            regarding our App, you can email us at support@paytail.com. <br /><br />
            <span className={policyStyle.subheading}>Changes</span> <br />
            Paytail India Private Limited, reserves the right to suspend / cancel, or discontinue any or all channels,
            products or service at any time without notice, make modifications and/or alterations in any or all of the
            content, products and services contained on the App without prior notice.  <br /><br />
            <span className={policyStyle.subheading}>Definitions</span> <br />

            <span className={policyStyle.subheading}>"Agreement"</span> means the Terms and Conditions (T&amp;C)
            contained herein along with the Privacy Policy and Terms of Use including other T&amp;C at other App/Website of
            Paytail India Private Limited under the name. It will also include references to this Agreement as and when
            amended, notated, supplemented, varied or replaced. <br />
            <span className={policyStyle.subheading}>"Mobile App" </span> or "PAYTAIL" includes all online
            platform(s) (including PAYTAIL Mobile Application/s and Browser Extensions) owned and/or operated by Paytail
            India Private Limited providing users with the facility to find and use Value back offers available at
            Offline outlets &amp; online marketplace. <br />
            <span className={policyStyle.subheading}>"Vendor" / "merchants" / "affiliates"</span> shall mean the
            person or any legal entity who offers for sale, sells, displays, the deals/ offers at their retail outlet or
            on the online platform provided by their respective websites and delivers the ordered products to the
            customers.  <br />
            <span className={policyStyle.subheading}>"Customer" / "Buyer"</span> shall mean the person or any legal
            entity who accepts the offer for sale on goods/services by visiting the merchant outlets or by placing an
            order for and or purchases any deal offered for sale on affiliates. <br />
            <span className={policyStyle.subheading}>"User/You/ Your"</span> means and includes you and/or any
            person or an entity including Vendor/Merchant using or accessing the services provided on this App and any
            person who access or avail this App of the Company for the purpose of hosting, publishing, sharing,
            transacting, displaying or uploading information or views and includes other persons jointly participating
            in using the App. The term  <span>"We", "Us", "Our"</span> shall mean the
            Company.  <br /><br />
            <span className={policyStyle.subheading}>The Services </span><br />
            This Mobile App is only a platform for interaction and facilitation of legally recognised transactions
            between the merchant and potential customer. PAYTAIL acts merely as a facilitator for such sale and all
            commercial / contractual terms are offered independently by the merchants/ affiliates and agreed to by
            user/buyers independently and PAYTAIL assumes no liability qua the deficiency in services, potency of goods
            and services etc arising from or incidental to such transactions.. The commercial / contractual terms
            include without limitation price, shipping costs, date, period, mode of delivery, warranties related to
            products and services including after sales services related to products and services, etc., over which the
            merchant/seller has complete control and the same may be subject to change and PAYTAIL has no control over
            it. <br />
            PAYTAIL reserves the right to reduce or remove the available amount without any prior notice or suspend or
            block or delete the account of the User without prior notice at its sole discretion. We may also add an
            expiry date to the earned valueback vouchers/cashback and bonus, if any, without any prior notice, and
            reduce or remove the available vouchers/points if you do not use it before the expiry date. <br />
            You further agree and acknowledge that the App is not involved in any sale and purchase of
            goods/products/services and cannot warranty the quality and merchantability of the goods purchased utilising
            the deals/ coupons/vouchers purchased. PAYTAIL is only a facilitator and intermediary and cannot be
            construed a party to or control in any manner any transactions between the Vendor/Seller and the
            user/Customer/Buyer. PAYTAIL shall neither be responsible nor liable to mediate or resolve any disputes or
            disagreements between the Users and the Vendors.  <br />
            PAYTAIL shall neither be liable nor responsible for any actions or inactions of Vendors and/or
            merchants/Sellers or any breach of conditions, representations or warranties by the merchant or manufacturer
            of the products. PAYTAIL does not sell or retail any products and does not ensure that the Users shall
            perform their obligations in respect of the transaction concluded on this App and further expressly
            disclaims any warranties or representations express or implied in respect of quality, safety,
            merchantability, fitness for a particular purpose, or legality of the products listed and transacted on
            App.  <br />
            You further agree and undertake that you are accessing the Paytail Services available on this App and
            transacting at your sole risk and are using your best and prudent judgment before entering into any
            transaction through this App.  <br />
            PAYTAIL accepts no liability for any errors or omissions, whether on behalf of itself or third parties.
            <br />
            PAYTAIL shall not be responsible for any non-performance or breach of any contract entered into between
            Users.  <br />
            Before placing an order you are advised to check the product/ services description carefully. By placing an
            order for a Deal you agree to be bound by the conditions of sale included in the Deal's description.  <br />
            The agreement between Paytail User and PAYTAIL is subject to the following terms and conditions:  <br />
            </p><ul class="bullet-points">
                <li>
                    The Paytail User certifies that he/she is at least 18 (eighteen) years of age and not barred under
                    law to execute any agreement.
                </li>
                <li>These terms and conditions supersede all previous representations, understandings, or agreements and
                    shall prevail notwithstanding any variance with any other terms of any order submitted or your login
                    through other group or related ID. By using the services of PAYTAIL you agree to be bound by the
                    Terms and Conditions.  </li>
                <li>All prices, unless indicated otherwise are in Indian Rupees.  </li>
                <li>By indicating Paytail User's acceptance to purchase any product or service offered on the App,
                    Paytail User is obligated to complete such transactions after making payment. Paytail User is
                    prohibited from indicating its acceptance to purchase products and services where it does not intend
                    to complete such transactions. </li>
                <li>PAYTAIL is neither responsible for any non-performance or breach of any contract entered into
                    between Paytail User and Merchant/ Vendor nor make any representation or Warranty as to the
                    attributes (such as quality, worth, marketability, etc.) of the products or services proposed to be
                    sold or to be redeemed or offered to be sold by the seller or purchased by the buyer, on the App.
                </li>
                <li>You are advised to independently verify the bona fides of any particular Deal/ Vouchers that you
                    choose to buy with on the App and use your best judgment in that behalf. PAYTAIL accepts no
                    liability for any errors or omissions, whether on behalf of itself or its channel partners/
                    Sellers/merchants or third parties.  </li>
                <li>In a credit card transaction, you must use your own credit card. PAYTAIL will not be liable for any
                    credit card fraud. The liability to use a card fraudulently will be on the Paytail User and the onus
                    to 'prove otherwise' shall be exclusively on the Paytail User. To provide a safe and secure shopping
                    experience, we regularly monitor transactions for fraudulent activity. In the event of detecting any
                    suspicious activity, PAYTAIL reserves the right to cancel all past, pending and future orders
                    without any liability. </li>
                <li>The Paytail User agrees to use the services provided by PAYTAIL, its affiliates, consultants and
                    contracted companies, for lawful purposes only.  </li>
                <li>The User agrees not to make any bulk purchase, whether from offline merchant outlets or online
                    merchant platforms through PAYTAIL, to indulge into any reseller activities. In case of any such
                    instances, PAYTAIL reserves all rights to cancel the current and future orders and block the
                    concerned Paytail User account. </li>
                <li>The Paytail User agrees to provide authentic and true information. PAYTAIL reserves the right to
                    confirm and validate the information and other details provided by the Paytail User at any point of
                    time. Paytail User shall produce any document or undertaking, which we may require for this
                    validation. The Paytail User is responsible for the accuracy of their information and PAYTAIL is not
                    responsible for any losses, which may arise due to this information being inaccurate or incomplete.
                    If upon confirmation such Paytail User details are found to be false, not to be true (wholly or
                    partly), PAYTAIL shall in its sole discretion reject the registration and debar the Paytail User
                    from using the Paytail Services available on its App, and / or other affiliated websites without
                    prior intimation whatsoever.  </li>
                <li>
                    PAYTAIL will not be responsible for any damage suffered by Paytail Users from use of the services on
                    this APP. This without limitation includes loss of revenue/data resulting from delays,
                    non-deliveries, missed deliveries, or service interruptions as may occur because of any act /
                    omission of parties. This disclaimer of liability also applies to any damages or injury caused by
                    any failure of performance, error, omission, interruption, deletion, defect, delay in operation or
                    transmission, computer virus, communication line failure, theft or destruction or unauthorized
                    access to, alteration of, or use of record, whether for breach of contract, tortuous behavior,
                    negligence, or under any other cause of action.
                </li>
                <li>Cancellation on Paytail User's request, may not be allowed, subject to the specific terms and
                    conditions applicable to the type of product or service purchased. </li>
                <li>All order/sub orders cancelled will be refunded as per the payment mode selected by customer. </li>
                <li>PAYTAIL as a merchant shall be under no liability whatsoever in respect of any loss or damage
                    arising directly or indirectly out of the decline of authorization for any Transaction, on Account
                    of the Cardholder having exceeded the pre-set limit mutually agreed by us with our acquiring bank
                    from time to time. </li>
                <li>PAYTAIL reserves the right to send transactional emails and SMS's related to any order placed by a
                    Paytail User and the Paytail User agrees that the same will not tantamount to spam. By submitting
                    your mobile number, you agree to receive calls/SMS's from PAYTAIL or its authorized representative,
                    irrespective of your registration on Do Not Disturb (DND) with your telecom service provider. </li>
                <li>You agree to receive calls from PAYTAIL and its agents, merchants, courier partners seeking
                    clarification/information regarding your order and any other related information as and when
                    required and disclaim any liability of PAYTAIL in this regard. </li>
            </ul>
            <span className={policyStyle.subheading}>Use Of Contents </span><br />
            The contents of the PAYTAIL Software / application / website can be viewed and used on your mobile for
            making purchases of various types of goods and services through various mediums designated by PAYTAIL and,
            configure to the Contents that PAYTAIL publishes or broadcasts via its Software. You also grant Us the right
            to sub-license these rights, and the right to bring an action for infringement of these rights. We reserve
            the right to edit or remove any material submitted to this App, or stored on Our servers, or hosted or
            published upon this App <br />
            Notwithstanding anything contained here, you agree and acknowledge that PAYTAIL may have procured the
            Contents or part thereof under valid licenses, and then in case the delivery of the Contents and/or the
            Software or part thereof is stopped due to the termination of the such license agreements with the third
            party, then PAYTAIL shall stop the use of such Contents or part thereof and you shall not raise any
            objection to the same and claim any loss from PAYTAIL thereupon. <br />
            We have the right to upload content on behalf of third parties, subsequent to collecting such information
            and verifying it if we deem it necessary. Notwithstanding this, We can in no way be held liable for any
            false or misleading information. <br />
            Paytail Users of our App may post reviews, comments and other content; as long as the content is not
            illegal, obscene, abusive, threatening, defamatory, invasive of privacy, infringing of intellectual property
            rights, or otherwise injurious to third parties, or objectionable and does not consist of or contain
            software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form
            of "spam". In case a Paytail User uses a false e-mail address, impersonates any person or entity, or
            otherwise misleads as to the origin of any content, PAYTAIL has the right to remove, refuse, delete or edit
            any content that violates these Conditions of use and, or terminate your permission to access or use our
            website. <br /><br />
            <span className={policyStyle.subheading}>Links</span><br />
            The Services may contain links to third-party websites or resources. You acknowledge and agree that PAYTAIL
            is not responsible or liable for: (i) the availability or accuracy of such websites or resources; or (ii)
            the content, products, or services on or available from such websites or resources. Links to such websites
            or resources do not imply any endorsement by PAYTAIL of such websites or resources or the content, products,
            or services available from such websites or resources. You acknowledge sole responsibility for and assume
            all risk arising from your use of any such websites or resources. <br /><br />
            <span className={policyStyle.subheading}>Compliance with Laws: </span><br />
            As required by applicable law, if the Paytail User makes a purchase of an amount equal to or above INR
            200,000/- (Two Lacs), the Paytail User will be required to upload a scanned copy of his/her PAN card on the
            Platform, within 48 hours of making the purchase for activating the deal/ purchase, failing which, the
            purchase made by the Paytail User will be cancelled without any responsibility on part of PAYTAIL. The
            requirement to submit the PAN card arises only once and if the Paytail User has submitted it previously, it
            need not be submitted again. The order of the Paytail User shall stand cancelled if there is a discrepancy
            between the name of the Paytail User and the name on the PAN Card. In case of cancellation in such cases,
            the amount shall be refunded to the Paytail User’s credit card/ bank account/ UPI account etc. as per the
            refund policy of card issuer bank and Paytail assumes no responsibility in this regard. <br />
            Paytail User/Buyer shall comply with all the applicable laws (including without limitation Foreign Exchange
            Management Act, 1999 and the rules made and notifications issued there under and the Exchange Control Manual
            as may be issued by Reserve Bank of India from time to time, Customs Act, Information and Technology Act,
            2000 as amended by the Information Technology (Amendment) Act 2008, Prevention of Money Laundering Act, 2002
            and the rules made there under, Foreign Contribution Regulation Act, 1976 and the rules made there under,
            Income Tax Act, 1961 and the rules made there under, Export Import Policy of government of India) applicable
            to them respectively for using Payment Facility. <br />
            Nothing on the App constitutes, or is meant to constitute, advice of any kind. All the Products sold or
            services purchased through the App are governed by different state laws and if the Seller or PAYTAIL is
            unable to deliver such Products due to implications of different state laws, the Seller will return or will
            give credit for the amount (if any) received in advance by Seller from the sale of such Product that could
            not be delivered to you. There shall be no compensation, whether by way of credit or refund, in respect of
            Samples as all Samples are delivered free of cost. <br /><br />
            <span className={policyStyle.subheading}>Intellectual Property Rights </span><br />
            Unless otherwise indicated or anything contained to the contrary or any proprietary material owned by a
            third party and so expressly mentioned, the Services contains content to which PAYTAIL owns all intellectual
            property rights, including, without limitation, any and all rights, title and interest in and to copyright,
            related rights, patents, utility models, trademarks, trade names, service marks, designs, know-how, trade
            secrets and inventions (whether patentable or not), goodwill, source code, meta tags, databases, text,
            content, graphics, icons, and hyperlinks ("PAYTAIL Content"). You acknowledge and agree that you shall not
            use, copy, imitate, reproduce or distribute any PAYTAIL Content without obtaining authorisation from
            PAYTAIL. <br />
            Notwithstanding the foregoing, it is expressly clarified that you will retain ownership and shall solely be
            responsible for any Content that you provide or upload when using any Service, including any text, data,
            information, images, photographs, music, sound, video or any other material which you may upload, transmit
            or store when making use of our various Services. Instead, you hereby grant to PAYTAIL a nonexclusive, fully
            paid and royalty-free, transferable, sub-licensable, worldwide license to use the Content that you post on
            or through the Service, subject to PAYTAIL's Privacy Policy. <br />
            Some of the Service is supported by advertising revenue and may display advertisements and promotions, and
            you hereby agree that PAYTAIL may place such advertising and promotions on the Service or on, about, or in
            conjunction with your Content. The manner, mode and extent of such advertising and promotions are subject to
            change without specific notice to you. You acknowledge that we may not always identify paid services,
            sponsored content, or commercial communications as such.  <br /><br />
            <span className={policyStyle.subheading}>License</span><br />
            You acquire absolutely no rights or licenses in the Contents other than the limited right to access on your
            mobile/computer. It is clarified that you cannot download any Contents from the App/Website and you can only
            live stream the same. PAYTAIL is also not responsible for slow internet connection you use to access the
            Service or disruption in the internet services leading to rejection of orders and/or deduction of payments,
            a bad quality of the equipment you use to access the Service. <br /><br />
            <span className={policyStyle.subheading}>Limitation Of Liability </span><br />
            Under no circumstances, shall PAYTAIL, its suppliers, merchants, agents, directors, officers, employees,
            representatives, successors, or assigns be liable to any user on account of such user's use, misuse or
            reliance on the platforms for any damages whatsoever, including direct, special, punitive, indirect,
            consequential or incidental damages or damages for loss of profits, revenue, use, or data whether brought in
            warranty, contract, intellectual property infringement, tort (including negligence) or other theory, even if
            PAYTAIL is aware of or has been advised of the possibility of such damage, arising out of or connected with
            the use (or inability to use) or the materials or the internet generally, reliance upon or performance of
            any material contained in or accessed from any platforms. <br />
            PAYTAIL does not assume any legal liability or responsibility for the accuracy, completeness, or usefulness
            of any information, apparatus, product, service or process disclosed on the platforms or other material
            accessible from the platforms.  <br />
            The Paytail User assumes all responsibility and risk for the use of this platform and the internet
            generally. The foregoing limitations shall apply notwithstanding any failure of the essential purpose of any
            limited remedy and to the fullest extent permitted under applicable law. Some countries do not allow the
            exclusion or limitation of liability of consequential or incidental damages, so the above exclusions may not
            apply to all users; in such countries liability is limited to the fullest extent, as permitted by law.
            <br />
            THE PAYTAIL ENTITIES SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE
            DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA,
            USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (i) YOUR ACCESS TO OR USE OF OR INABILITY TO
            ACCESS OR USE THE SERVICES; (ii) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICES, INCLUDING
            WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF OTHER PAYTAIL USERS OR THIRD PARTIES;
            (iii) ANY CONTENT OBTAINED FROM THE SERVICES; OR (iv) UNAUTHORISED ACCESS, USE OR ALTERATION OF YOUR
            TRANSMISSIONS OR CONTENT. THE LIMITATIONS OF THIS SUBSECTION SHALL APPLY TO ANY THEORY OF LIABILITY, WHETHER
            BASED ON WARRANTY, CONTRACT, STATUTE, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, AND WHETHER OR NOT THE
            PAYTAIL ENTITIES HAVE BEEN INFORMED OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF A REMEDY SET FORTH
            HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.  <br />
            Notwithstanding anything to the contrary contained herein, the extent of liability under all circumstances
            shall not exceed the transaction or service fee received by PAYTAIL on the disputed transaction from the
            Paytail User/ Buyer or the Merchant/ vendor/ Affiliate etc., as the case may be. <br /><br />

            <span className={policyStyle.subheading}>Disclaimers And Limitations Of Liability</span><br />
            Please read this section carefully as it limits the liability of PAYTAIL and its parents, subsidiaries,
            affiliates, related companies, officers, directors, employees, agents, representatives, partners, and
            licensors (collectively, the "PAYTAIL Entities"). Nothing in this section is intended to limit any rights
            you may have which may not be lawfully limited. <br />
            Your access to and use of the Services or any Content are at your own risk. You understand and agree that
            the Services are provided to you on an "AS IS" and "AS AVAILABLE" basis without any representation or
            warranties, express or implied except otherwise specified in writing. Without limiting the foregoing, to the
            maximum extent permitted under applicable law, THE PAYTAIL AND ITS RELATED ENTITIES DISCLAIMS ALL WARRANTIES
            AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
            NON-INFRINGEMENT. <br />
            Without prejudice to the foregoing, the PAYTAIL Entities make no warranty or representation and disclaim all
            responsibility and liability for: (i) the completeness, accuracy, availability, timeliness, security or
            reliability of the Services or any Content; (ii) any harm to your computer system, loss of data, or other
            harm that results from your access to or use of the Services or any Content; (iii) the deletion of, or the
            failure to store or to transmit, any Content and other communications maintained by the Services; and (iv)
            whether the Services will meet your requirements or be available on an uninterrupted, secure, or error-free
            basis. No advice or information, whether oral or written, obtained from the PAYTAIL Entities or through the
            Services, will create any warranty or representation not expressly made herein. <br /><br />
            <span className={policyStyle.subheading}>Updates</span><br />
            The Paytail Users of the App are expected to update his/her said app on regular basis, so that he/she can
            avail the latest services and is duly aware of any change(s) in the PAYTAIL policy. By registering your
            phone number with us, Paytail User consent to be contacted by us via phone calls and/or SMS and/or Pop-up
            notifications, in case of any order or shipment or delivery related updates. In the event of failure to
            update the App by the Paytail Paytail User, the Users understands and agrees that PAYTAIL shall not be
            liable in any manner whatsoever.  <br /><br />
            <span className={policyStyle.subheading}>Refund &amp; Cancellation Policy  </span><br />
            <ol classname="tolist">
                <li>
                    Offline Vendors: In no event shall PAYTAIL be obligated to refund any amount paid by you under this
                    Agreement. The policy of refund shall entirely depend and governed by the Cancellation &amp; Refund
                    Policy posted on the Paytail App.
                </li>
                <li>
                    Online Vendor: In no event shall PAYTAIL be obligated to refund any amount paid by you under this
                    Agreement. The policy of refund shall entirely depend and governed by the Cancellation &amp; Refund
                    Policy posted on the Paytail App.
                </li>
            </ol>
            <br />
            <span className={policyStyle.subheading}>Paytail User Covenant </span><br />
            You need to purchase Services, daily routine goods/retail goods etc. from the registered Paytail Merchants
            only whether online or offline.  <br /><br />
            <span className={policyStyle.subheading}>Indemnification</span><br />
            You agree to indemnify, defend and hold harmless PAYTAIL, its associates and partners from and against any
            and all losses, liabilities, claims, damages, costs and expenses (including legal fees and disbursements in
            connection therewith and interest chargeable thereon) asserted against or incurred by PAYTAIL that arise out
            of, result from, or may be payable by virtue of, any breach or a law or any right of any user including but
            not limited to intellectual property rights of a third party or a user or non-performance of any
            representation, warranty, covenant or obligation to be performed by you pursuant to these Terms. <br />
            <br />
            <span className={policyStyle.subheading}>Payment</span><br />
            While availing any of the payment method/s available through the App, we will not be responsible or assume
            any liability, whatsoever in respect of any loss or damage arising directly or indirectly to you due to:
            <br />
            Lack of authorisation for any transaction(s), or Exceeding the pre-set limit mutually agreed by You and
            between "Bank(s)", or Any payment issues arising out of the transaction, or Decline of transaction for any
            other reason(s). <br />
            All payments made against the purchases on App by You shall be compulsorily in Indian Rupees acceptable in
            the Republic of India. App will not facilitate transaction with respect to any other form of currency with
            respect to the purchases made on App. <br />
            Before shipping/delivering your order to you, Seller may request you to provide supporting documents
            (including but not limited to Govt. issued ID and address proof) to establish the ownership of the payment
            instrument used by you for your purchase. This is done in the interest of providing a safe online shopping
            environment to our Paytail Users.  <br /><br />
            <span className={policyStyle.subheading}>Relationship</span><br />
            Transactions, transaction price and all commercial terms such as delivery, dispatch of Products are as per
            principal to principal bipartite contractual obligations between Buyer and Seller and payment facility is
            merely used by the Buyer and Seller to facilitate the completion of the transaction. Use of the payment
            facility shall not render PAYTAIL, its associates or partners liable or responsible for the non-delivery,
            non-receipt, non-payment, damage, breach of representations and warranties, non-provision of after sales or
            warranty services or fraud as regards the Products listed on and purchased by Buyers through the App. 
            <br />
            You have specifically authorised Paytail and its service providers to collect, process, facilitate and remit
            payments and/or the transaction price electronically to and from other users in respect of transactions
            through the relevant payment system ("Payment Facility"). Your relationship with Paytail is on a
            principal-to-principal basis and by accepting these Terms you agree that Paytail is an independent
            contractor for all purposes, and does not have control of or liability for the Products that are listed on
            App which are paid for by using the Payment Facility. Paytail does not guarantee the identity of any user
            nor does it ensure that a Buyer or a Seller will complete a transaction. <br />
            For the purposes of Credit/ Debit card/ Netbanking transactions, You understand, accept and agree that the
            Payment Facility provided by PAYTAIL is neither a banking nor financial service but is merely a facilitator
            providing an electronic, automated online channel of electronic payment, collection and remittance facility
            for the transactions through the App using the existing authorised banking infrastructure payment gateway
            networks. Further, by providing the Payment Facility, PAYTAIL, its associates and partners are neither
            acting as trustees nor acting in a fiduciary capacity with respect to the transaction or the transaction
            price. <br />
            For the purposes of purchase of product and services through the credit facility, the Paytail User will be
            governed by the terms and conditions of the Customer Credit &amp; Allied Terms and the same are incorporated
            herein by way of reference. <br /><br />
            <span className={policyStyle.subheading}>Prohibited Activities </span><br />
            You agree that you shall not host, display, upload, modify, publish, transmit, update or share any
            information on the App/Site, that: <br />
            <ul class="bullet-points">
                <li>belongs to another person and to which you do not have any right to;  </li>
                <li>is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic,
                    libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable,
                    disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any
                    manner whatever; </li>
                <li>
                    harm minors in any way;
                </li>
                <li>infringes any patent, trademark, copyright or other proprietary rights;  </li>
                <li>violates any law for the time being in force;  </li>
                <li>deceives or misleads the addressee about the origin of such messages or communicates any information
                    which is grossly offensive or menacing in nature;  </li>
                <li>impersonate another person;</li>
                <li>contains software viruses or any other computer code, files or programs designed to interrupt,
                    destroy or limit the functionality of any computer resource;  </li>
                <li>Abuse, harass, threaten, defame, disillusion, erode, abrogate, demean or otherwise violate the legal
                    rights of others;  </li>
                <li>Engage in any activity that interferes with or disrupts access to the Website or the Services (or
                    the servers and networks which are connected to the Website);  </li>
                <li>Impersonate any person or entity, or falsely state or otherwise misrepresent Your affiliation with a
                    person or entity; </li>
                <li>Publish, post, disseminate, any information which is grossly harmful, harassing, blasphemous,
                    defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful,
                    or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or
                    gambling, or otherwise unlawful in any manner whatever; or unlawfully threatening or unlawfully
                    harassing including but not limited to "indecent representation of women" within the meaning of the
                    Indecent Representation of Women (Prohibition) Act, 1986; </li>
                <li>Directly or indirectly, offer, attempt to offer, trade or attempt to trade in any item, the dealing
                    of which is prohibited or restricted in any manner under the provisions of any applicable law, rule,
                    regulation or guideline for the time being in force.  </li>
                <li>threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with
                    foreign states, or public order or causes incitement to the commission of any cognisable offence or
                    prevents investigation of any offence or is insulting any other nation.  </li>
            </ul>
            Any Content and or comment uploaded by you, shall be subject to relevant Indian laws and may be disabled, or
            and may be subject to investigation under appropriate laws. Furthermore, if you are found to be in
            non-compliance with the laws and regulations, these terms, or the privacy policy of the App/Site, the
            Company shall have the right to immediately terminate/block your access and usage of the App/Site and the
            Company shall have the right to immediately remove any noncompliant Content and or comment, uploaded by you
            and shall further have the right to take recourse to such remedies as would be available to the Company
            under the applicable laws.  <br /><br />
            <span className={policyStyle.subheading}>Merchant's Obligations </span><br />
            As a Merchant, whether offline or online, registered on the App, you are allowed to promote/list any
            Product(s)/Service(s) for sale through the App in accordance with the Policies which are incorporated by way
            of reference in these Terms. You must be legally able to sell the Product(s)/Service(s) you list for sale on
            our App. You must ensure that the listed Products/Services do not infringe upon the intellectual property,
            trade secret or other proprietary rights or publicity or privacy rights of third parties. Listings may only
            include text descriptions, graphics and pictures that describe your Product for sale. All listed Products
            must be listed in an appropriate category through the App. All listed Products must be kept in stock with
            the Merchant for successful fulfilment of online sales. <br />
            The listing description of the relevant item must not be misleading and must describe actual condition of
            the Product or Sample, as may be applicable. If the item description does not match the actual condition of
            the item, you agree to refund any amounts that you may have received from the Buyer. You agree not to list a
            single Product or Sample in multiple quantities across various categories on the App. PAYTAIL reserves the
            right to delete such multiple listings of the same Product or Sample listed by you in various categories.
            <br />
            In addition to these terms, Merchant Terms posted on Paytail App shall be binding on the Merchant, which are
            incorporated herein by way of reference. <br /><br />
            <span className={policyStyle.subheading}>Paytail User's Arrangement With Issuing Bank: </span><br />
            All valid credit /debit/cash card and other payment instruments are processed using a credit card payment
            gateway or appropriate payment system infrastructure and the same will also be governed by the terms and
            conditions agreed to between the Paytail User and the respective issuing bank and the payment instrument
            issuing company. <br />
            All online bank transfers from valid bank accounts are processed using the payment gateway provided by the
            respective issuing bank, which supports the Payment Facility to provide these services to the Paytail Users.
            All such online bank transfers on the Payment Facility are also governed by the terms and conditions agreed
            to between Paytail User and the respective Issuing Bank. <br />
            Paytail User who is availing the Paytail services of digital loan financing through Merchant Bank/s in
            accordance with the Customer Terms of the App, shall be entitled to make payment of the purchase price of
            the products, subject to monetary limited specified for Paytail User by the Partner Merchant’s bank account
            through Paytail, ('Transaction Amount'). Paytail and/or Partner Merchant’s bank reserves the right to amend,
            modify or restrict the monetary limit/ approve or reject funds in case of option as applicable to each
            Paytail User, at its sole discretion, for any reason whatsoever. <br /><br />
            <span className={policyStyle.subheading}>Jurisdictional Issues/Sale In India Only </span><br />
            Unless otherwise specified, the material through the Services is presented solely for the purpose of sale/
            ordering in India. PAYTAIL makes no representation that materials made available through the Services are
            appropriate or available for use in jurisdictions other than India. Those who choose to access this App from
            locations other than in India, do so on their own initiative and PAYTAIL is not responsible for the supply
            of Products or Samples/refund for the Products/ replacement of the Samples ordered from other
            locations/countries other than India, or compliance with local laws, if and to the extent local laws are
            applicable. <br /><br />
            <span className={policyStyle.subheading}>Trademark, Copyright And Restriction </span><br />
            The App services are controlled and operated by PAYTAIL, its partners and associates and Products/Services
            and Samples are offered for sale or ordering by the respective Merchants. All material on this App,
            including images, illustrations, audio clips, and video clips, may protected by copyrights, trademarks, and
            other intellectual property rights. <br />
            All content included on the App, such as text, graphics, logos, button icons, images, audio clips, digital
            downloads, data compilations, and software, is the property of PAYTAIL or its Merchants, unless owned by any
            third party and protected by applicable copyright laws. All other content not owned by PAYTAIL that appears
            on the App is the property of the respective owners. All software used on this App is the property of
            PAYTAIL and is protected by applicable copyright laws. Any unauthorised copying or reproduction of the above
            identified material is strictly prohibited by law. <br />
            The PAYTAIL logo and other marks indicated on the App are trademarks of Paytail India Private Limited. Other
            PAYTAIL graphics, logos, page headers, button icons, scripts, and service names are trademarks or trade
            dresses of PAYTAIL. Company's trademarks and trade dresses may not be used in connection with any product or
            service that is not Company's, in any manner that is likely to cause confusion among users, or in any manner
            that disparages or discredits PAYTAIL. All other trademarks not owned by Company that appear on this App are
            the property of their respective owners.  <br />
            Material on the App is solely for Your personal, non-commercial use. You must not copy, reproduce,
            republish, upload, post, transmit or distribute such material in any way, including by email or other
            electronic means and whether directly or indirectly and You must not assist any other person to do so.
            Without the prior written consent of the owner, modification of the materials, use of the materials on any
            other website or networked computer environment or use of the materials for any purpose other than personal,
            non-commercial use is a violation of the copyrights, trademarks and other proprietary rights, is strictly
            prohibited. Any use for which You receive any remuneration, whether in money or otherwise, is a commercial
            use for the purposes of this clause. <br /><br />
            <span className={policyStyle.subheading}>Trademark Complaint </span><br />
            PAYTAIL respects the intellectual property of others. In case you feel that your trademark has been
            infringed, you can write to PAYTAIL at support@paytail.com. <br /><br />
            <span className={policyStyle.subheading}>No Waiver </span><br />
            Unless otherwise expressly stated, failure to exercise or delay in exercising a right or remedy, hereunder
            shall not constitute a waiver of the right or remedy or a waiver of any other rights or remedies, and no
            single or partial exercise of any right or remedy, hereunder shall prevent any further exercise of the right
            or remedy or the exercise of any other right or remedy. <br /><br />
            <span className={policyStyle.subheading}>Force Majeure </span><br />
            Paytail shall not be responsible or liable for any delay or failure to perform its obligations (other than
            an obligation to make payment) under this Agreement due to unforeseen circumstances or any event which is
            beyond that Party's reasonable control and without its fault or negligence, but not limited to, acts of God,
            war, riots, embargoes, strikes, lockouts, notification of pandemic, acts of any Government authority, delays
            in obtaining licenses or rejection of applications under the Statutes, failure of telephone connections,
            server malfunction, malware/virus attacks on Paytail’s servers or power failure, fire or floods etc. <br />
            <br />
            <span className={policyStyle.subheading}>Non -exclusivity </span><br />
            It is agreed and clarified that the arrangement set out by these T&amp;C between Paytail and the Paytail Users
            is on non-exclusive basis. <br /> <br />
            <span className={policyStyle.subheading}>Non-Solicitation </span><br />
            The Paytail User warrants that it shall not directly or indirectly solicit for employment, nor offer
            employment to, nor enter into any contract of services with any person employed by Paytail. <br /> <br />
            <span className={policyStyle.subheading} governing="" law=""></span><br />
            These Terms shall be governed by and constructed in accordance with the laws of India. Any disputes arising
            in relation hereto shall be subject to the exclusive jurisdiction of the courts at Gurugram, Haryana. <br />
            Interpretation of Number and Genders and Capitalised Words The Terms herein shall apply equally to both the
            singular and plural form of the terms defined. Whenever the context may require, any pronoun shall include
            the corresponding masculine and feminine. The words "include", "includes" and "including" shall be deemed to
            be followed by the phrase "without limitation". Unless the context otherwise requires, the terms "herein",
            "hereof", "hereto", "hereunder" and words of similar import refer to the Terms as a whole. The capitalised
            words used anywhere in these Terms shall have the meaning as is readily inferable from the context or as
            they are commonly used and understood in their normal commercial sense.  <br /> <br />
            <span className={policyStyle.subheading}>Headings</span><br />
            The headings and subheadings herein are included for convenience and identification only and are not
            intended to describe, interpret, define or limit the scope, extent or intent of the Terms or the right to
            use the App by you contained herein or any other section or pages of the App or any Linked Sites in any
            manner whatsoever. <br /> <br />
            <span className={policyStyle.subheading}>Assignment</span><br />
            Paytail User shall not assign this Agreement to any third party under any circumstances however Paytail
            India Private Limited shall be free to assign this Agreement to any one without requiring giving prior
            notice to the Paytail User. <br /><br />
            <span className={policyStyle.subheading}>Severability</span><br />
            If any provision of the Terms is determined to be invalid or unenforceable in whole or in part, such
            invalidity or unenforceability shall attach only to such provision or part of such provision and the
            remaining part of such provision and all other provisions of these Terms shall continue to be in full force
            and effect. <br /><br />
            <span className={policyStyle.subheading}>Report Abuse </span><br />
            As per these Terms, Paytail Users are solely responsible for every material or content uploaded on to the
            App. PAYTAIL does not review the contents in any way before they appear through the App. PAYTAIL does not
            verify, endorse or otherwise vouch for the contents of any Paytail User or any content generally posted or
            uploaded on to the App. Paytail Users can be held legally liable for their contents and may be held legally
            accountable if their contents or material include, for example, defamatory comments or material protected by
            copyright, trademark, etc. If you come across any abuse or violation of these Terms, please report to
            PAYTAIL. <br />
            <span className={policyStyle.subheading}>Unauthorised Charges On Your Card </span><br /><br />
            If you see charges on your credit/debit card for purchases made through the App, but you never created an
            account or signed up, please check with your family members or business colleagues authorised to make
            purchases on your behalf, to confirm that they haven't placed the order. If you are still unable to
            recognise the charge, please report the unauthorised purchase to PAYTAIL within 7days of the transaction to
            enable PAYTAIL to begin an investigation. <br /><br />
            <span className={policyStyle.subheading}>Mispricing</span><br />
            Despite our best efforts, a small number of Products in our catalogue might be mispriced. However, we verify
            prices as part of our shipping procedures. If a Product's correct price (MRP) is lower than our stated
            price, we charge the lower amount and send you the Product along with a refund of the excess amount as per
            these Terms. If a Product's correct price (MRP) is higher than our stated price, we will cancel your order
            and notify you of that cancellation. <br /><br />
            <span className={policyStyle.subheading}>Profanity Policy </span><br />
            PAYTAIL prohibits the use of language that is obscene, racist, hateful or otherwise disparaging in nature in
            any manner whatsoever. <br />
            This policy is applicable text within listings, on merchants pages and all other areas of the site that
            another User may view. If the profane words are part of a title for the item being sold, we allow Sellers to
            'blur' out the bulk of the offending word with asterisks. <br />
            If a feedback comment, or any communication made between Paytail Users through the App; or email
            communication between Paytail Users in relation to transactions conducted on the App contain profane
            language, please submit a request for action/removal. Disciplinary action may result in either an indefinite
            suspension of a Paytail User's account, a temporary suspension, or a formal warning. PAYTAIL will consider
            the circumstances of an alleged policy violation and the Paytail User's trading records before taking
            action. Violations of this policy may result in a range of actions, including, limits placed on account
            privileges, loss of special status and account suspension. <br /><br />
            <h3>Grievance Officer</h3>
            <p>
                In accordance with Information Technology Act 2000 and rules made there under, the name and contact
                details of the Grievance Officer are provided below: <br /><br />
                </p>
                <p>
                    </p><address>
                        <em>
                            <b className={policyStyle.subheading}>Contact info below</b><br />
                            Nishant Patni<br />
                            Paytail Commerce Private Limited<br />
                            5th Floor, Unitech Commercial Tower-2,<br />
                            Block B, Greenwood City, <br />
                            Sector 45, Gurugram - 122001, Haryana<br /><br />
                            <b className={policyStyle.subheading}>Contact Us</b>
                            <br />
                            <b>Email</b>: grievance.officer@Paytail.com
                            <br />
                            <b>Phone</b>: 8880200500<br />
                            <b>Time</b>: Mon - Sat (9:00 - 18:00)
                            <br /><br />
                        </em>
                    </address>
                <p></p>
            <p></p>
            <br />

        <p></p>
    </div>
  );
};
  
export default TermsOfUse;
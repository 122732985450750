import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InstantIcon from '../../assets/images/instant-icon.svg'
import NoCostEmi from '../../assets/images/no-cost-emi-icon.svg'
import offerIcon from '../../assets/images/offer-icon.svg'
import storIcon from '../../assets/images/stor-icon.svg'
import emiIcon from '../../assets/images/emi-icon.svg'
import powerIcon from '../../assets/images/power-icon.svg'
import AOS from 'aos';
import 'aos/dist/aos.css';
import PaytailSpendPowerVideo from '../../assets/videos/Paytail-Spend-Power.mp4'
import Lottie from 'react-lottie';
import PowerVideo from '../../assets/jsonFile/Paytail-Spend-Power'
const PaytailPower = ()=>{

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: PowerVideo,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

    useEffect(() => {
        AOS.init();
      }, [])


    return(
        <div className="powerSec sectionPadding">
            <Container>
                <Row>
                    <Col>
                        <h2 className="secTitle" data-aos="fade-up" data-aos-duration="300" data-aos-easing="ease-in-sine" data-aos-delay="100">Paytail Spend Power </h2>
                    </Col>
                </Row>
                <Row>
                    <Col className="flexCenter">
                        <div className="powerBox leftSpace">
                            <div className="list"  data-aos="fade-right" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="200">
                                <div className="icon">
                                    <img src={InstantIcon} />
                                </div>
                                <div className="detail">
                                    <span>Instant Credit up to</span>
                                    <p><strong>Rs.2</strong> Lacs</p>
                                </div>
                            </div>
                            <div className="list" data-aos="fade-right" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="300">
                                <div className="icon">
                                    <img src={NoCostEmi} />
                                </div>
                                <div className="detail">
                                    <span>No Cost EMI options up to</span>
                                    <p><strong>18</strong> Months</p>
                                </div>
                            </div><div className="list" data-aos="fade-right" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="400">
                                <div className="icon">
                                    <img src={offerIcon} />
                                </div>
                                <div className="detail">
                                    <span>Pre-approved offers from</span>
                                    <p><strong>25+ </strong> Partner financial institutions</p>
                                </div>
                            </div>
                        </div>
                        <div className="powerBoxMobile">
                            {/* <img src={require('../../assets/images/powerMobile.png')} className="responsiveImg" /> */}
                            <video data-aos="fade-up" data-aos-duration="800" data-aos-easing="ease-in-sine" data-aos-delay="100" src={PaytailSpendPowerVideo} loop muted autoPlay className='paytailVide'></video>
                            <div className='lotiMobile' data-aos="fade-up" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="100">
                                <Lottie 
                                    options={defaultOptions}
                                    height="100%"
                                    width="100%"
                                /> 
                            </div>
                            
                        </div>
                        <div className="powerBox rightSpace">
                            <div className="list" data-aos="fade-left" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="200">
                                <div className="icon">
                                    <img src={storIcon} />
                                </div>
                                <div className="detail">
                                    <span>Buy products across</span>
                                    <p><strong>60,000</strong> Retailer Stores</p>
                                </div>
                            </div>
                            <div className="list" data-aos="fade-left" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="300">
                                <div className="icon">
                                    <img src={emiIcon} />
                                </div>
                                <div className="detail">
                                    <p className="bSize">Get 0 % EMI’s on online <br/>portals once you become a <br/>Power user.</p>
                                </div>
                            </div><div className="list" data-aos="fade-left" data-aos-duration="500" data-aos-easing="ease-in-sine" data-aos-delay="400">
                                <div className="icon">
                                    <img src={powerIcon} />
                                </div>
                                <div className="detail">
                                    <p className="bSize">Spend power keeps <br/>increasing for all customers <br/>repaying on time. </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PaytailPower;
import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ShopImg from '../../assets/images/shomImg.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
const SpotUs =()=>{
    useEffect(() => {
        AOS.init();
      }, [])
    return(
        <section className="spotSec sectionPadding fullHeight">
            <Container>
                <Row className="flexCenter">
                    <Col>
                        <div className="leftSpace spotDetail">
                            <h2 className="secTitle">
                                <span data-aos="fade-right" data-aos-duration="300" data-aos-easing="ease-in-sine" data-aos-delay="100">How to</span> 
                                <span data-aos="fade-right" data-aos-duration="300" data-aos-easing="ease-in-sine" data-aos-delay="120">spot us? Look for</span>
                                <span className="redColor" data-aos="fade-right" data-aos-duration="300" data-aos-easing="ease-in-sine" data-aos-delay="130"> crimson!</span> 
                            </h2>
                            <p data-aos="fade-right" data-aos-duration="400" data-aos-easing="ease-in-sine" data-aos-delay="200">Spot us at stores near you with our 'all-in-one' QR code in distinct crimson colour, you simply cannot miss.</p>
                        </div>
                    </Col>
                    <Col>
                        <div className="rightSpace spotImg">
                            <img 
                                data-aos="fade-up" data-aos-duration="400" data-aos-easing="ease-in-sine" data-aos-delay="300"
                                src={require('../../assets/images/spotImg.png')} className="responsiveImg" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default SpotUs;
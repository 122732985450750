import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Link, Stack } from "@mui/material";
import { Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles'
import Logo from '../../../assets/images/paytail_logo.png'
import './privacy_new.css'

const textStyle = {
  textDecoration: "none !important",
  fontSize: "16px",
  color: "#3875F4",
  margin: "1rem",
};

const StyledAccordionSummary = styled(AccordionSummary)({
  '& .MuiTypography-root': {
    fontSize: '18px'
  }
})

const StyledAccordion = styled(Accordion)({
  boxShadow: 'none',
  background: '#F5F5F5',
  borderRadius: '8px',
  '&:before': {
    // content: " ",
    opacity: '0'
  }
})

const PrivacyPolicy = () => {
  const [readMore, setreadMore] = useState(false)
  const handleShow = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setreadMore(prev => !prev)

  };
  return (
    <div className="mainDiv">
      <Box sx={{
        width: { xs: '90%', md: '60%', lg: '70%', xl: '50%' },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        margin: 'auto',
        marginBottom: '5rem'
      }} >
        <div className="center">
          <div>
            <a style={{ display: 'flex', justifyContent: 'center', margin: '4rem 1rem 2rem 1rem' }}>
              <img src={Logo} />
            </a>
          </div>
          <Stack spacing={3}>
            <Typography variant="h3" align="center" fontFamily="Inter, sans-serif" fontWeight={700} fontSize={{ xs: '32px', md: '48px', lg: '64px', }}>Privacy Policy</Typography>
            <Typography variant={{ xs: 'h5', md: "body2" }} align="center">Last updated: 02/05/2023</Typography>
            <div>
              <p>
                We value the trust you place in us and recognize the importance of secure transactions and information privacy. This Privacy Policy describes how Paytail Commerce Private Limited and its affiliates (collectively “Paytail, we, our, us”) collect, use, share or otherwise process your personal information through Paytail website www.paytail.com , its mobile application, and m-site (hereinafter referred to as the “Platform”).
              </p>
              {readMore && <p>
                By visiting the Platform, providing your information or availing our product/service, you expressly agree to be bound by the terms and conditions of this Privacy Policy, the Terms of Use and the applicable service/product terms and conditions . You hereby understand, acknowledge and consent to the following:
                <ol type="1">
                  <li>
                    You have read, understood and accept the Privacy Policy;
                  </li>
                  <li>
                    You consent to the collection, disclosure, storage, use and processing of your personal information by the Company in accordance with the Privacy Policy; and
                  </li>
                  <li>
                    You consent to the sharing and transfer of your personal information by the Company within or outside of India in accordance with the Privacy Policy.
                  </li>
                </ol>
                You confirm that you have provided your consent hereunder willingly. If you do not agree, please do not access or use our Platform.
              </p>}
              <Link
                href=""
                onClick={handleShow}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textDecoration: 'none',
                  mb: 3
                }}
              >
                <span style={textStyle}> {readMore ? 'Read Less' : 'Read More'}</span>
              </Link>
            </div>
          </Stack>
        </div>
        <Stack spacing={2} sx={{ width: { xs: '95%', md: '100%', margin: 'auto' } }}>
          <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <b>Information we collect</b>
            </StyledAccordionSummary>
            <AccordionDetails>
              <p>
                Paytail uses the information collected from You or about You to provide Services to You. When You set up Your account on the Paytail portal, we collect Your personal information such as Your first and last name; Your home address or other physical addresses; email addresses; telephone numbers; identity information (like PAN, address proof, date of birth, gender), bank statement or rental agreement, etc. In addition, We also collect and store certain information including security-related information that You use to access Our Website/Portal in relation to Our Services (“Account Information”). In general, Paytail will use the information collected from You for the purposes for which You provided such information and as set out below.
              </p>
              <p>
                Paytail may collect the following information (“Personal Information”) and you hereby explicitly consent to the following:
              </p>
              <ul>
                <li><b>Account Opening Information and Account Information: </b>  Self-declared information like name, referral code used, email, address details, photo, address proof and identity proof. Additionally, for opening account with Our Financing Partner:
                </li>
                <ul>
                  <li>
                    <b>Salaried individuals may have to provide: </b>Employment details like company name, salary/offer letter on case to case basis, employment ID, and company Email ID;
                  </li>
                  <li>
                    <b>Self-employed individuals may have to provide:</b>Self-employment details such as profession, social media links for or of Your work, work location and monthly income.
                  </li>
                </ul>
                <li>
                  <b>SMS Data Collection and Usage: </b>Our app collects, reads, and stores specific SMS messages from your inbox. We want to be clear that we do not collect or store your personal SMS messages. Our focus is solely on financial SMS messages that are sent by 6-digit alphanumeric senders. The purpose of collecting these messages is to help us identify various bank accounts you may hold, understand your cash flow patterns, and analyze the descriptions and amounts of transactions undertaken by you as a user. This data allows us to perform a credit risk assessment, which, in turn, enables us to determine your risk profile and provide you with appropriate credit analysis. This process is designed to assist you in accessing financial facilities from the regulated financial entities available on our platform. The financial SMS data we collect includes both current and historical data. While using the app, it periodically sends this financial SMS information to our affiliate server and to us.
                  <ul>
                    <li>
                      <b>Upload of SMS Log Information: </b>We want to make it clear that our app uploads your SMS log information. This is done for the sole purpose of processing financial SMS data, as detailed above. We assure you that this information is securely handled and is used strictly for the purposes outlined in this policy. We are committed to maintaining your trust and ensuring the privacy and security of your information. If you have any questions or concerns about our data handling practices, we encourage you to contact us at support@paytail.com
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Camera Access: </b>We require access to Your mobile device camera for a seamless experience for clicking Your selfie and to upload photos for Your KYC compliance and/or for uploading any other necessary documents on the Portal.
                </li>
                <li>
                  <b>Location: </b>We may collect and monitor Your location to verify Your current address, to ensure/check, serviceability of our services, to let You search for Your company/college name, and to prevent any fraudulent activity. We collect the location data from You in two ways: (i) when You add Your pin code on the Portal; and (ii) from Your mobile device when enabled by You to do so. We collect this data only when our Portal is being used. You may have restricted access to the Portal/services available through the Portal, if You disable/ withdraw/do not provide this access
                </li>
                <li>
                  <b>Contact: </b>We may periodically collect Your contacts’ information to facilitate credit assessment to be undertaken by your Financing Partner and to enable you access of some additional features on the Portal . You may have restricted access to the Portal/services available through the Portal, if You disable/withdraw/do not provide this access
                </li>
                <li>
                  <b>Installed Apps:  </b>We collect and analyse only the package name of installed applications to detect fraudulent/malware/spoof applications on the device and assess the creditworthiness.
                </li>
                <li>
                  <b>Device Data: </b>When You browse Our Website/Portal or apps, We also automatically receive Your devices’ internet protocol (IP) address. This helps Us learn about Your browser and operating system, software, the date and time at which You visited the Portals, the type of device being used (including the hardware models), the browser being used, etc, same would help us detect and prevent fraud.
                </li>
                <li>
                  <b>Storage: </b>We may require this permission so that Your documents can be securely downloaded and saved on Your phone, and so You can upload any required documents easily for quicker approval and disbursal of credit facility. This helps in providing a smooth and seamless experience while using the Portal.
                </li>
                <li>
                  <b>Feedback Data and Other Data:</b>We may access and save data in the following instances:
                  <ul>
                    <li>
                      When You contact Us or We contact You, We may record information provided by You to service You, or record calls for quality and training purposes;
                    </li>
                    <li>When You participate in Our referral programs or use any discount codes offered by Us and input such data;
                    </li>
                    <li>When You provide any feedback or comments to Us on the Portal or any other platform.
                    </li>
                  </ul>
                </li>
                <li>
                  <b>Third Party Information: </b>We or the Financing Partner may also work closely with third parties (including, for example, credit information bureaus, account aggregators, business partners, technical sub-contractors, analytics providers, search information providers, etc.) and may lawfully receive information about You from such sources. Such data may be combined with data collected on the Portal and such other information as provided in this Policy. Such third party will provide same or equal protection to the user data as provided in this Policy.
                </li>
              </ul>
              <p className="mt-3">
                You hereby expressly consent to Our access and use of Your aforesaid information, and disclosure of Your personal information in accordance with this Policy. We may require You to share further information on a later date to confirm the veracity of Your information or pursuant to any additional features added to the Portal.
              </p>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <b>What do we do with your information?</b>
            </StyledAccordionSummary>
            <AccordionDetails>
              <p>Personal Information provided by You will be used to: </p>
              <ul>
                <li> Open and update Your account on Paytail portal</li>
                <li> Establish and verify Your identity</li>
                <li> Provide Services, process service requests, and offer customer support</li>
                <li> Undertake research and development</li>
                <li> Facilitate credit assessment and personalised offers</li>
                <li> Analyse Your usage of the Portal and to improve the services/facilities on the Portal</li>
                <li> Deliver any administrative notices, alerts, advice, notifications and communication relevant to Your use of any of the facilities on the Portal through social media (including WhatsApp), SMS and other mediums</li>
                <li> Undertake market research, project planning and problem troubleshooting, and to protect against error, fraud or other criminal activity</li>
                <li> Disclose to governmental authorities, courts and regulatory laws or as otherwise required under any applicable laws or private contract</li>
                <li> Disclose to employees, agents and representatives on a need-to-know basis as part of the services</li>
                <li> Disclose to relevant and authorised financial service providers and Financing Partner who have partnered with Paytail for providing the loan facilities the customers.</li>
                <li> Facilitate in undertaking KYC authentication/compliance</li>
                <li> Assist in resolving disputes in relation to loan facilities made available by Financing Partners</li>
                <li> Disclose to the credit information bureau and such other authorities as may be required by applicable laws and</li>
                <li> Such other purposes as provided in this Policy</li>
              </ul>
              <p className="mt-3">
                Notwithstanding anything contained, Paytail will have the right to collect and process transactions executed by the Customer through the Portal. It is clarified that Paytail Commerce Private Limited will be undertaking preliminary KYC verification for the customers. Further Paytail Commerce Private Limited shares the KYC information and KYC documents (for final KYC verification) with its banking and Financing Partners, who provide loan facilities through the Portal. It is hereby clarified that, the ultimate responsibility for (a) customer identification procedure/ undertaking enhanced due diligence measures/ KYC verification; and (b) final decision-making function of determining whether a customer is in compliance with KYC regulation, shall remain with the banking and Financing Partners.
              </p>
              <p>
                Paytail may also be required to share Personal Information with its third-party contractors such as financial service providers, banks or NBFCs/Financing Partners. In such cases, Paytail shares the information securely and ensures that all personal information recipients comply with confidentiality, fidelity and secrecy obligations and sign covenants in this regard. Such third party will provide same or equal protection to the user data as provided in this Policy. Paytail may make information available to third parties that are financial and non-financial companies, such as service providers, government agencies, courts, legal investigators, and other non-affiliated third parties as requested by You or Your authorized representative, or otherwise when required or permitted by law.
              </p>
              <p>
                In addition to registration, Personal Information may be asked for reporting a problem/complaint with Paytail. We may send You emails about Our Portal, new products, payment reminders and other updates. We may use the phone number provided and verified by You to reach out to You via call, WhatsApp and SMS. Paytail will ensure that Personal Information provided by You is treated securely, in accordance with the provisions of this Policy and the Rules. You are advised to refrain from posting personal information anywhere on the Portal, other than those specifically required by Paytail. The account information and profile are password protected. Paytail will never ask for Your password through unsolicited phone calls or emails. However, if any person, claiming to be an authorized representative of Paytail, contacts You, We request You not to provide any information to such person and instead immediately contact Paytail as per the contact details provided in this Policy. Paytail shall not be liable for any loss of Personal Information provided to such persons.
              </p>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <b>Link to third-party websites</b>
            </StyledAccordionSummary>
            <AccordionDetails>
              <p>
                Our application has a link to third-party websites. Your use of these features may result in the collection, processing or sharing of information about You, depending on the feature. Please be aware that we are not responsible for the content or privacy practices of other websites or services which may be linked to Our services. We do not endorse or make any representations about third-party websites or services. Our Privacy Policy does not cover the information You choose to provide to or that is collected by these third parties. We strongly encourage You to read such third parties' privacy policies.
              </p>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <b>Cookies</b>
            </StyledAccordionSummary>
            <AccordionDetails>
              We use data collection devices such as "cookies" on certain pages of the Platform to help analyse our web page flow, measure promotional effectiveness, and promote trust and safety. "Cookies" are small files placed on your hard drive that assist us in providing our services. Cookies do not contain any of your personal information. We offer certain features that are only available through the use of a "cookie". We also use cookies to allow you to enter your password less frequently during a session. Cookies can also help us provide information that is targeted to your interests. Most cookies are "session cookies," meaning that they are automatically deleted from your hard drive at the end of a session. You are always free to decline/delete our cookies if your browser permits, although in that case you may not be able to use certain features on the Platform and you may be required to re-enter your password more frequently during a session. Additionally, you may encounter "cookies" or other similar devices on certain pages of the Platform that are placed by third parties. We do not control the use of cookies by third parties. We use cookies from third-party partners such as Google Analytics for marketing and analytical purposes. Google Analytics help us understand how our customers use the site. You can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/ . You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout .
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <b>Sharing of personal information</b>
            </StyledAccordionSummary>
            <AccordionDetails>

              <p> We may share personal information with our other corporate entities and affiliates for purposes of providing products and services offered by them, such as, the deferred payment options Paytail PayLater/Paytail Cardless EMI offered by lending partners. These entities and affiliates may share such information with their affiliates, business partners and other third parties for the purpose of providing you their products and services, and may market to you as a result of such sharing unless you explicitly opt-out.</p>

              <p> We may disclose your personal information to third parties, such as sellers, business partners. This disclosure may be required for us to provide you access to our products and services; for fulfilment of your orders; for enhancing your experience; for providing feedback on products; to collect payments from you; to comply with our legal obligations; to conduct market research or surveys; to enforce our Terms of Use a; to facilitate our marketing and advertising activities; to analyse data; for customer service assistance; to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to our product and services. We do not disclose your personal information to third parties for their marketing and advertising purposes without your explicit consent.</p>

              <p> We may disclose personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may disclose personal information to law enforcement agencies, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce our Terms of Use or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of our users or the general public.</p>

              <p> We and our affiliates will share / sell some or all of your personal information with another business entity should we (or our assets) plan to merge with, or be acquired by that business entity, or re-organization, amalgamation, restructuring of business. Should such a transaction occur that other business entity (or the new combined entity) will be required to follow this Privacy Policy with respect to your personal information.</p>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <b>User Data Deletion</b>
            </StyledAccordionSummary>
            <AccordionDetails>
              <ul>
                <li>
                  Paytail provides users with full control over their data, including what data is retained and what data is deleted.
                </li>
                <li>End users can request all or part of their data to be deleted by sending an email to support@paytail.com. Once the request is approved, Paytail will delete their data.
                </li>
              </ul>
              <p className="mt-3"><b>Note: </b> We shall not retain your Information if it is no longer required by Us and there is no legal requirement to retain the same. Do note that multiple legal bases may exist in parallel, and We may still have to retain certain Information as may be required for audit purposes and/or in accordance with applicable law.</p>

              <p>In the event any Information provided by you is inaccurate, incomplete, or outdated then you should provide Us with accurate, complete, and up-to-date Information and have Us rectify such Information at Our end immediately. We urge you to ensure that you always provide Us with accurate and correct Information to ensure your use of Our Services is uninterrupted.</p>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <b>Links to Other Sites</b>
            </StyledAccordionSummary>
            <AccordionDetails>
              <p>Our Platform may provide links to other websites or application that may collect personal information about you. We are not responsible for the privacy practices or the content of those linked websites.
              </p>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <b>Security Precautions</b>
            </StyledAccordionSummary>
            <AccordionDetails>
              We maintain reasonable physical, electronic and procedural safeguards to protect your information. Whenever you access your account information, we offer the use of a secure server. Once your information is in our possession we adhere to our security guidelines to protect it against unauthorized access. However, by using the Platform, the users accept the inherent security implications of data transmission over the internet and the World Wide Web which cannot always be guaranteed as completely secure, and therefore, there would always remain certain inherent risks regarding use of the Platform. Users are responsible for ensuring the protection of login and password records for their account.
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <b>Choice/Opt-Out</b>
            </StyledAccordionSummary>
            <AccordionDetails>
              We provide all users with the opportunity to opt-out of receiving non-essential (promotional, marketing-related) communications after setting up an account with us.
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <b>Advertisements on Platform</b>
            </StyledAccordionSummary>
            <AccordionDetails>
              We use third-party advertising companies to serve ads when you visit our Platform. These companies may use information (not including your name, address, email address, or telephone number) about your visits to this and other websites in order to provide advertisements about goods and services of interest to you.
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <b>Children Information</b>
            </StyledAccordionSummary>
            <AccordionDetails>
              We do not knowingly solicit or collect personal information from children under the age of 18 and use of our Platform is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872. If you are under the age of 18 years then you must use the Platform, application or services under the supervision of your parent, legal guardian, or any responsible adult.
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <b>Data Retention</b>
            </StyledAccordionSummary>
            <AccordionDetails>
              We retain your personal information in accordance with appliable laws, for a period no longer than is required for the purpose for which it was collected or as required under any applicable law. However, we may retain data related to you if we believe it may be necessary to prevent fraud or future abuse or if required by law or for other legitimate purposes. We may continue to retain your data in anonymised form for analytical and research purposes.
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <b>Your Consent</b>
            </StyledAccordionSummary>
            <AccordionDetails>
              <p>By visiting our Platform or by providing your information, you consent to the collection, use, storage, disclosure and otherwise processing of your information (including sensitive personal information) on the Platform in accordance with this Privacy Policy. If you disclose to us any personal information relating to other people, you represent that you have the authority to do so and to permit us to use the information in accordance with this Privacy Policy.</p>

              <p>You, while providing your personal information over the Platform or any partner platforms or establishments, consent to us (including our other corporate entities, affiliates, lending partners, technology partners, marketing channels, business partners and other third parties) to contact you through SMS, instant messaging apps, call and/or e-mail for the purposes specified in this Privacy Policy.</p>


            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <b>Changes to this Privacy Policy</b>
            </StyledAccordionSummary>
            <AccordionDetails>
              Please check our Privacy Policy periodically for changes. We may update this Privacy Policy to reflect changes to our information practices. We will alert you to significant changes by posting the date our policy got last updated, placing a notice on our Platform, or by sending you an email when we are required to do so by applicable law.
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <b>Grievance Officer</b>
            </StyledAccordionSummary>
            <AccordionDetails>
              <p>
                In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:
                <br />
                <br />
                Contact info below
                <br />
                <br />
                Nishant Patni
                <br />
                Paytail Commerce Private Limited
                <br />
                5th Floor, Unitech Commercial Tower 2,
                <br />
                Block B, Greenwood City,
                <br />
                Sector 45, Gurugram - 122001, Haryana.
                <br />
                <br />
                <b> Contact Us</b>
                <br />
                <b> Email: </b> grievances@paytail.com
                <br />
                <b> Phone: </b> 8880200500
                <br />
                <b> Time: </b> Mon - Sat (9:00 - 18:00)
              </p>
            </AccordionDetails>
          </StyledAccordion>
          <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <b>Queries</b>
            </StyledAccordionSummary>
            <AccordionDetails>
              If you have a query, issue, concern, or complaint in relation to collection or usage of your personal information under this Privacy Policy, please contact us at the contact information provided above.
            </AccordionDetails>
          </StyledAccordion>
        </Stack>
      </Box >
    </div>
  )
};

export default PrivacyPolicy;
import React from "react";
import policyStyle from "../Style/policyStyle.module.css";
import fintree from '../../assets/images/fintree.png';
import airthmate from '../../assets/images/airthmate.png';
import chola from '../../assets/images/chola.png';
import { Link } from "react-router-dom";

const ConsumerTermCondition = () => {
  return (
    <div className="policy_content" id="topscroll">
      <h2 className={policyStyle.header}>
        CUSTOMER CREDIT &amp; ALLIED TERMS (Paytail Services)
      </h2>
      <p>
        This Customer Credit &amp; Allied Terms (hereinafter referred to as “Terms &amp; Conditions”) are binding on Customer who
        has accepted these terms and consented to avail the Paytail Services in accordance with the Paytail Application-
        Terms of Use, Privacy Policy, Cancellation &amp; Refund Policies by clicking the “accept”/ “proceed” button at the
        time of his/her registration. These Terms &amp; Conditions are in addition to the Paytail Application- Terms of Use,
        Privacy Policy, Cancellation &amp; Refund Policies accepted by the Paytail User at the time of registration on Paytail
        Application and the same are incorporated herein by of reference.
      </p>
      <p>
        This document is published in accordance with the provisions of Rule 3(1) of the Information Technology
        (Intermediaries Guidelines) Rules, 2011 which require the publishing of the rules and regulations governing the
        use and access of “PAYTAIL” mobile application and the Paytail Services (defined below), or any other portal as
        may be launched by Paytail India Private Limited ('Paytail App').
      </p>
      <p>
        These terms and conditions ('T&amp;C'), and all applicable policies which are herein incorporated by way of reference
        govern your rights and obligations as a user of the services provided by Paytail India Private Limited (defined
        below) to Paytail Users (defined below), while accessing and using the Paytail online application and/or availing
        the Paytail Services as may be rendered by Paytail India Private Limited, a company incorporated under the laws of
        India and having its registered office 605, Tower 2, Hewo-1, Sector 56, Gurugram, Haryana-122011 in ('Paytail').
        You agree and acknowledge that the Paytail Services may be provided by Paytail, through its partner merchants
        and/or third-party banks or financial institutions (list as per Schedule 1). You agree of having read and
        understood the Frequently Asked
        Questions ('FAQ') uploaded from time to time.
      </p>
      <p>
        By availing the Paytail Services, you agree to be bound by these T&amp;C, including any additional guidelines and
        future modifications issued by Paytail from time to time. If at any time you do not agree with these T&amp;C, you
        shall immediately cease your use of such Paytail Services. Paytail reserves the right to amend, add, or remove
        portions of these T&amp;C, from time to time, without notice to you by posting revised T&amp;C on the Paytail App and/or
        Paytail Mobile Application.
      </p>
      <p>
        Your access to and use of the Paytail App and the Paytail Services are conditional upon your acceptance of and
        continuous compliance with these T&amp;C at all times. By accessing, browsing and/or using the Paytail App and/or
        availing any of its services, including Paytail Services, you agree to be bound by these T&amp;C, including all rules,
        guidelines, policies, terms and conditions applicable to the Paytail Services being provided by Paytail
        ('Policies'), as may be modified by Paytail from time to time, which shall be deemed to be incorporated in these
        T&amp;C by reference, unconditionally and at all times. Paytail shall have the sole right to change, modify, add or
        remove these T&amp;C or any of its Policies relating to the provisions of access to the Paytail App and Paytail
        Services, at any time without any prior written notice or intimation. It is hereby clarified that you shall be
        responsible for reviewing the T&amp;C and Policies on the Paytail App periodically and keep yourself updated with any
        change in the T&amp;C and Policies. In fact, Paytail Users should periodically review this page for the latest
        information on our T&amp;C and Policies. Once posted, those changes are effective immediately, unless stated
        otherwise. Continued access or use of the Services constitutes your acceptance of the changes and the amended T&amp;C
        and Policies. However, if you do not agree with the changes, please do not continue to use the Services or submit
        Information to us.
      </p>
      <br />
      <h3 className={policyStyle.font_weight_bold}>A. USE OF APPLICTION AND SERVICES OF PAYTAIL </h3>
      <p>
      </p><ul className={policyStyle.numeral} type="1">
        <li>
          The access to the Paytail App is only available to persons who are competent to enter into legally binding
          contracts under the Indian Contract Act, 1872. Any person who shall be considered as incompetent to contract as
          per the provisions of the Indian Contract Act, 1872, including minors, un-discharged insolvents, etc., are not
          eligible to use the Paytail App and/or the Paytail Services. If you access Paytail App or use Paytail Services,
          you, as a Paytail User, represent to Paytail that at least of 18 years age and are competent to enter into
          legally binding contracts under the Indian Contract Act, 1872.
        </li>
        <br />
        <li>
          For accessing the Paytail App and using Paytail Services, you, as a Paytail User, further represent to Paytail
          that you are an Indian National having tax residency in India.
        </li>
        <br />
        <li>
          It is hereby clarified that Paytail reserves the right to provide the Paytail Services to only those users who
          are both competent to enter into legally binding contracts, have made the representations as provided above, and
          also qualify under the internal policy(s) for the same determined solely by Paytail from time to time ('Paytail
          User'/ 'you'/'your'). Paytail shall have the sole right to change, modify, add or remove, in whole or in part,
          its internal policy(s) in relation to the provision of the Paytail Services of Paytail, at any time without any
          prior written notice or intimation to the Paytail Users and Paytail shall have the right to not entertain any
          requests in relation to the same, from the Paytail Users towards such Paytail Services, without assigning any
          reason.
        </li>
        <br />
        <li>
          You hereby authorize Paytail to register you with Paytail in order to avail the Paytail Services, other features
          and products offered by Paytail. Accordingly, in the event a Paytail User is not already registered with Paytail
          in relation to the other services rendered by Paytail or does not have a Paytail Account (defined below),
          Paytail is authorized to register such Paytail User, by creating the Paytail User’s account with Paytail in
          relation to the Paytail Services, offered by Paytail ('Paytail Account'). You cannot avail the Paytail Services
          unless you have an existing Paytail Account. It is hereby clarified that all terms and conditions in relation to
          creation and use of the Paytail Account as specified on the Paytail App shall be applicable in addition to these
          T&amp;C.
        </li>
        <br />
      </ul>
      <h3 className={policyStyle.font_weight_bold}>B. TERMS OF USE</h3>
      <p>
      </p><ul className={policyStyle.numeral} type="1">
        <li>
          Paytail provides to the Paytail Users (i) option for payment facilitation services for purchase of product or
          service on specific merchant websites/ sales link or outlets ('Partner Merchant'), wherein the Paytail User
          shall be provided can opt for a checkout transaction processing at Partner Merchant websites/ sales link or
          outlet and providing invoicing services and payment management systems in relation to the purchase of products
          or services from the Partner Merchant; and/or (ii) option to a Paytail User to transfer funds into the Paytail
          User’s verified bank account (iii) Partner Merchant checkout option which can be availed by the Paytail User and
          make the payment on instalments, on the terms and conditions that may be accepted by the Paytail User from time
          to time (above options jointly and severally known as 'Paytail Services'). It is clarified that Paytail reserves
          the right to Paytail service to any Paytail User in whole or in part.
        </li>
        <br />
        <li>
          In order to render the Paytail Services, Paytail may partner with the Partner Merchant, third party banks or
          financial institutions and such other entities ('Financial Institutions') as may be determined by Paytail, in
          its sole discretion.
        </li>
        <br />
        <li>
          The Paytail User availing the Paytail Services shall be entitled to make payment of the purchase price of the
          products, subject to monetary limited specified for Paytail User by the Partner Merchant’s bank account through
          Paytail, ('Transaction Amount'). Paytail and/or Partner Merchant’s bank reserves the right to amend, modify or
          restrict the monetary limit/ approve or reject funds in case of option as applicable to each Paytail User, at
          its sole discretion, for any reason whatsoever.
        </li>
        <br />
        <li>
          The Paytail User hereby agrees and acknowledges that the payment of the Transaction Amount shall be made by the
          Paytail User, to Paytail, who is authorised by the Partner Merchant and/or third party banks or financial
          institutions to collect the Transaction Amount, on behalf of the Partner Merchant and/or third party banks or
          financial institutions, or directly to the financial institutions as the case may be, in the manner specified by
          Paytail. By availing the Paytail Services, you authorize Paytail or its authorized representatives to collect
          the Transaction Amount from you, on behalf of the Partner Merchant and process, facilitate and remit the
          payments to the Partner Merchant selling the products.
        </li>
        <br />
        <li>
          Once the Paytail User becomes eligible to avail Paytail Services and is selected and approved by Paytail, the
          Paytail Services option for transfer of funds into the Paytail User’s/ Merchant’s bank account as the case may
          be.
        </li>
        <br />
        <li>
          Upon selection of the Paytail Service option on the Partner Merchant’s platform by the Paytail User, the Paytail
          User will be required to complete the transaction(s) through one of the methods of authorization prescribed by
          Paytail from time to time, including but not limited to entering the one-time password issued by Paytail to such
          Paytail User to complete the transaction ('Authentication Process'). Completion of the Authentication Process,
          shall effectuate the transaction vis-à-vis the Partner Merchant or the Partner Merchant and the financial
          partner, as the case may be and the transaction shall be deemed completed and the Paytail User hereby authorises
          Paytail to deduct such Transaction Amount from his/her Paytail Account upon completion of the Authentication
          Process. For provision of the option of transfer of funds into the Paytail User’s bank account, the Paytail
          Users KYC will be collected and/or validated; bank account for credit of Paytail approved amount and re-payment
          thereof, will be linked once the Paytail User accepts the credit agreement between Paytail User and the
          Financial Institution, at the time of confirmation of the credit facility eligibility by Financial Institution/
          Partner Merchant Bank, and Paytail, and allied terms and conditions governing such credit. Upon successful
          completion of the KYC and credit rating verification of the Paytail, the funds will be transferred into the
          seller Merchant’s bank account to the extent of Product/Services price. The Paytail User hereby authorises
          Paytail to deduct such Transaction Amount from his/her Paytail Account or Paytail User’s Bank account in which
          the Transaction amount was credited.The Paytail User shall ensure that true and correct details are furnished to
          Paytail including all information required for the Paytail account opening process and the KYC process and such
          or other information, details and clarifications that Paytail may require from time to time. For the purposes of
          this clause, the Paytail User agrees and understands that the Paytail account is neither in the nature of
          savings bank account nor current meant for any banking transactions. Paytail account is solely for the purposes
          of providing the updates related to the Paytail User’s active loan/s, EMI payment details, paymen schedule etc.
        </li>
        <br />
        <li>
          Paytail User Agrees to utilize the credit limit provided by the Merchant Bank through Paytail Services towards
          the purcase of products and services from the Merchants of Paytail only. No cash withdrawl is permitted from
          Merchant location or other banks against the credit limit. If the Customer and/or merchant is found to be
          fraudulently dealing in cash againt the credit limit then the Merchant Bank reservs the righ to recover a higher
          lending rate on the utilized credit limit at its sole discretion from the Paytail User and/or blacklist the
          Paytail User on account of indulging into fraudulent practices.
        </li>
        <br />
        <li>
          Paytail User understands and agrees that, pursuant to the provisions under applicable laws, approvals/
          permissions, and policies of Paytail and Financial Institutions, Paytail may be (either by itself or through
          authorised third party) required to collect physical certified true copies of account opening documents / KYC
          documents/ payments etc from the Paytail User. Paytail User agrees and consents to cooperate and provide all the
          such relevant data/ documents etc. for the purpose of verification and account opening.
        </li>
        <br />
        <li>
          The details pertaining to the Authentication Process or KYC collection/ verification and linkage to bank account
          shall be issued by Paytail and shall be sent to the Paytail User and Paytail User hereby provide his/her consent
          to receive communications, notices and information from us electronically, whether sent by e-mail or other
          electronic means. Paytail User hereby consent and agree that any pin, password etc. issued by Paytail, shall be
          used or undertaken, as the case may be, only by the Paytail User, that they shall not be disclosed by Paytail
          User to any third party and that Paytail User shall take all such steps, including deleting/destroying all
          records of pin, password etc. issued by Paytail, to ensure that such unauthorised disclosure of the same is
          prevented. Paytail User agree to immediately notify Paytail of any unauthorized use of your pin, password etc.
          issued by Paytail or any other breach of security. It is hereby clarified that Paytail will not be liable for
          any loss, damage or other liability arising from your failure to comply with these provisions or from any
          unauthorized access to or use of the pin, password etc. issued by Paytail. In the event of any dispute between 2
          (Two) or more parties as to ownership of a particular OTP or any other form of authentication (as may be
          specified by Paytail), Paytail User agree that Paytail will be the sole arbiter of such dispute, at its sole
          discretion and that Paytail's decision (which may include termination or suspension of any account subject to
          dispute) will be final and binding on all parties.
        </li>
        <br />
        <li>
          Paytail User hereby acknowledge that he/she is solely responsible for the repayment of loan/ credit facility
          amount, transactions / payments made for the products/ services purchased by Paytail User, by entering the pin,
          password etc. issued by Paytail/third party financial institution to Paytail User. Paytail User consent and
          agree to not revoke such transactions at any time except in accordance with the Cancellation &amp; Refund Policies
          of Paytail Services. Paytail User hereby acknowledge that other than pin, password etc. issued by Paytail,
          entered by he/she for a particular transaction, Paytail has no obligation, responsibility or liability to verify
          the transactions authenticated and/or authorised by Paytail User or his/her payment instructions.
        </li>
        <br />
        <li>
          Paytail User hereby acknowledge that he/she is solely responsible for the repayment of loan/ credit facility
          amount, transactions / payments made for the products/ services purchased by Paytail User, by entering the pin,
          password etc. issued by Paytail/third party financial institution to Paytail User. Paytail User consent and
          agree to not revoke such transactions at any time. Paytail User hereby acknowledge that other than pin, password
          etc. issued by Paytail, entered by he/she for a particular transaction, Paytail has no obligation,
          responsibility or liability to verify the transactions authenticated and/or authorised by Paytail User or
          his/her payment instructions.
        </li>
        <br />
        <li>
          Paytail User hereby agree and acknowledge that Paytail has been authorized by the Partner Merchant and/or third
          party banks or financial institutions, as the case may be, to collect the Transaction Amount, on behalf of the
          Partner Merchant and/or third party banks or financial institutions, as the case may be and Paytail User shall
          be liable to pay to Paytail the Transaction Amount as per the payment schedule and/or upon demand in case of
          default, as the case may. Paytail shall raise a statement on the Paytail User pertaining to the transactions
          undertaken by the Paytail User through Paytail Services ('Statement Amount') specifying details pertaining to
          the timelines and manner in which the Statement Amount is required to be repaid by the Paytail User
          ('Statement') to Paytail by e-mail/SMS/any other medium as identified by Paytail from time to time. Paytail
          User, however, hereby agree, acknowledge and confirm that Paytail shall not be liable under any circumstances
          whatsoever in relation to non-delivery and/or delay in receipt of the Statement whether by e-mail/SMS/any other
          medium as may be identified by Paytail, due to default / non-delivery / delay at the end of the third party(s)
          telecom service provider(s) and/or other service provider(s). User is advised to check their Paytail profile on
          the platform on regular basis for updates related to the Statement.
        </li>
        <br />
        <li>
          It is hereby clarified that all products or services made available to a Paytail User by the Partner Merchant
          are provided on an ‘as is’ and ‘as available’ basis, without any representations and warranties, express or
          implied by Paytail. Paytail does not make any representations pertaining to the information, content,
          products/services included on or delivery of the products/services or otherwise made available to a Paytail User
          through the Partner Merchant and only acts as an intermediary between the Partner Merchant and the Paytail User.
          Paytail User hereby further agree, acknowledge and confirm that Paytail is not responsible in any way for the
          product/services purchased by him/her from the Partner Merchant and it is explicitly agreed by Paytail User that
          Paytail will not in any way, under any circumstances whatsoever, be responsible or liable for products/services
          purchased by Paytail User from the Partner Merchant and/or in relation to any issue and/or dispute thereof.
          Paytail User hereby further agree, acknowledge and confirm that under the aforesaid circumstances his/her only
          recourse will be against the Partner Merchant and/or the Financial Institution and Paytail will not be made a
          party to any such issue and/or dispute between Paytail Userrself and the Partner Merchant/ Financial Institution
          as the case may be.
        </li>
        <br />
        <li>
          Paytail shall have the right to not process a transaction through the Paytail Services, if Paytail in its sole
          discretion determines that a transaction undertaken by a Paytail User is not authorised by such Paytail User or
          the transaction is not genuine or suspicious.
        </li>
        <br />
        <li>
          Paytail User hereby consent and agree to comply with guidelines, instructions, requests, etc., as maybe made by
          Paytail or a Partner Merchant or third party banks or financial institutions, as the case may be or a payment
          system provider from time to time, in relation to the Paytail Services.
        </li>
        <br />
        <li>
          Paytail User hereby acknowledge and agree that Paytail shall not be liable for failure of any transaction
          undertaken on the Partner Merchant platform for any reason whatsoever including but not limited to
          non-performance or omission or commission on the part of the Partner Merchant, deficiency of service and/or
          products delivered, technical errors on the Partner Merchant platform, disputes qua repayments pursuant to the
          any Transaction etc. Paytail User further acknowledge that Paytail shall not be responsible, in any manner
          whatsoever, for any loss incurred by Paytail User for a failed / incomplete transaction undertaken by Paytail
          User using Paytail Services.
        </li>
        <br />
        <li>
          If You choose to browse or transact on the Platform using the voice command-based shoppingfeature, you
          acknowledge that Paytail and/or its affiliates will collect, store and use your voice inputs on this feature to
          customize your experience and provide additional functionality as well as to improve Paytail’s and/or its
          affiliates’ products and services. Paytail’s and/or its affiliates’ use of your voice data will be in accordance
          with the Paytail Privacy Policy. You accept that your use of this feature is restricted to the territory of the
          Republic of India. This feature may not be accessible on certain devices and requires an internet connection.
          Paytail reserves the right to change, enhance, suspend, or discontinue this feature, or any part of it, at any
          time without notice to you. Your continued use of this feature constitutes your acceptance of the terms related
          to this feature.
        </li>
        <br />
      </ul>
      <h3 className={policyStyle.font_weight_bold}>C. NO COST EMI TERMS</h3>
      <p>
        The following terms and conditions apply to no cost equated monthly installment ("EMI") transactions made using an
        eligible credit card issued by any bank and using EMI facility as a payment option ("No Cost EMI")
      </p>
      <p>
      </p><ul className={policyStyle.numeral} type="1">
        <li>
          The No Cost EMI facility is being offered to the customers who make a purchase transaction through Paytail App
          thereof (collectively, "Paytail.com") using an eligible credit/ debit card issued by any bank using EMI
          facility; if available on Paytail.com
        </li>
        <br />
        <li>
          The No Cost EMI facility is funded by participating sellers and brands (as the case may be) and is made
          available on select products, as determined from time to time by such sellers and/or brands.
        </li>
        <br />
        <li>
          The No Cost EMI payment option can be availed using the credit/ debit card of any bank on paytail.com.
        </li>
        <br />
        <li>
          Using the No Cost EMI payment option, the customers who undertake the purchase transactions through Paytail.com,
          will only pay amounts such that the total of these amounts during the EMI tenure is equal to the list price of
          the products as displayed on Paytail.com Or price agreed between the Paytail User and the seller (at the time of
          making the purchase transactions). The participating sellers or brands (as the case may be) will provide amounts
          equivalent to the interest imposed by the banks to undertake the purchase transactions on EMI.
        </li>
        <br />
        <li>
          The banks issuing the credit/ debit cards may charge Goods and Services Tax (GST) or other applicable taxes on
          the purchase transactions undertaken on EMI which will be over and above the product/ services price.
        </li>
        <br />
        <li>
          Customers can add more than one item to their cart and if all the products are eligible for No Cost EMI, such
          customer will receive No Cost EMI payment option on all items. No Cost EMI discount will be calculated only on
          the eligible items in the cart.
        </li>
        <br />
        <li>
          Customers may avail the No Cost EMI facility, provided that: <br />
          &nbsp;&nbsp;&nbsp;the order is not cancelled by the customer or the participating sellers or paytail.com <br />
          &nbsp;&nbsp;&nbsp;the product is not returned / exchanged by the customer. <br />
        </li>
        <br />
        <li>
          The EMI facility is made available to the customers by and in the sole discretion of the banks issuing the
          credit/ debit cards. Paytail will not be liable for any claims on account of availability or non-availability of
          EMI facility.
        </li>
        <br />
        <li>
          Paytail, seller, brand or Partner Merchant Bank reserves the right to stop No Cost EMI payment option at any
          time without prior notice and without any liability.
        </li>
        <br />
      </ul>
      <h3 className={policyStyle.font_weight_bold}>D. KYC &amp; SUBMISSION OF PERSONAL INFORMATION</h3>
      <p>
      </p><ul className={policyStyle.numeral} type="1">
        <li>
          Paytail User agree that the personal information such as name, address, contact number, KYC details, etc.
          ('Personal Information') Paytail User provide to Paytail upon registration and at the time of availing the
          Paytail Services and at all other times will be true, accurate, current and complete. Paytail User agree to
          maintain and update the Personal Information and to keep the Personal Information true, accurate and complete.
        </li>
        <br />
        <li>
          If Paytail User provide any Personal Information that is untrue, inaccurate, not current or complete, Paytail
          has the right to suspend or terminate his/her Paytail Account created on the Paytail App and refuse any and/or
          all use, either present or future, of the services of Paytail, including Paytail Services, or any portion
          thereof. It is hereby clarified that Paytail shall not be liable in any manner whatsoever for any loss or damage
          incurred by a Paytail User or any dispute initiated by or against a Paytail User, due any untrue, inaccurate,
          not current or incomplete Personal Information provided by such Paytail User.
        </li>
        <br />
        <li>
          Paytail User hereby agree that the Personal Information provided by his/her to Paytail at the time of
          registration on the Paytail App shall be collected, stored, analysed and used by Paytail for the purpose of
          providing Paytail User with efficient access to the Paytail App, Paytail Services, and other features, products
          and services offered by Paytail. Paytail User hereby agree, acknowledge and grant his/her consent to Paytail to
          further use and share the Personal Information and other personal details, as may have been disclosed by the
          Paytail User to the Partner Merchants and third party service providers engaged by Paytail and Paytail User
          hereby confirm that he/she has no objection to receive promotional offers, deals, marketing material,
          promotional material and any other form of communications from Paytail and/or the Partner Merchant in such form
          as may be determined by Paytail in its sole discretion, including electronic communications. Paytail User hereby
          agree, acknowledge and accept the Paytail <Link to="/privacypolicy" className={policyStyle.links_styles}>Privacy Policy</Link> and that his/her Personal Information will be handled by Paytail in accordance with the Paytail <Link to="/privacypolicy" className={policyStyle.links_styles}>Privacy Policy</Link> . In case of any discrepancy between the terms of this T&amp;C and the Paytail <Link to="/privacypolicy" className={policyStyle.links_styles}>Privacy Policy</Link> pertaining to handling, processing, using, sharing, disclosing, etc. of his/her Personal Information, the terms of the <Link to="/privacypolicy" className={policyStyle.links_styles}>Privacy Policy</Link> shall prevail.
        </li>
        <br />
        <li>
          Paytail User further agree that such Personal Information may be displayed, automatically or otherwise, upon
          logging into his/her Paytail Account and/or initiating any transaction on the Partner Merchant/ Seller sales
          point (offline or physical). Please note that Paytail User’s information, including Personal Information and
          information required to service his/her Paytail Account, is readily available on the Paytail App and only
          viewable by him/her by logging on to the Paytail Application.
        </li>
        <br />
        <li>
          It may be noted that Paytail handles all such information including Personal Information in accordance with the
          provisions of the applicable law as may be applicable from time to time and only for the duration lawfully
          required, there under.
        </li>
        <br />
        <li>
          Paytail User hereby specifically agree that he/she shall, at all times, comply with the requirements of the
          Information Technology Act, 2000 as also rules, regulations, guidelines, byelaws and notifications made there
          under.
        </li>
        <br />
        <li>
          Paytail User hereby authorise Paytail to request for additional information/documentation, at any time, in order
          to verify that the information provided by him/her is accurate and complete. In order to allow Paytail User’
          continued and uninterrupted use of the Paytail Services, Paytail shall request from time to time, for additional
          information/documentation from the Paytail User, in order to be in conformity with know your customer (KYC)
          requirements as per applicable laws. The Paytail User agree to provide Paytail with such additional
          information/documentation, as is required by Paytail at any time, to verify the accuracy and completeness of the
          information provided by him/her and its conformity with the said KYC requirements.
        </li>
        <br />
      </ul>
      <h3 className={policyStyle.font_weight_bold}>E. Termination</h3>
      <p>
        Paytail may forthwith terminate and/or suspend, in whole or in part, these T&amp;C, the Policies, Paytail App and/or
        the Paytail Services provided hereunder, for any reason including for reasons of breach of these T&amp;C and/or any of
        the Policies on the Paytail App. Your sole right to terminate these T&amp;C and the Policies is by deleting your
        Paytail Account. In the event of termination of these T&amp;C by Paytail or the Paytail User, the Paytail User shall
        be liable to pay the amounts due to Paytail or Partner Merchant Bank under the terms of this Agreement, as per the
        Statement raised by Paytail till the date of such termination.
      </p>
      <h3 className={policyStyle.font_weight_bold}>F. JURISDICTION</h3>
      <p>
        The courts at Gurugram, Haryana shall have exclusive jurisdiction over any disputes relating to the subject matter
        herein.
      </p>
      <br />
      <h3 className={policyStyle.font_weight_bold}>G. MISCELLANEOUS TERMS </h3>
      <p>
      </p><ol className={policyStyle.misc} type="i">
        <li>
          <span className={policyStyle.subheading}>Notices :</span><br />
          All notices, requests, demands, waivers, complaints, queries and other communications required or permitted to
          be given hereunder in relation to the Paytail Services under these T&amp;C, shall be emailed at admin@Paytail.com,
          if to Paytail and if to Paytail User, it shall be to the address registered with Paytail. Any other notices,
          requests, demands, waivers, complaints, queries and other communications arising out of any Partner Merchant’s
          acts or omissions whatsoever, including but not limited to deficiency of service, quality, merchantability of
          products or services, shall be resolved between the Paytail User and the Partner Merchant only and Paytail shall
          have no responsibility or liability whatsoever in this regard.

        </li>
        <br />
        <li>
          <span className={policyStyle.subheading}>Intellectual Property</span><br />
          <ul type="a">
            <li>
              The Paytail User acknowledges that Paytail is the sole and rightful owner of the Intellectual Property and
              the Paytail User agrees not to register, use or file in its own name or in the name of any other person or
              company any trademarks same or similar or resembling in any manner the Intellectual Property and not to
              associate the Intellectual Property with its own business, except for the purpose as specified in these T&amp;C.
              In this regard 'Intellectual Property' shall mean any and all property in any name, signature, word, letter,
              numeral or any combination thereof, software, Trademark, brand name, service mark, trade name, design, logo,
              know-how, trade secrets whether registered or not, belonging to Paytail.
            </li>
            <li>
              Nothing contained herein shall authorize a Paytail User to use, display or exploit the Intellectual Property
              rights of Paytail without the prior written consent of Paytail and all content on the Paytail App and
              Paytail Services is the copyright of Paytail except for third party content and any link to third party
              websites and/or web applications.
            </li>
            <li>
              The information provided on the Paytail App is intended, solely to provide general information for the
              personal use of the reader, who accepts full responsibility for its use. Paytail does not represent or
              endorse the accuracy or reliability of any information, or advertisements contained on, distributed through,
              or linked, downloaded or accessed from any of the Paytail Services contained on Paytail App, or the quality
              of any products, information or other materials displayed, or obtained by you as a result of an
              advertisement or any other information or offer in or in connection with the Paytail Services provided
              through the online technology platform.
            </li>
          </ul>
        </li>
        <br />
        <li>
          <span className={policyStyle.subheading}>Amendment:</span> <br />
          These T&amp;C and/or Policies may be varied, amended or modified, in whole or in part, by Paytail at any time
          without notice to the Paytail Users.
        </li>
        <br />
        <li>
          <span className={policyStyle.subheading}>Relationship:</span> <br />
          These T&amp;C constitute a principal-to-principal relationship between Paytail and the Paytail Users and do not
          attempt to create any agency relationship. The Paytail Services are being rendered by Paytail as an independent
          entity and nothing contained herein shall be deemed to create any association, partnership, joint venture or
          relationship of principal and agent or master and servant, or employer and employee between Paytail and the
          Paytail Users hereto or any affiliates or subsidiaries thereof or to provide either Paytail or the Paytail User
          with the right, power or authority, whether express or implied to create any such duty or obligation on behalf
          of the other party, i.e. either Paytail or the Paytail User.
        </li>
        <br />
        <li>
          <span className={policyStyle.subheading}>Assignment:</span> <br />
          Paytail User shall not assign this Agreement to a third party under any circumstances. Paytail may assign, in
          whole or in part, the benefits or obligations of these T&amp;C to its associates of affiliates of other group
          company, or any other company, in circumstances including but not limited to pursuant to a restructuring or
          re-organization or demerger of its organization or operations causing a change in management or something of the
          nature. Paytail shall not require approval or consent of the Paytail User, it can at its discretion provide an
          intimation of such assignment to the Paytail Users. However, the Paytail User shall not assign, in whole or in
          part, the benefits or obligations of these T&amp;C, without the prior written permission of Paytail. However,
          Assignment of this Agreement by Paytail shall not constitute any waiver of repayment liability of Statement
          amount in favour of assignee entity or Partner Merchant Bank and User shall be bound to discharge his/her
          obligations in accordance with this Agreement.
        </li>
        <br />
        <li>
          <span className={policyStyle.subheading}>Force Majeure:</span> <br />
          Paytail shall not be liable for its failure to perform under these T&amp;C as a result of occurrence of any force
          majeure events like acts of God, fire, wars, sabotage, civil unrest, labour unrest, action of statutory
          authorities or local or central governments, change in laws, rules and regulations, affecting the performance of
          Paytail, payment gateways or Partner Merchant.
        </li>
        <br />
        <li>
          <span className={policyStyle.subheading}>No Waiver:</span> <br />
          Unless otherwise expressly stated, failure to exercise or delay in exercising a right or remedy, hereunder shall
          not constitute a waiver of the right or remedy or a waiver of any other rights or remedies, and no single or
          partial exercise of any right or remedy, hereunder shall prevent any further exercise of the right or remedy or
          the exercise of any other right or remedy.
        </li>
        <br />
        <li>
          <span className={policyStyle.subheading}>Surviving Terms:</span> <br />
          The terms and provisions of these T&amp;C, by their nature and content are intended to survive the performance
          hereof by any or all parties hereto shall so survive termination.
        </li>
        <br />
        <li>
          <span className={policyStyle.subheading}>Enforceability:</span> <br />
          If any provision, hereunder becomes, in whole or in part, invalid or unenforceable but would be valid or
          enforceable if some part of that provision was deleted, that provision shall apply with such deletions as may be
          necessary to make it valid. If any court/tribunal of competent jurisdiction holds any of the provisions,
          hereunder unlawful or otherwise ineffective, the remainder shall remain in full force and the unlawful or
          otherwise ineffective provision shall be substituted by a new provision reflecting the intent of the provision
          so substituted.
        </li>
        <br />
        <li>
          <span className={policyStyle.subheading}>Non-Solicitation:</span> <br />
          The Paytail User warrants that it shall not directly or indirectly solicit for employment, nor offer employment
          to, nor enter into any contract of services with any person employed by Paytail.
        </li>
        <br />
        <li>
          <span className={policyStyle.subheading}>Non-exclusivity:</span> <br />
          It is agreed and clarified that the arrangement set out by these T&amp;C between Paytail and the Paytail Users is on
          a non-exclusive basis.
        </li>
        <br />
      </ol>
      <h3 className={policyStyle.font_weight_bold}>Grievances</h3>
      <p>
        Users have complete authority to file a complaint/ share feedback, if they are disappointed by services rendered
        by Paytail. They can give their complaint/ feedback in writing or by way of an email to the following:<br />

        <b className={policyStyle.text_muted}>Helpline</b>: 8880200500<br />

        <b className={policyStyle.text_muted}>Email</b>: support@paytail.com<br />

        <b>SCHEDULE 1</b> <br />

        LIST OF THE LENDING PARTNERS <br />
      </p><table>
        <tbody>
          <tr>
            <th><img src={chola} alt="" /></th>
            <td><a href="https://www.cholamandalam.com/" target={'_blank'} style={{ color: "#d0144b" }}>
              Cholamandalam Investment and Finance Company Limited
            </a><br />
              <b>Grievance Redressal Officer: </b> <br />
              <b>Name-</b>  Mr. Krishnakumar K.P <br />
              <b>Email-</b>  principalnodalofficer@chola.murugappa.com  <br />
              <b>Help Desk Number- </b> 044 - 40906114<br />
              <b>Web Link- </b><a href="https://cholamandalam.com/grievance-redressal.aspx" target={'_blank'} style={{ color: "#d0144b" }}>https://cholamandalam.com/grievance-redressal.aspx</a>
            </td>
          </tr>
          <tr>
            <th><img src={airthmate} alt="" style={{ "width": "174px" }} /></th>
            <td><a href="https://www.arthmate.com/t-n-p " target={'_blank'} style={{ color: "#d0144b" }}>
              MAMTA PROJECTS PRIVATE LIMITED (Arthmate)
            </a> <br />
              <b>Customer Service: </b> <br />
              <b>Email-</b>  statutory.compliance@arthmate.com  <br />
              <b>Help Desk Number- </b> 7302118667
            </td>
          </tr>
          <tr>
            <th><img src={fintree} alt="" style={{ "height": "68px" }} /></th>
            <td><a href="https://fintreefinance.com/" target={'_blank'} style={{ color: "#d0144b" }}>
              Fintree Finance Private Limited
            </a></td>
          </tr>

        </tbody>
      </table>
      <p></p>
      {/* <!-- <p className={policyStyle.subheading}>Contact info below :</p> --> */}
      <h3 className={policyStyle.font_weight_bold}>Grievance Officer</h3>
      <p>
        In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the
        Grievance Officer are provided below: <br />
      </p>
      <address className={policyStyle.text_left}>
        <em className={policyStyle.address}>
          <b className={policyStyle.subheading}>Contact info below</b><br />
          Nishant Patni<br />
          Paytail Commerce Private Limited<br />
          5th Floor, Unitech Commercial Tower-2,<br />
          Block B, Greenwood City, <br />
          Sector 45, Gurugram - 122001, Haryana<br /><br />
          <b className={policyStyle.subheading}>Contact Us</b>
          <br />
          <b>Email</b>: grievance.officer@Paytail.com
          <br />
          <b>Phone</b>: 8880200500<br />
          <b>Time</b>: Mon - Sat (9:00 - 18:00)
          <br />
        </em>
      </address>
      <h3 className={policyStyle.font_weight_bold}>ADDITIONAL TERMS FOR CREDIT RATING ASSESMENT </h3>
      <p>
        <span className={policyStyle.font_weight_bold}>
          CUSTOMER’s ACCEPTANCE OF INDEPENDENT TERMS AND CONSENT FOR OBTAINING CREDIT WORTHINESS INFORMATION ON BEHALF OF
          THE USER BY PAYTAIL THROUGH CREDIT INFORMATION AGENCIES
        </span> <br />
        Terms provided by the below mentioned credit information agencies are independent of Paytail’s Terms of Use &amp;
        Privacy Policies and the User shall be bound by them and governed by their respective terms pertaining to User’s
        credit worthiness information.
      </p><ol className={policyStyle.termlist} type="A">
        <li>
          <span className={policyStyle.subheading}>Terms of “CRIF High Mark”</span><br />
          In connection with submission of the application for my credit information (“Consumer Credit Information”)
          offered by CRIF High Mark (“CIC”) through Paytail Commerce Private Limited (referred to as the “Company”) and
          delivery of the Consumer Credit Information to the Company, I hereby acknowledge and agree to the following:
          <br />
          <ul className={policyStyle.termlist} typeof="1">
            <li>
              The Company is my lawfully appointed agent and he / it has agreed to be my agent for the purposes,
              including, without limitation, to receive the Consumer Credit Information from CIC on my behalf and use it
              in the manner consistent with the Agreement entered into between the Company and CIC, and the Company has
              granted its consent for being appointed for the aforesaid purpose.
            </li>
            <li>
              I grant my unconditional consent to the Company to receive the Consumer Credit Information from CIC on my
              behalf and use it in the manner consistent with the consistent with the Agreement entered into between the
              Company and CIC, as the case may be, and the Company has granted its consent for being appointed for the
              aforesaid purpose. I hereby represent and acknowledge that: (a) I have carefully read the terms and
              conditions of the Agreement entered into between the Company and CIC understood the same; or (b) the Terms
              of Understanding in relation to the use of the Consumer Credit Information has been agreed between me and
              the Company.
            </li>
            <li>
              I hereby expressly grant unconditional consent to, and direct, CIC to deliver and / or transfer my Consumer
              Credit Information to the Company on my behalf.
            </li>
            <li>
              I shall not hold CIC responsible or liable for any loss, claim, liability, or damage of any kind resulting
              from, arising out of, or in any way related to: (a) delivery of my Consumer Credit Information to the
              Company; (b) any use, modification or disclosure by the Company of the contents, in whole or in part, of my
              Consumer Credit Information, whether authorized or not; (c) any breach of confidentiality or privacy in
              relation to delivery of my Consumer Credit Information to the Company ; (d) for any use made by the Company
              which is contrary to the Agreement entered into between the Company and CIC
            </li>
            <li>
              I acknowledge and accept that: (a) CIC has not made any promises or representations to me in order to induce
              me to provide my Consumer Credit Information or seek any consent or authorization in this regard;
              and (b) the implementation of the Agreement between CIC and the Company is solely the responsibility of the
              Company.
            </li>
            <li>
              I agree that I may be required to record my consent / provide instructions electronically and in all such
              cases I understand that by clicking on the "I Accept" button below, I am providing "written instructions" to
              Company authorizing Company to obtain my Consumer Credit Information from my personal credit profile from
              CRIF High Mark. I further authorize Company to obtain such information solely to confirm my identity and
              display my Consumer Credit Information to me. Further in all such cases by checking this box and clicking on
              the Authorize button, I agree to the terms and conditions, acknowledge receipt of CIC  <Link to="/privacypolicy" className={policyStyle.links_styles}>Privacy Policy</Link> and agree to its terms, and confirm my authorization for Company to obtain my Consumer Credit
              Information.
            </li>
            <li>
              I understand that in order to deliver the product to me, I hereby authorize Company, to obtain my Consumer
              Credit Information from CIC.
            </li>
            <li>
              By submitting this registration form, I understand that I am providing express written instructions for
              Company to request and receive information about me from third parties, including but not limited to a copy
              of my consumer credit report and score from consumer reporting agencies, at any time for so long as I have
              an active Company account. I further authorize Company to retain a copy of my information for use in
              accordance with Company’s Terms of Use and <Link to="/privacypolicy" className={policyStyle.links_styles}>Privacy Policy</Link>
              .
            </li>
            <li>
              I UNDERSTAND THAT THE PRODUCT IS PROVIDED ON AN “AS-IS”, “AS AVAILABLE” BASIS AND CIC EXPRESSLY DISCLAIMS
              ALL WARRANTIES, INCLUDING THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
              NON-INFRINGEMENT.
            </li>
            <li>
              I shall not sue or otherwise make or present any demand or claim, and I irrevocably, unconditionally and
              entirely release, waive and forever discharge CIC, its officers, directors, employees, agents, licensees,
              affiliates, successors and assigns, jointly and individually (hereinafter “Releasee”), from any and all
              manner of liabilities, claims, demands, losses, claims, suits, costs and expenses (including court costs and
              reasonable attorney fees) (“Losses”), whatsoever, in law or equity, whether known or unknown, which I ever
              had, now have, or in the future may have against the Releasee with respect to the submission of my Consumer
              Credit Information and / or my decision to provide CIC with the authority to deliver my Consumer Credit
              Information to the Company. I agree to defend, indemnify, and hold harmless the Releasee from and against
              any and all Losses resulting from claims made against CIC L by third parties arising from and in connection
              with this letter.
            </li>
            <li>
              I agree that the terms of this confirmation letter shall be governed by the laws of India and shall be
              subject to the exclusive jurisdiction of the courts located in Gurugram, Haryana in regard to any dispute
              arising hereof. CIC is entitled to assign its rights hereunder to any third person without taking my prior
              written consent.
            </li>
          </ul>
        </li>
        <br />
        <li>
          <span className={policyStyle.subheading}>Terms of “Equifax Credit Information Services Pvt. Ltd” </span><br />
          You hereby consent to PAYTAIL being appointed as your authorized representative to receive your Credit
          Information on your behalf from Equifax Credit Information Services Pvt. Ltd for a period of 36 consecutive
          months for the purpose of evaluating your creditworthiness and curate personalized offers for you. <br />
          BY EXECUTING THIS AGREEMENT / CONSENT FORM, YOU ARE EXPRESSLY AGREEING TO ACCESS THE EQUIFAX CREDIT INFORMATION
          SERVICES PVT. LTD CREDIT INFORMATION REPORT AND CREDIT SCORE, AGGREGATE SCORES. INFERENCES, REFERENCES AND
          DETAILS (AS DEFINED BELOW) (TOGETHER REFERRED AS "CREDIT INFORMATION"). YOU HEREBY ALSO IRREVOCABLY AND
          UNCONDITIONALLY CONSENT TO SUCH CREDIT INFORMATION BEING PROVIDED BY EQUIFAX CREDIT INFORMATION SERVICES PVT.
          LTD TO PAYTAIL BY USING EQUIFAX CREDIT INFORMATION SERVICES PVT. LTD TOOLS, ALGORITHMS AND DEVICES AND YOU
          HEREBY AGREE, ACKNOWLEDGE AND ACCEPT THE TERMS AND CONDITIONS SET FORTH HEREIN. <br />
          <span className={policyStyle.subheading}>Terms and Conditions </span> <br />
          Information Collection, Use, Confidentiality, No-Disclosure and Data Purging <br />
          PAYTAIL shall access your Credit Information as your authorized representative and PAYTAIL shall use the Credit
          Information for limited End Use Purpose consisting of and in relation to the services proposed to be availed by
          you from PAYTAIL. We shall not aggregate, retain, store, copy, reproduce, republish, upload, post, transmit,
          sell
          or rent the Credit Information to any other person and the same cannot be copied or reproduced other than as
          agreed herein and in furtherance to CICRA. <br />
          The Parties agree to protect and keep confidential the Credit Information both online and offline. <br />
          The Credit Information shared by you, or received on by us on your behalf shall be destroyed, purged, erased
          promptly within 1 (one) Business Day of upon the completion of the transaction/ End Use Purpose for which the
          Credit Information report was procured. <br />
          <span className={policyStyle.subheading}>Governing Law and Jurisdiction </span> <br />
          The relationship between you and shall be governed by laws of India and all claims or disputes arising there
          from shall be subject to the exclusive jurisdiction of the courts of Gurugram, Haryana. <br />
          <span className={policyStyle.subheading}>Definitions </span> <br />
          Capitalised terms used herein but not defined above shall have the following meanings: <br />
          "Business Day" means a day (other than a public holiday) on which banks are open for general business in Mumbai.
          "Credit Information Report" means the credit information / scores/ aggregates / variables /inferences or reports
          which shall be generated by Equifax Credit Information Services Pvt. Ltd; <br />
          "Credit Score' means the score which shall be mentioned on the Credit Information Report which shall be computed
          by Equifax Credit Information Services Pvt. Ltd. <br />
          "CICRA" shall mean the Credit Information Companies (Regulation) Act, 2005 read with the Credit Information
          Companies Rules, 2006 and the Credit Information Companies Regulations, 2006, and shall include any other rules
          and regulations prescribed thereunder. <br />
          PLEASE READ THE ABOVEMENTIONED TERMS AND CONDITIONS AND CLICK ON "ACCEPT" FOLLOWED BY THE LINK BELOW TO COMPLETE
          THE AUTHORISATION PROCESS FOR SHARING OF YOUR CREDIT INFORMATION BY EQUIFAX CREDIT INFORMATION SERVICES PVT. LTD
          WITH PAYTAIL IN ITS CAPACITY AS YOUR AUTHORISED REPRESENTATIVE. BY CLICKING "PROCEED* YOU AGREE AND ACCEPT THE
          DISCLAIMERS AND TERMS AND CONDITIONS SET OUT HEREIN. <br />
          ** This document is an electronic record in terms of the Information Technology Act, 2000 and Rules made there
          under, and the amended provisions pertaining to electronic records. <br />
        </li>
        <br />
        <li>
          <span className={policyStyle.subheading}>Terms and Conditions of Karza Aadhaar</span><br />
          Upon Sign Up/Sign In and sharing the Aadhaar number, You (the Aadhaar holder), hereby irrevocably: <br />
          <ul typeof="1">
            <li>
              Place a request to Paytail Commerce Private Limited and Karza Technologies Private Limited (“Karza”) to
              access Your Aadhaar Information from UIDAI to fetch and verify information regarding Aadhaar Number, Aadhaar
              XML, Virtual ID, e-Aadhaar, Masked Aadhaar, Aadhaar details, demographic information, identity information,
              Aadhaar registered mobile number, face authentication details and/or biometric information (collectively
              referred to as “Aadhaar Information”)
            </li>
            <li>
              Explicitly authorise Paytail Commerce Private Limited and Karza to fetch Your Aadhaar Information from UIDAI
              to make it available to Paytail Commerce Private Limited and/or any third party, as may be required for
              availing services.
            </li>
            <li>
              Agree to take all the necessary actions required for the purpose of authenticating and verifying Your
              Aadhaar Information.
            </li>
            <li>
              Give a valid, binding, irrevocable and explicit authorisation and consent as may be required under
              applicable laws, rules, regulations and guidelines for availing the Aadhaar API services of Karza including,
              but not limited to the transmission and storage of Your Aadhaar Information by Paytail Commerce Private
              Limited and Karza.
            </li>
            <li>
              Understand and agree that the Consent has been submitted by You voluntarily and without any coercion
              from Paytail Commerce Private Limited or Karza or any other party.
            </li>
            <li>
              Fully understand and accept sole and complete responsibility for any issues, legal suits, damages, losses,
              penalties, fines or liabilities (“Losses”) arising out of Your sharing of Aadhaar Information and
              authorising Paytail Commerce Private Limited or Karza for fetching Your Aadhar Information and that You will
              fully hold harmless Paytail Commerce Private Limited and Karza, its representatives, employees and directors
              for any Losses arising out of such request and actions.
            </li>
            <li>
              Paytail Commerce Private Limited and Karza does not store or retain any Aadhaar Information including
              the Aadhaar number belonging to You after it has processed Your request.”
            </li>
          </ul>
        </li>
      </ol>
      <p></p>
    </div>
  );
};

export default ConsumerTermCondition;
import React from 'react';
import Logo from '../../assets/images/paytail_logo.png'
import QRCode from '../../assets/images/QR_code.png'
import toggleIcon from '../../assets/images/menu.png'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import $ from "jquery";
const Header = () => {

    $(function () {
        const $this = $(this);
        const $toggleMenu = $this.find('.toggleMenu');
        const $closeMenu = $this.find('.closeMenu');
        $toggleMenu.on('click', function (e) {
            $('.menuBg').addClass('active');
        });
        $closeMenu.on('click', function (e) {
            $('.menuBg').removeClass('active');
        });
    });
    const openIncurrentTab = url => {
        window.open(url, '_top', 'noopener,noreferrer');
    };

    return (
        <div className='header'>
            <Container>
                <Row>
                    <Col>
                        <div className='mainMenu'>
                            <div className='logo'>
                                <a onClick={() => openIncurrentTab('/')}><img src={Logo} /></a>
                            </div>
                            <div className='nav'>
                                <div className='menuBg'>
                                    <span className='closeMenu'><AiOutlineClose /></span>
                                    <ul>
                                        <li><a onClick={() => window.open('/about-us', '_top', 'noopener,noreferrer')}>About us</a></li>
                                        <li><a href='https://paytail-web.s3.ap-south-1.amazonaws.com/Paytail.apk' target={'_blank'}>Download</a></li>
                                        {/* <li><a href='https://play.google.com/store/apps/details?id=com.paytail.consumers' target={'_blank'}>Download</a></li> */}
                                        <li><a onClick={() => openIncurrentTab('/careers')}>Careers</a></li>
                                    </ul>
                                </div>
                                <ul>
                                    <li className='qrCode'><a href='https://www.paytail.com/scan' target={'_blank'}><img src={QRCode} /></a></li>
                                    <li className='toggleMenu'><AiOutlineMenu /></li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
export default Header;